import React, {useEffect, useState} from 'react';
import { Switch } from 'react-router-dom';
import { routes, routeComponents } from '../routes';
import { Layout } from 'antd';
import styled from 'styled-components';
import Logo from '../components/Logo';
import Loader from '../components/Loader';
import { repository as skillRepository } from '../services/SkillService';
import { repository as skillLevelRepository } from '../services/SkillLevelService';

const { Header, Content } = Layout;

const StyledHeader = styled(Header)`
  display: none; /*flex*/
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  @media (max-width: 1200px) {
    padding: 0 1.5rem;
  }
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const OperatorAppLayout = () => {
  const [loaded, setLoaded] = useState(false);

  async function loadGlobalAppData() {
    await skillRepository.getAll(true);
    await skillLevelRepository.getAll(true);
    setLoaded(true);
  }

  // Effects
  useEffect(() => {
    loadGlobalAppData();
  }, []);

  if (true === loaded) {
    return (
      <>
        <Layout>
          <StyledHeader>
            <Logo type="small" />
          </StyledHeader>
          <StyledContent>
              <Switch>
                { routeComponents }
              </Switch>
          </StyledContent>
        </Layout>
      </>
    );
  }

  return <Loader/>;
};

export default OperatorAppLayout;
