import { useState, useEffect } from "react";
import { getOperatorSearches } from "./OperatorService";
import { statusCode, getStatus } from "./ProductionStatusService";
import { getSessions } from "./SessionService";
import { getRepository } from "./RepositoryService";

const productionRepository = getRepository('production');
const productionTyperepository = getRepository('productionType');

export const getProductionTypes = async () => {
	return await productionTyperepository.getAll(true);
};

export const updateProduction = async (data) => {
  try {
		if (!data.id) {
			throw new Error("Production " + data.title + " does not exist. It should be created!");
		}
		let fields = castAndValidateProduction(data);
		let resp = await productionRepository.patch(data.id, fields);
		if (resp.data.id) {
			// remove instance from cache
			if (productionRepository.has(resp.data.id)) {
				productionRepository.remove(resp.data.id);
			}
			// reload instance
			await getProduction(resp.data.id, false);
		}
    return resp;
  } catch(error) {
    return error;
  }
};

export const deleteProduction = async (id) => {
  return await productionRepository.delete(id);
};

export const createProduction = async (data) =>{
	if (data.id) {
		throw new Error("Production " + data.title + " already exists. It should be updated!");
	}

	// default values
	if (!data.status) {
		data.status = await getStatus(statusCode.CREATION);
	}

	let fields = castAndValidateProduction(data);

	return await productionRepository.post(fields);
};

const castAndValidateProduction = (data) =>{
	let {id, ...fields} = data;
	let ignoreFields = ['sessions', 'operatorSearches', 'jobs', 'location', 'crew'];
	ignoreFields.forEach(field => {
		if (field in fields) {
			delete fields[field];
		}
	});
	// cast type
	if (fields?.type?.id) {
		fields.type = fields.type.id;
	} else if (Array.isArray(fields.type) && fields.type.length > 0 && typeof fields.type[0] === 'string') {
		fields.type = fields.type[0];
	} else if (typeof fields?.type !== 'string') {
		delete fields['type'];
	}
	// cast status
	if (fields?.status?.id) {
		fields.status = fields.status.id;
	} else if (typeof fields?.status !== 'string') {
		delete fields['status'];
	}
	// cast user
	if (fields?.user?.id) {
		fields.user = fields.user.id;
	} else if (Array.isArray(fields.user) && fields.user.length > 0 && typeof fields.user[0] === 'string') {
		fields.user = fields.user[0];
	} else if (typeof fields?.user !== 'string') {
		delete fields['user'];
	}
	// cast company
	if (fields?.company?.id) {
		fields.company = fields.company.id;
	} else if (Array.isArray(fields.company) && fields.company.length > 0 && typeof fields.company[0] === 'string') {
		fields.company = fields.company[0];
	} else if (typeof fields?.company !== 'string') {
		delete fields['company'];
	}

	// validate required fields
	if (!fields.type) {
		throw new Error("Type must be defined!");
	}
	if (!fields.status) {
		throw new Error("Status must be defined!");
	}
	if (!fields.user) {
		throw new Error("User must be defined!");
	}
	if (!fields.company) {
		throw new Error("Company must be defined!");
	}
	return fields;
};


async function loadProductions(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!productionRepository) {
		throw new Error('No repository defined for production!');
	}
	let productions = null === ids ? await productionRepository.getAll(true) : await productionRepository.get(ids);
	if (false === lazy) {
		productions = await Promise.all(productions.map(async (record) => {
			// operatorSearches
			if (record?.operatorSearches?.length > 0 && (typeof record?.operatorSearches[0] === 'string')) {
				record.operatorSearches = await getOperatorSearches(record.operatorSearches, false);
			}
			// status
			if (Array.isArray(record.status) && record.status.length > 0) {
				let status = await getStatus(record.status[0]);
				if (status && status.id) {
					record.status = status;
				}
			}
			// sessions
			if (record?.sessions?.length > 0 && (typeof record?.sessions[0] === 'string')) {
				record.sessions = await getSessions(record.sessions, false);
			}
			return record;
		}));
	}
	return productions;
}

export const getProductions = async (ids: Array, lazy: boolean = true) => {
	return await loadProductions(ids ?? null, lazy);
};

export const getProduction = async function (id, lazy: boolean = true) {
	await getProductions([id], lazy);
	return productionRepository.get(id);
};


const listeners = new Set();

export const useProduction = () => {
	const [productions, setProductions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getProductions = async function (ids: Array, lazy: boolean = true) {
		listeners.forEach(listener => listener());
		setError(null);
		//try {
			setLoading(true);
			let productions = await loadProductions(ids ?? null, lazy);
			setLoading(false);
			listeners.forEach(listener => listener());
			return productions;
		//} catch (e) {
		//	setError(e);
		//}
		//return [];
	};

	const getProduction = async function (id, lazy: boolean = true) {
		await getProductions([id], lazy);
		return productionRepository.get(id);
	};

	useEffect(() => {
		const listener = () => {
			productionRepository.getAll(false).then(productions => {
				setProductions(productions);
			});
			setLoading(productionRepository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, productions, getProductions, getProduction };
};

export default useProduction;