import React from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
  background-color: #ffe8d5;
  color: ${props => props.theme.brandColor};
  &.font-150 {
    span {
      font-size: 3.5rem!important;
    }
  }
`;

const ProfileAvatar = ({size, initials, src}) => {
  return (
    <>
      {
        initials &&
        <StyledAvatar className={`font-${size}`} size={size}>{initials}</StyledAvatar>
      }
      {
        src && 
        <Avatar src={src} size={size}/>
      }
    </>
  )
}

export default ProfileAvatar;
