import React, { useState, useEffect } from "react";
import { Table, Tag, Row, Col, Button, Space, Input } from 'antd';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import moment from "moment";
import styled from 'styled-components';
import {
  getProductionTypes,
  useProduction
} from '../../services/ProductionService';
import {
  useProductionStatus
} from '../../services/ProductionStatusService';


const StyledActionsButtons = styled.div`
  padding: 0 0 1.5rem 0;
  .search-bar {
    min-width: 20vw;
    @media (max-width: 1200px) {
      min-width: calc(100vw - 6rem);
    }
  }
`;

const TableCounter = styled.span`
  display: inline-block;
  margin: 1rem 0 1.5rem 0;
  font-size: .9rem;
  span {
    font-weight: bold;
  }
`;

const StyledLink = styled(Link)`
  color: #343D46;
  &:hover {
    opacity: .8;
    color: #343D46;
  }
  @media (max-width: 550px) {
    font-size: .9rem;
  }
`;

const StyledTag = styled(Tag)`
    border: none;
    padding: 4px 15px;
    font-size: .9rem;
    font-weight: bold;
  &.team-selection {
      background-color: ${props => props.theme.productionStatus.inSelection};
  }
  &.team-complete {
      background-color: ${props => props.theme.productionStatus.selectionComplete};
  }
  &.making {
      background-color: ${props => props.theme.productionStatus.productionStarted};
  }
  &.complete {
      background-color: ${props => props.theme.productionStatus.productionDone};
  }
  &.archive {
    background-color: ${props => props.theme.productionStatus.productionArchived};
  }
  @media (max-width: 550px) {
    font-size: .8rem;
    padding: 4px 10px;
  }
`;

const ActionIcons = styled.span`
  a {
    color: #343D46;
    padding: 5px 20px;
    display: flex;
    @media (max-width: 550px) {
      padding: 5px 12px;
    }
    &:hover {
      opacity: .8;
      color: #343D46;
    }
  }
`;

const FilterButton = styled(Button)`
    border: none;
    padding: 4px 15px;
    font-size: .9rem;
    font-weight: bold;
    opacity: .3;
    transition: .3s opacity;
  &.active {
    opacity: 1;
    &:hover {
      opacity: .8
    }
  }
  &:focus, &:hover {
    color: #343D46; 
  }
  &.hover {
    opacity: 1;
  }
  &.team-selection {
      background-color: ${props => props.theme.productionStatus.inSelection};
  }
  &.team-complete {
      background-color: ${props => props.theme.productionStatus.selectionComplete};
  }
  &.making {
      background-color: ${props => props.theme.productionStatus.productionStarted};
  }
  &.complete {
      background-color: ${props => props.theme.productionStatus.productionDone};
  }
  &.archive {
    background-color: ${props => props.theme.productionStatus.productionArchived};
  }
  @media (max-width: 1024px) {
    span {
      font-size: 0px;
    }
  }
`;


const Productions = ({ location }) => {
  const { queryFilterId } = location;
  let queryFilter = queryFilterId ? [queryFilterId] : null;
  let statusFiltersDefault = [];
  let localFilteredCount;
  let prodsLabels = [];

  // Queries
  const { productions, getProductions } = useProduction();
  const { statuses: productionStatuses, getStatuses: getProductionStatuses } = useProductionStatus();

  // States
  const [productionTypes, setProductionTypes] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [displayResetFilter, setdisplayResetFilter] = useState(false);
  
  const [filteredCount, setFilteredCount] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const [loadStatus, setLoadStatus] = useState(localStorage.getItem('prodsFilters') !== null ? true : false);
  const [filteredItems, setFilteredItems] = useState([]);

  // Effects
  useEffect(() => {
    // load all production types
    getProductionTypes().then(types => setProductionTypes(types));
    // load all production statuses
    getProductionStatuses();
    // load all productions
    getProductions().then(productions => {
      if (queryFilter) {
        setFilter(productions, queryFilter);
      }
    });
    if (localStorage.getItem('prodsFilters')) {
      let userFilters = JSON.parse(localStorage.getItem('prodsFilters'));
      setStatusFilters(userFilters);
      setdisplayResetFilter(true);
    }
  }, []);

  const setFilter = (productions, filter) => {
    if (!Array.isArray(productions)) {
      return;
    }
    setLoadStatus(true);
    let updatedStatusIds = []
    updatedStatusIds = [...new Set([...statusFilters, ...filter])];

    localStorage.setItem('prodsFilters', JSON.stringify(updatedStatusIds));

    // Toggle add/remove filter
    if (statusFilters.includes(filter[0]) === true) {
      updatedStatusIds = updatedStatusIds.filter(id => id !== filter[0])
    }

    let matchingItems = productions.filter(prod => {
      if (Array.isArray(prod.status)) {
        return updatedStatusIds.includes(prod.status[0]) === true;
      }
      if (prod.status?.id) {
        return updatedStatusIds.includes(prod.status.id) === true;
      }
      return true;
    });

    setFilteredItems(() => matchingItems);
    setFilteredCount(matchingItems.length);

    if (updatedStatusIds.length > 0) {
      setFiltered(true);
      setdisplayResetFilter(true)
    } else {
      clearFilters()
    }

    setStatusFilters(updatedStatusIds);
  }

  const clearFilters = () => {
    setLoadStatus(false);
    setStatusFilters([]);
    setFiltered(false);
    setdisplayResetFilter(false);
    setFilteredItems(() => productions);
    setFilteredCount(productions.length);
    localStorage.removeItem('prodsFilters');
  }

  if (productions && productionStatuses?.length && productionTypes?.length) {
    prodsLabels.push(...productionTypes, ...productionStatuses);

    if (localStorage.getItem('prodsFilters') !== null) {
      let userFilters = JSON.parse(localStorage.getItem('prodsFilters'));
      localFilteredCount = productions.filter(prod => {
        if (Array.isArray(prod.status)) {
          return userFilters.includes(prod.status[0]) === true;
        }
        if (prod.status?.id) {
          return userFilters.includes(prod.status.id) === true;
        }
        return true;
      });
    }

    const getProdsLabel = (mixed) => {
      if (mixed instanceof Object && mixed.id) {
        return mixed;
      }
      if (Array.isArray(mixed)) {
        mixed = mixed[0];
      }
      if (typeof mixed === 'string') {
        return prodsLabels.find((item) => item.id === mixed);
      }
      return null;
    };

    const releaseDateFormatter = (date) => moment(date).format("LL");

    const actionsViewFormatter = (record) => {
      return (
        <ActionIcons>
          <Space>
            <Link to={`/productions/${record.id}`}>
              <FontAwesomeIcon icon={faEllipsisV} size="2x"/>
            </Link>
          </Space>
        </ActionIcons>
      )
    };

    const handleChange = (pagination, filters, sorter, {currentDataSource}) => {
      if (filters.status) {
        queryFilter = null;
        setStatusFilters(filters.status);
        setdisplayResetFilter(true);
        setFilteredCount(currentDataSource.length);
      }
    };

    const handleSearch = (value) => {
      const filterTable = productions.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
      setFilteredItems(() => filterTable);
      setFilteredCount(filterTable.length);
      displayCount();
      setFiltered(true)
    }

    const columns = [
      {
        dataIndex: "title",
        key: "title",
        title: "Titre",
        render: (text, record) => {
          return (
            <StyledLink to={`/productions/${record.id}`}>
              <b>{ text }</b>
            </StyledLink>
          )
        }
      },
      {
        dataIndex: "type",
        key: "type",
        title: "Type",
        width: "250px",
        responsive: ['md'],
        render: (text, record) => {
          let type = getProdsLabel(record.type);
          return type ? type.name : '';
        }
      },
      {
        dataIndex: "releaseDate",
        key: "releaseDate",
        title: "Sortie",
        width: "250px",
        responsive: ['md'],
        render: releaseDateFormatter,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => moment(a.releaseDate).unix() - moment(b.releaseDate).unix(),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: "status",
        key: "status",
        title: "Statut",
        width: "250px",
        filterMultiple: true,
        filteredValue: statusFilters || null,
        defaultFilteredValue: statusFiltersDefault,
        onFilter: (value, record) => {
          if (Array.isArray(record.status)) {
            return record.status.indexOf(value) === 0;
          }
          if (record.status?.id) {
            return record.status.id === value;
          }
          return true;
        },
        render: (text, record) => {
          let status = getProdsLabel(record.status);
          return ( status?.id &&
              <StyledTag key={status.id} className={status?.code?.toLowerCase()}>{status.name.toUpperCase()}</StyledTag>
          );
        }
      },
      {
        title: "",
        dataIndex: "",
        width: "30px",
        align: "right",
        responsive: ['md'],
        render: (text, record) => (
          actionsViewFormatter(record)
        )
      }
    ];

    const displayCount = () => {
      let count;
      if (localFilteredCount) {
        count = localFilteredCount.length
      } else {
        count = displayResetFilter ? filteredCount : productions.length
      }

      return <><span>{ `${count}` }</span> élément{ count > 1 ? 's' : '' }</>
    };

    return (
      <>
      <StyledActionsButtons>
      <Row justify="space-between">
        <Col>
          <Button type="primary" className="text-uppercase mb-1">
            <Link to="/productions/create" className="text-uppercase">Créer une production</Link>
          </Button>
        </Col>
        <Col>
          <Input.Search
            className="search-bar"
            placeholder="Rechercher une production..."
            enterButton
            allowClear
            onSearch={(value) => handleSearch(value)}
          />
        </Col>
      </Row>
      </StyledActionsButtons>
      {
        productions &&
        <>
        <Row justify="space-between" align="middle">
          <Col>
            <TableCounter>
              { displayCount() }
            </TableCounter>
            {
              displayResetFilter &&
                <Space>
                  <Button style={{fontSize:"14px", textTransform: "uppercase", marginLeft: "1rem"}} onClick={() => { clearFilters() }}>
                    Supprimer les filtres
                  </Button>
                </Space>
            }
          </Col>
          <Col>
          <Space>
            {
              productionStatuses.map(status => {
              return (
              <FilterButton
                key={status.id}
                className={`${(statusFilters.includes(status.id) === true || loadStatus === false) ? 'active' : ''} ${status.code.toLowerCase()}`}
                onClick={() => setFilter(productions, [status.id])}
              >
                  { status.name.toUpperCase() }
              </FilterButton>
              )
              })
            }
          </Space>
          </Col>
        </Row>
        </>
      }

      <Table
        dataSource={filtered ? filteredItems : productions}
        rowKey="id"
        columns={columns}
        size="small"
        pagination={productions.length > 8 ? {position: ['', 'bottomCenter'], pageSize: 8} : false}
        onChange={handleChange}
      />
      </>
    );
  }

  return <Loader/>

};

export default Productions;
