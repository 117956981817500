import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';
import GlobalErrorBoundary from './components/GlobalErrorBoundary';
import Layout from './layouts/Layout';
import './assets/styles/app.less';
import Theme from "./theme";
import { UserProvider } from "./context/Context";

moment.locale('fr');

function App() {
  return (
    <UserProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <GlobalErrorBoundary>
          <ConfigProvider locale={frFR}>
            <Theme>
              <Layout />
            </Theme>
          </ConfigProvider>
        </GlobalErrorBoundary>
      </Router>
    </UserProvider>
  );
}

export default App;