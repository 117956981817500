import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

const BackButton = ({ text, type, icon }) => {
  let buttonType = type ? type : "default"
  let history = useHistory();
  return (
    <>
      <Button type={buttonType} icon={icon || null} onClick={() => history.goBack()}>{text}</Button>
    </>
  )
};

export default BackButton;
