import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Button, Drawer, Input, Layout, Modal, Typography} from "antd";
import {CheckOutlined, CloseCircleFilled, CloseOutlined, InfoOutlined, LoadingOutlined} from "@ant-design/icons";
import OperatorProfile from "./Profile";
import {UserContext} from "../../context/Context";
import {updateOperatorSearchMatch} from "../../services/OperatorService";
import { statusCode as operatorSearchStatusCode, getOperatorSearchMatchStatus } from "../../services/OperatorSearchMatchStatus";

const { Title } = Typography;

const Container = styled(Layout)`
  position: relative;
  flex: initial;
  min-width: 370px;
  width: 370px;
  max-width: 100%;
  margin: 1rem;
  user-select:none;
  padding-right: 60px;
  
  > div:first-child {
    position: absolute;
    overflow: hidden;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
  }
  .ant-card-body {
    background-color: rgba(0, 0, 0, 0.025);
    min-height: 100%;
  }
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.025);
  }
  .ant-drawer-content-wrapper {
    width: 50px;
  }
  .ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.025);
    
    > .anticon-close-circle {
      color: #79838E;
    }
  }
  
  > div.status {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px 10px;
    color: #79838E;

    .note {
      color: #FF4000;
    }
  }

  &.rejected, &.canceled {
    opacity: 0.5;
  }
`;

const OperatorProfileModal = styled(Modal)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  overflow: auto;
  .ant-modal-wrap {
    position: absolute;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;


const SearchMatch = ({match, ...props}) => {
  let { user, company } = useContext(UserContext);

  let startDate = match?.operatorSearch?.startDate;
  if (startDate) {
    /*
    if (typeof startDate === "string") {
      startDate = new Date(startDate);
    }
    if (startDate instanceof Date) {
      startDate = startDate.toLocaleString();
    }
    */
    startDate = moment(startDate).format('LL');
  }
  let endDate = match?.operatorSearch?.endDate;
  if (endDate) {
    /*
    if (typeof endDate === "string") {
      endDate = new Date(endDate);
    }
    if (endDate instanceof Date) {
      endDate = endDate.toLocaleString();
    }
    */
    endDate = moment(endDate).format('LL');
  }

  let defaultRequestMessage = `### Bonjour ${match.operator.firstName},  
  
Nous avons un projet qui pourrait t'intéresser.  
  
Production : ${match?.operatorSearch?.production.title}  
Lieu : ${match?.operatorSearch?.location}  
Date(s) : ${startDate} au ${endDate}  
${match.operatorSearch?.production?.description?.length > 0 ? '\nDescription:\n' + match.operatorSearch.production.description + '\n' : ''}
${match.operatorSearch?.sessionSkills?.session?.notes.length > 0 ? match.operatorSearch.sessionSkills.session.notes + '\n' : ''}
${match.operatorSearch?.notes?.length > 0 ? '\nNotes de mission:\n  ' + match.operatorSearch.notes + '\n' : ''}  
  
Serais-tu disponible ?  
  
Cordialement,  
  
${user.fullName}  
${company.name}  `;

  let defaultCancelMessage = `### Bonjour ${match.operator.firstName},  
  
Nous sommes au regret de devoir annuler la session suivante.  
  
Production : ${match?.operatorSearch?.production.title}  
Lieu : ${match?.operatorSearch?.location}  
Date(s) : ${startDate} au ${endDate}  
${match.operatorSearch?.production?.description?.length > 0 ? '\nDescription:\n' + match.operatorSearch.production.description + '\n' : ''}
  
Nous espérons pouvoir revenir vers toi bientôt dans le cadre de ce projet ou d'un autre.  
  
Cordialement,  
  
${user.fullName}  
${company.name}  `;

  // States
  const [showCancel, setShowCancel] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [hover, setHover] = useState(false);
  const [buttonSelectHover, setButtonSelectHover] = useState(false);
  const [buttonInfoHover, setButtonInfoHover] = useState(false);

  const select = async (selected: boolean = true) => {
    match.selected = !!selected;
    if (match?.selected && (!match?.message || match?.message.trim() === '')) {
      match.message = defaultRequestMessage;
    }
    return await updateOperatorSearchMatch(match);
  };

  const cancel = async () => {
    switch (match?.status?.code) {
      case operatorSearchStatusCode.ACCEPTED:
      case operatorSearchStatusCode.SENT:
      case operatorSearchStatusCode.PENDING:
        match.status = await getOperatorSearchMatchStatus(operatorSearchStatusCode.CANCELED);
        if (undefined === match?.cancelMessage || null === match?.cancelMessage || match.cancelMessage.trim() === '') {
          match.cancelMessage = defaultCancelMessage;
        }
        break;
      default:
        return;
    }
    return await updateOperatorSearchMatch(match);
  };

  const seRequestMessage = (message: String = '') => {
    match.message = message;
  };

  const setCancelMessage = (message: String = '') => {
    match.cancelMessage = message;
  };

  return (
    <Container className={match?.status?.code?.toLowerCase()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Drawer
          closable={false}
          destroyOnClose={true}
          getContainer={false}
          mask={false}
          maskClosable={true}
          placement="right"
          style={{ position: 'absolute' }}
          visible={match.selected || hover}
          width={50}
      >
        { null == match?.status &&
        <Button
            type="primary" shape="circle" block={true} ghost={!(match.selected || buttonSelectHover)} icon={<CheckOutlined />}
            onClick={() => match.selected ? select(false) : setShowRequest(true)}
            onMouseEnter={() => setButtonSelectHover(true)} onMouseLeave={() => setButtonSelectHover(false)}
        /> }
        <Button
            shape="circle" icon={<InfoOutlined />}
            onClick={() => setShowProfile(showProfile ? false : true)}
            onMouseEnter={() => setButtonInfoHover(true)} onMouseLeave={() => setButtonInfoHover(false)}
        />
        { operatorSearchStatusCode.ACCEPTED === match?.status?.code &&
        <Button
            type="primary" shape="circle" block={true} ghost={false} icon={buttonSelectHover ? <CloseOutlined /> : <CheckOutlined />}
            onClick={() => setShowCancel(true)}
            onMouseEnter={() => setButtonSelectHover(true)} onMouseLeave={() => setButtonSelectHover(false)}
        /> }
        { (operatorSearchStatusCode.REJECTED === match?.status?.code || operatorSearchStatusCode.CANCELED === match?.status?.code) &&
        <CloseCircleFilled style={{fontSize:'32px'}} /> }
        { (operatorSearchStatusCode.SENT === match?.status?.code || operatorSearchStatusCode.PENDING === match?.status?.code || operatorSearchStatusCode.PROCESSING === match?.status?.code) &&
        <Button
            type="primary" shape="circle" block={true} ghost={false} icon={<LoadingOutlined spin />}
            onClick={() => setShowCancel(true)}
        /> }
      </Drawer>
      <OperatorProfile operator={match.operator} size="small" searchMatch={match} />
      <div className="status">
          {match.score && <span className="note">Recommandé à {Math.min(match.score, 100) }%</span>}
          <span>{match?.status?.name}</span>
      </div>
      {showProfile && <OperatorProfileModal
        visible={true}
        footer={null}
        getContainer="main"
        width="90vw"
        style={{ position: 'absolute', top: '10px', bottom: '10px', left: '10px', right: '10px' }}
        onCancel={() => setShowProfile(false)}>
        <OperatorProfile operator={match.operator} size="full"/>
      </OperatorProfileModal>}
      {showRequest && <Modal
        title=<Title level={4}>Recruter {match.operator.fullName} pour cette production</Title>
        visible={showRequest}
        okText="Sélectionner"
        width={640}
        onOk={() => { select(!match.selected); setShowRequest(false); }}
        onCancel={() => setShowRequest(false)}>
        <p>Message qui sera envoyé à {match.operator.firstName}, vous pouvez le personnaliser.</p>
        <Input.TextArea
          defaultValue={match.message || defaultRequestMessage}
          rows={10}
          onChange={(e) => seRequestMessage(e.target.value)}
          />
      </Modal>}
      {showCancel && <Modal
        title=<Title level={4}>Annuler l'opérateur {match.operator.fullName} pour cette production</Title>
        visible={showCancel}
        okText="Annuler l'opérateur"
        width={640}
        onOk={() => { cancel(); setShowCancel(false); }}
        onCancel={() => setShowCancel(false)}>
        <p>Message qui sera envoyé à {match.operator.firstName}, vous pouvez le personnaliser.</p>
        <Input.TextArea
          defaultValue={match.cancelMessage || defaultCancelMessage}
          rows={10}
          onChange={(e) => setCancelMessage(e.target.value)}
        />
      </Modal>}
    </Container>
  )
}

export default SearchMatch;
