import { getRepository } from "./RepositoryService";

export const repository = getRepository('notification');

export const getNotifications = async () => {
  return await repository.getAll(true);
};

export const saveNotification = async (notification) => {
  try {
    if (notification && notification.id) {
      return await repository.patch(notification.id, {read: notification.read});
    }
  } catch (error) {
    return error;
  }
  return false;
};

