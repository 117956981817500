import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { UserContext } from '../../context/Context';
import Loader from "../Loader";
import Lottie from 'react-lottie';
import LottieInbox from '../../assets/lotties/inbox.json';

const FormLogin = styled(Form)`
  width: 80vw;
  max-width: 400px;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

function LoginForm() {
  const recaptchaId = process.env.REACT_APP_GOOGLE_RECAPTCHA_ID;

  const { login, loading, errorMessage:userErrorMessage, requestMagicLink, recaptchaToken, setRecaptchaToken } = useContext(UserContext);
  const [email, setEmail] = useState(null);
  const [magicLink, setMagicLink] = useState(true);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieInbox
  };

  const handleLogin = (values: any) => {
    const {
      email,
      password
    } = values;
    return login(email, password);
  };

  const handleMagicLink = async () => {
    if (recaptchaId && !recaptchaToken) {
      setErrorMessage("Votre navigateur n'est pas autorisé à se connecter.\nVeuillez contacter le support.");
      return false;
    }
    let success = await requestMagicLink(email);
    setMagicLinkSent(true === success);
  };

  useEffect(() => {
    const onReCaptchaLoaded = () => {
      if (!recaptchaToken && !recaptchaLoaded && window.grecaptcha?.enterprise) {
        window.grecaptcha.enterprise.ready(function() {
          window.grecaptcha.enterprise.execute(`${recaptchaId}`, {action: 'login'}).then(function(token) {
            setRecaptchaToken(token);
          })
        });
      }
      setRecaptchaLoaded(true);
    };

    const loadRecaptcha = (callback) => {
      const existingScript = document.getElementById("js-grecaptcha");
      const recaptchaUrl = recaptchaId ? 'https://www.google.com/recaptcha/enterprise.js?render=' + recaptchaId : null;
      if (!existingScript && recaptchaUrl && typeof callback === 'function') {
        const script = document.createElement("script");
        script.src = recaptchaUrl;
        script.id = "js-grecaptcha";
        script.async = true;
        script.defer = true;
        script.onload = callback;
        //script.addEventListener("load", () => callback);
        document.body.appendChild(script);
      }
    };

    if (!recaptchaToken && recaptchaId && !recaptchaLoaded) {
      loadRecaptcha(onReCaptchaLoaded);
    }
  }, []);

  useEffect(() => {
    let message = null;
    if (userErrorMessage) {
      if (userErrorMessage.message) {
        message = userErrorMessage.message;
      } else if ('function' === typeof userErrorMessage.toString) {
        message = userErrorMessage.toString();
      }
    }
    setErrorMessage(message);
  }, [userErrorMessage]);

  return (
    <>
    { true === magicLinkSent &&
      <div>
        <Lottie
          options={lottieOptions}
          height={400}
          width={400}
        />
        <div>Vérifiez votre boite de réception 📤&nbsp; nous vous avons envoyé un lien magique 🥖🧙‍‍.</div>
      </div>}
    { false === magicLinkSent &&
      <FormLogin name="loginForm" className="login-form" initialValues={{ remember: true }} onFinish={magicLink ? handleMagicLink : handleLogin}>
        {loading && <LoaderOverlay><Loader/></LoaderOverlay>}
        <Form.Item name="email"
          rules={[{ required: true, message: 'Veuillez saisir votre email !' }]}
          validateStatus={errorMessage ? 'error' : ''}
          help={errorMessage ? errorMessage : ''}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={(evt) => setEmail(evt.target.value)} />
        </Form.Item>
        {false === magicLink && <Form.Item name="password"
    rules={[{ required: true, message: 'Veuillez saisir votre mot de passe !' }]}
        >
          <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Mot de passe" />
        </Form.Item>}
        <Form.Item>
          <Row justify="end">
            {false === magicLink && <Button type="link" onClick={(event) => setMagicLink(true)}>Mot de passe oublié ? 🤔</Button>}
            {(!recaptchaId || null !== recaptchaToken) && <Button type="primary" htmlType="submit" className="login-form-button">
              Connexion
            </Button>}
          </Row>
        </Form.Item>
      </FormLogin> }
    </>
  );
}

export default LoginForm;