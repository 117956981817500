import { getRepository } from "./RepositoryService";

const repository = getRepository('user');
const userRequestRepository = getRepository('userRequest');

export const getUser = async (id: string) => {
  if (id) {
    return repository.get(id);
  }
  return null;
};

const getUserByEmail = async (email) => {
  let users = await repository.getByFilters({email});
  return users.length > 0 ? users[0] : false;
};

const getUserRequestByToken = async (token) => {
  let requests = await userRequestRepository.getByFilters({token});
  if (requests.length !== 1) {
    return false;
  }
  return requests.map(request => {
      request.request = JSON.parse(request.request);
      request.response = JSON.parse(request.response);
      return request;
    })[0];
};

export const authenticateUserPassword = async (email, password) => {
  return getUserByEmail(email);
};

export const authenticateUserToken = async (token: string, email: string, code: string) => {
  let request = await getUserRequestByToken(token);
  if (request && Array.isArray(request.user) && request.user.length > 0) {
    let user = await getUser(request.user[0]);
    if (user && request.request?.code === code) { // && user?.email === email
      return user;
    }
  }
  return false;
};

export const requestUserMagicLink = async (email: string, code: string) => {
  if (email?.trim().length > 0 && code?.trim().length > 0) {
    let user = await getUserByEmail(email);
    if (user) {
      const data = {
          user: [user.id],
          requestedAt: (new Date()).toISOString(),
          type: 'magic-link',
          url: window.location.origin,
          request: JSON.stringify({
            code: code
          })
        };
      let resp = await userRequestRepository.post(data);
      return 200 === resp.status;
    }
  }
  return false;
};