import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useProduction } from '../services/ProductionService';
import { useProductionStatus } from '../services/ProductionStatusService';
import { getOperators } from '../services/OperatorService';
import { getNotifications, saveNotification } from '../services/NotificationService';
import MediaImg from '../components/Media/MediaImg';
import Loader from '../components/Loader';
import OverviewList from '../components/List/OverviewList';
import ImportModal from './Operators/ImportModal';
import ProductionsIllustration from '../assets/img/productions-overview.svg';
import styled from 'styled-components';
import moment from 'moment';
import { ExperimentTwoTone } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';

import { Badge, Row, Col, Card, Button, List, Typography, message } from 'antd';


const { Text } = Typography;

const StyledDashboard = styled.div`
    
`;

const NotificationsList = styled(List)`
  .ant-list-item-meta-title {
    font-weight: bold
  }
  .status-icon {
      max-width: .5rem;
      margin: 0 1.5rem 0 0;
    &.readed {
      color: #e9e9e9;
    }
    &.unread {
      color: ${props => props.theme.brandColor};
    }
  }

  .action-icon {
    max-width: .5rem;
    margin-right: .5rem;
  }

  .ant-list-item {
    padding: 1rem;
    .ant-list-item-action {
        opacity: 0;
        visibility: hidden;
        svg {
          font-size: 1.4rem;
        }
      }
    &:hover {
      background-color: #fdfbfa;
      cursor: pointer;
      .ant-list-item-action {
        opacity: .5;
        visibility: visible;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .ant-list-item-action {
      display: none!important;
    }
  }

`;

const UpToDateBanner = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
font-size: 1.1rem;
  svg {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: ${props => props.theme.textColor};
  }
`;

const Dashboard = () => {

  const [notifications, setNotifications] = useState([]);
  const [operators, setOperators] = useState([]);
  const [visible, setVisible] = useState(false);

  const { productions, getProductions } = useProduction();
  const { statuses: productionStatuses, getStatuses: getProductionStatuses } = useProductionStatus();

  // Onload
  useEffect(() => {
    // load all productions
    getProductionStatuses();
    // load all productions
    getProductions();
    // load notifications
    getNotifications().then(notifications => setNotifications(notifications));
    // load operators
    getOperators().then(operators => setOperators(operators));
  }, []);

  if (productions && operators && productionStatuses && notifications) {
    const incompleteProfiles = operators.filter((operator) => !operator.profileComplete);

    const unreadNotifications = notifications.filter(notification => !notification.read);
    const notificationstoDisplay = unreadNotifications.slice(0, 3);

    const markAsRead = async (item) => {
      item.read = item.read !== true;
      try {
        saveNotification(item);
        setNotifications([...notifications]);
        message.success('Notification marquée comme lue');
      } catch (error) {
        message.error("Une erreur est survenue, impossible d'effectuer l'action demandée");
      }
    };


    return (
      <>
      <StyledDashboard>
          <Row gutter={[32, 32]}>
              <Col xs={24} xl={12}>
                  <Card 
                    title={<h2><b>{productions.length}</b> Productions en cours</h2>}
                    bordered={false}
                    extra={
                    <Button type="primary" className="xs-hide">
                      <Link to="productions/create" className="text-uppercase">Créer une production</Link>
                    </Button>}
                    >
                    <OverviewList items={productionStatuses} itemsType="productions"/>
                    <Row justify="center" className="xs-hide">
                      <MediaImg src={ProductionsIllustration} className="py-1" alt="Créer une production"/>
                    </Row>
                    <Row className="lg-hide py-2" justify="center">
                      <Button type="primary">
                        <Link to="productions/create" className="text-uppercase">Créer une production</Link>
                      </Button>
                    </Row>
                  </Card>
              </Col>
              <Col xs={24} xl={12}>
                  <Card 
                    title={<h2><b>{operators.length}</b> Opérateurs</h2>}
                    bordered={false}
                    extra={<Badge count={<ExperimentTwoTone twoToneColor="#FF4000" />}><Button onClick={() => setVisible(true)}>IMPORTER</Button></Badge>}
                  > 
                    <OverviewList items={incompleteProfiles} itemsType="operators"/>
                  </Card>

                  <Card 
                    bordered={false} 
                    className="mt-2"
                    title={<h2>Notifications</h2>}
                    extra={
                      <Button className="xs-hide">
                        <Link to="notifications" className="text-uppercase">Voir tout</Link>
                      </Button>}
                  >

                    {
                      notificationstoDisplay.length > 0 &&
                      <NotificationsList
                      itemLayout="horizontal"
                      dataSource={notificationstoDisplay}
                      renderItem={item => (

                        <List.Item
                        onClick={() => markAsRead(item)}
                        actions={ 
                          !item.read ? [
                            <Button title="Marquer comme lu" type="link"><FontAwesomeIcon icon={faCheckCircle}/></Button>
                          ] : [
                            <Button title="Marquer comme non lu" type="link"><FontAwesomeIcon icon={ faPlusCircle }/></Button>
                          ]
                          }>
                        
                        <>
                        { !item.read ? <FontAwesomeIcon icon={ faCircle } className="status-icon unread"/> : <FontAwesomeIcon icon={ faCircle } className="status-icon readed"/>}
                        </>
        
                        <List.Item.Meta
                          title={<Text ellipsis={true}>{moment(item.createdAt).fromNow()}</Text>}
                          description={item.message}
                        />
        
                      </List.Item>

                      )}
                      />
                    }

                    {
                      notificationstoDisplay.length === 0 && 
                      <UpToDateBanner>
                        <FontAwesomeIcon icon={faCheckCircle}/>
                        <Text>Vous êtes à jour !</Text>
                      </UpToDateBanner>
                    }
                  </Card>
              </Col>
          </Row>
      </StyledDashboard>

      <ImportModal
        visible={visible}
        onCancel={() => setVisible(false)}
      />

      </> 
    )
  }

  return <Loader/>
};

export default Dashboard
