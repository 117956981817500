// Layouts for future : Landing / Login / Signup
import React, { useContext } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import { UserContext } from "../context/Context";
import ErrorHandler from "../components/ErrorHandler";
import AppLayout from './AppLayout';
import AuthLayout from './AuthLayout';
import OperatorAppLayout from './OperatorAppLayout';
import { getRoute } from "../helpers/getPageName";

const Layout = () => {
  const { auth } = useContext(UserContext);
  const location = useLocation();
  const route = getRoute(location);

  return (
    <ErrorHandler>
      <Switch>
        <Route component={'operator' === route?.context ? OperatorAppLayout : (true === auth?.valid ? AppLayout : AuthLayout)} />
      </Switch>
    </ErrorHandler>
  );
};

export default Layout;
