import { useState, useEffect } from "react";

export const getStorageValue = (key: string, defaultValue = null) => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(key) ?? 'null') ?? defaultValue;
  }
};

export const setStorageValue = (key:string , value) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const clearStorageValue = (key:string) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(key)) {
      localStorage.setItem(key, null);
    }
  }
};

export const removeStorageItem = (key:string) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;