// App Styled Component theme
import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  brandColor: "#FF4000",
  textColor: "#343D46",
  productionStatus: {
    inSelection: "#FFDC75",
    selectionComplete: "#D2F273",
    productionStarted: "#FFA37F",
    productionDone: "#D8C58B",
    productionArchived: "#E8E8E8"
  },
  operatorAvaibility: {
    available: "#1CB16E",
    unavailable: "#9a9a9a"
  },
  jobStatus: {
    accepted: "#E5F6BD",
    pending: "#FFF0D2",
    refused: "#FAD1D2",
    canceled: "#E7E7E7"
  },
  searchStatus: {
    pending: "#FFF0D2",
    processing: "#FFF0D2",
    succeeded: "#E5F6BD",
    failed: "#FAD1D2",
    selectionProcessing: "#FFF0D2",
    selectionComplete: "#E5F6BD",
    confirmationProcessing: "#FFF0D2",
    confirmationComplete: "#E5F6BD"
  },
  matchStatus: {
    pending: "#FFF0D2",
    processing: "#FFF0D2",
    sent: "#FFF0D2",
    accepted: "#E5F6BD",
    rejected: "#FAD1D2",
    canceled: "#E7E7E7"
  }
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;

