import { useState, useEffect } from "react";
import { getRepository } from "./RepositoryService";

const repository = getRepository('productionStatus');

export const statusCode = {
	CREATION: 'CREATION',
	TEAM_SELECTION: 'TEAM-SELECTION',
	TEAM_COMPLETE: 'TEAM-COMPLETE',
	MAKING: 'MAKING',
	COMPLETE: 'COMPLETE',
	ARCHIVE: 'ARCHIVE'
};

async function loadStatuses(ids: Array = null) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for productionStatus!');
	}
	return null === ids ? await repository.getAll(true) : await repository.get(ids);
}

export const getStatuses = async (ids: Array) => {
	return await loadStatuses(ids ?? null);
};

export const getStatus = async function (id) {
	switch (id) {
		case statusCode.CREATION:
		case statusCode.TEAM_SELECTION:
		case statusCode.TEAM_COMPLETE:
		case statusCode.MAKING:
		case statusCode.COMPLETE:
		case statusCode.ARCHIVE:
			return (await getStatuses(null)).find(status => status.code === id) ?? null;
		default:
			await getStatuses([id]);
			return repository.get(id);
	}
	return null;
};


const listeners = new Set();

export const useProductionStatus = () => {
	const [statuses, setStatuses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getStatuses = async function (ids: Array) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let statuses = await loadStatuses(ids ?? null);
		setLoading(false);
		listeners.forEach(listener => listener());
		return statuses;
	};

	const getStatus = async function (id) {
		switch (id) {
			case statusCode.CREATION:
			case statusCode.TEAM_SELECTION:
			case statusCode.TEAM_COMPLETE:
			case statusCode.MAKING:
			case statusCode.COMPLETE:
			case statusCode.ARCHIVE:
				if (!repository?.isFullyLoaded()) {
					await getStatuses(null);
				}
				return statuses.find(status => status.code === id) ?? null;
			default:
				await getStatuses([id]);
				return repository.get(id);
		}
		return null;
	};

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(statuses => {
				setStatuses(statuses);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, statuses, getStatuses, getStatus };
};

export default useProductionStatus;