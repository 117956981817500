import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Badge, Typography, Card, Collapse, Empty, Form, Select, DatePicker, Space, Button, Row, Col, Input, message, Steps, Switch, Tabs, Tag } from 'antd';
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { updateOperatorSearchMatches, updateOperatorSearchStatus } from "../../services/OperatorService";
import { useProduction } from "../../services/ProductionService";
import { useProductionStage } from "../../services/ProductionStageService";
import { useSkill } from '../../services/SkillService';
import Loader from '../../components/Loader';
import SearchMatch from "../../components/Operator/SearchMatch";
import styled from 'styled-components';
import { searchOperators, getOperatorSearch } from "../../services/OperatorService";
import { statusCode as operatorSearchStatusCode, useOperatorSearchStatus } from "../../services/OperatorSearchStatus";
import { statusCode as operatorSearchMatchStatusCode } from "../../services/OperatorSearchMatchStatus";
import { UserContext } from "../../context/Context";
import Lottie from 'react-lottie';
import LottieOperatorSearching from '../../assets/lotties/operators-searching.json';
import BackButton from "../../components/BackButton";

const InlineForm = styled(Form)`
  .ant-space {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
      flex-direction: column;
      .ant-space-item {
        width: 100%;
        margin-right: 0!important ;
        .ant-select, .ant-btn, .ant-picker {
          width: 100%!important;
        }
      }
    }
  }
`;

const StyledTag = styled(Tag)`
  border: none;
  padding: 4px 15px;
  font-size: .9rem;
  font-weight: bold;
  background-color: #FFFFFF;

  &.pending {
    background-color: ${props => props.theme.searchStatus.pending};
  }
  &.processing {
    background-color: ${props => props.theme.searchStatus.processing};
  }
  &.succeeded {
    background-color: ${props => props.theme.searchStatus.succeeded};
  }
  &.failed {
    background-color: ${props => props.theme.searchStatus.failed};
  }
  &.selection-processing {
    background-color: ${props => props.theme.searchStatus.selectionProcessing};
  }
  &.selection-done {
    background-color: ${props => props.theme.searchStatus.selectionComplete};
  }
  &.confirmation-processing {
    background-color: ${props => props.theme.searchStatus.confirmationProcessing};
  }
  &.confirmation-done {
    background-color: ${props => props.theme.searchStatus.confirmationComplete};
  }
`;

const SearchSteps = styled(Steps)`
  .ant-steps-item-container, .ant-steps-item-icon {
    display: flex;
  }
  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon .ant-progress {
   display: flex;
  }
  .ant-steps-icon, .ant-steps-progress-icon {
    line-height: 30px;
    margin: auto;
  }
  &:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 300px !important;
  }
`;

const MatchesContainer = styled(Row)`
  position: relative;
  justify-content: start;
  .ant-empty:not(:first-child) {
    display: none;
  }
`;

const MatchesCollapse = styled(Collapse)`
  width: 100%;
  margin-top: 2rem;
  > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
  }
  > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0;
    display: none;
  }
  > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
    margin: -8px;
  }
`;

const NoMatch = styled(Empty)`
  :not(:first-child) {
    display: none;
  }
`;

const MatchesStagesContainer = styled('div')`
  position: relative;
`;

const MatchesStagesTabs = styled(Tabs)`
  .ant-tabs-content {
    margin-top: -16px;
  }
  .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-top: none;
  }
  .ant-tabs-nav::before {
    display: none;
  }
`;

const MatchesStagesOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9000;
  background: rgba(255,255,255,0.9);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 40px;
`;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Title } = Typography;

/* Matches Collapse */
const MatchesCollapseContainer = ({stage, skills, matches, showUnselectedMatches, showMatchesStatus, onSortEnd, disabled, ...props}) => {
  return (
    <MatchesCollapse defaultActiveKey={skills.map(skill => stage.id + '-' + skill.id)} ghost={true}>
      {skills.map(skill => {
        let skillsMatches = matches.filter(match => {
          return (match.skill.id === skill.id) &&
                (showUnselectedMatches || match.selected) &&
                (!Array.isArray(showMatchesStatus) || 0 === showMatchesStatus.length || -1 !== showMatchesStatus.indexOf(match.status?.code))
          }) || [];
        skillsMatches = skillsMatches.sort((s1, s2) => s1.ranking - s2.ranking);
        return (
          <Collapse.Panel header={<Title level={4} style={{marginBottom: 0}}>{skill.name}&nbsp;<Badge count={skillsMatches.length} /></Title>}
                          key={stage.id + '-' + skill.id}>
            <SortableList axis="xy" distance={5}
                          shouldCancelStart={(event) => { return true === disabled || event.target.tagName.toLowerCase() !== 'div';}}
                          onSortEnd={onSortEnd ?? null}
                          matches={skillsMatches}
                          collection={stage.id + '-' + skill.id}
                          disabled={disabled}/>
          </Collapse.Panel>
        )
      })}
    </MatchesCollapse>
  );
}
/* React Sortable */
const SortableItem = SortableElement(
({match}) => <SearchMatch key={match.id} match={match} />
);
const SortableList = SortableContainer(({matches, collection, disabled}) => {
  if (!Array.isArray(matches)) {
    return <></>;
  }
  return (
      <MatchesContainer>
        {matches.map((match, index) => (
            <SortableItem key={`item-${match.id}`} disabled={true === disabled} index={index} collection={collection} match={match} />
        ))}
        <NoMatch description="Trouver des opérateurs dans la communauté Opside" style={{ margin: 'auto' }} />
      </MatchesContainer>
  );
});

const SearchOperators = ({ match, location }) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const searchStepsRef = useRef();

  // parameters
  const { params: { id: operatorSearchId } } = match;
  let { productionName, productionId } = location || {};
  if (productionName && productionId) {
    localStorage.setItem('productionName', productionName);
    localStorage.setItem('productionId', productionId);
  }
  productionName = localStorage.getItem('productionName')
  productionId = localStorage.getItem('productionId')

  // States
  const [autocomplete, setAutocomplete] = useState([]);
  const [map, setMap] = useState([]);
  const [geolocation, setGeolocation] = useState([]);
  const [operatorSearch, setOperatorSearch] = useState({
    id: null,
    production: null,
    skills: [],
    startDate: null,
    endDate: null,
    location: null,
    lat: null,
    lng: null,
    streetNumber: null,
    route: null,
    postalCode: null,
    locality: null,
    administrativeLevel2: null,
    administrativeLevel1: null,
    country: null,
    countryCode: null,
    notes: null,
    user: [user.id],
    matches: [],
    status: null
  });
  const [matches, setMatches] = useState(new Map());
  const [showUnselectedMatches, setShowUnselectedMatches] = useState(false);
  const [showMatchesStatus, setShowMatchesStatus] = useState([]);
  const [step, setStep] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(operatorSearch?.id === null);

  // Queries
  const { skills, getSkills, loading:loadingSkills } = useSkill();
  const { getProduction, loading:loadingProductions } = useProduction();
  const { stages, getStages, loading:loadingStages } = useProductionStage();
  const { getOperatorSearchStatuses, getOperatorSearchStatus } = useOperatorSearchStatus();

  useEffect(() => {
    async function initSearchOperator() {
      // load globals @todo move to app loading
      await getStages();
      await getSkills(null, false);
      if (operatorSearchId && !operatorSearch?.id) {
        await getOperatorSearchStatuses();
        let search = await getOperatorSearch(operatorSearchId, false);
        if (search && search.id) {
          loadOperatorSearch(search);
        }
      } else {
        if (productionId) {
          operatorSearch.production = await getProduction(productionId);
        }
        setLoading(false);
      }
    }
    initSearchOperator();
  }, [form]);

  useEffect(() => {
    refreshShowMatchesStatus();
  }, [step, operatorSearch]);

  useEffect(() => {
    if (Array.isArray(stages) && stages.length > 0) {
      if (!stages.find(stage => stage.name === 'Non défini')) {
        stages.push({id: 'undefined', step: 99, name: 'Non défini', skills: []});
      }
    }
  }, [stages]);

/*
  useEffect(() => {
    if (searchStepsRef?.current && !scrolled) {
      //searchStepsRef.current.scrollIntoView({ behavior: 'smooth' });
      #let main = document.querySelector('main');
      #if (main?.scrollTop > 0) {
      #}
    }
  });
*/

  async function loadOperatorSearch(record) {
    if (record) {
      setOperatorSearch(record);
      setEdit(false);
      setGeolocation({
        location: record.location,
        lat: record.lat,
        lng: record.lng,
        streetNumber: record.streetNumber,
        route: record.route,
        postalCode: record.postalCode,
        locality: record.locality,
        administrativeLevel2: record.administrativeLevel2,
        administrativeLevel1: record.administrativeLevel1,
        country: record.country,
        countryCode: record.countryCode
      });
      if (form) {
        form.setFieldsValue({
          skills: record.skills.map(skill => skill.name),
          period: [record.startDate ? moment(record.startDate) : null, record.endDate ? moment(record.endDate) : null],
          notes: record.notes
        });
      }
      // sort matches
      if (Array.isArray(record.matches)) {
        sortOperatorSearchMatches(record.matches);
      }
      setLoading(false);
      // search step
      switch (record?.status?.code) {
        case operatorSearchStatusCode.PENDING:
        case operatorSearchStatusCode.PROCESSING:
        case operatorSearchStatusCode.FAILED:
          setStep(0);
          break;
        case operatorSearchStatusCode.SUCCEEDED:
        case operatorSearchStatusCode.SELECTION_PROCESSING:
        case operatorSearchStatusCode.SELECTION_DONE:
          setStep(1);
          break;
        case operatorSearchStatusCode.CONFIRMATION_PROCESSING:
        case operatorSearchStatusCode.CONFIRMATION_DONE:
          setStep(2);
          break;
        default:
          setStep(0);
          break
      }
      // toggle unselected matches display
      switch (record?.status?.code) {
        case operatorSearchStatusCode.SELECTION_DONE:
        case operatorSearchStatusCode.CONFIRMATION_PROCESSING:
        case operatorSearchStatusCode.CONFIRMATION_DONE:
          setShowUnselectedMatches(false);
          break;
        default:
          setShowUnselectedMatches(true);
          break
      }
    }
  }

  function sortOperatorSearchMatches(matches: Array) {
    if (Array.isArray(matches) && matches.length > 0) {
      // @todo extract sort function to reuse it with other .sort() in this component
      matches.sort((a, b) => {
        if (a.selected && !b.selected) {
          return -1;
        }
        if (b.selected && !a.selected) {
          return 1;
        }
        if ('ranking' in a && 'ranking' in b ) {
          return a.ranking - b.ranking;
        }
        if (b.selected && !a.selected) {
          return 1;
        }
        if ('score' in a && 'score' in b && a.score !== b.score) {
          return b.score - a.score;
        }
        if ('distance' in a && 'distance' in b && a.distance !== b.distance) {
          return a.distance - b.distance;
        }
        if (a.jobTitle < b.jobTitle) {
          return -1;
        }
        if (b.jobTitle < a.jobTitle) {
          return 1;
        }
        return 0;
      });
      let matchesByStage = matches.reduce((matchesByStage, match) => {
        if(Array.isArray(match.skill?.stages)) {
          match.skill.stages.forEach(stage =>{
            (matchesByStage[stage.id] = matchesByStage[stage.id] || []).push(match);
          });
        } else {
          (matchesByStage['undefined'] = matchesByStage['undefined'] || []).push(match);
        }
        return matchesByStage;
      }, []);
      setMatches(new Map(Object.entries(matchesByStage)));
    }
  }

  function refreshShowMatchesStatus() {
    // filter matches by status
    if (2 === step && operatorSearchStatusCode.CONFIRMATION_DONE === operatorSearch?.status?.code) {
      setShowMatchesStatus([operatorSearchMatchStatusCode.ACCEPTED]);
    } else {
      setShowMatchesStatus([]);
    }
  }

  const backToProduction = () => {
    localStorage.removeItem('productionName');
    localStorage.removeItem('productionId');
    history.goBack()
  };

  const onFinish = async (values) => {
    let { skills, period, notes } = values;
    let startDate = period && period.length > 1 ? period[0].toISOString() : null;
    let endDate = period && period.length > 1 ? period[1].toISOString() : null;
    let tmpOperatorSearch = {...operatorSearch};
    if (Array.isArray(skills) && skills.length) {
      tmpOperatorSearch.skills = skills;
    }
    if (startDate) {
      tmpOperatorSearch.startDate = startDate;
    }
    if (endDate) {
      tmpOperatorSearch.endDate = endDate;
    }
    tmpOperatorSearch.notes = notes;
    Object.assign(tmpOperatorSearch, geolocation);
    if (!tmpOperatorSearch.status) {
      tmpOperatorSearch.status = operatorSearchStatusCode.PENDING;
    }
    // todo manage multiple users if current user is not the user who create the search first
    const search = await searchOperators(tmpOperatorSearch);
    if(search) {
      loadOperatorSearch(search);
      success('La recherche a bien été enregistrée');
    } else {
      error('Impossible de rechercher des opérateurs');
    }
  };

  const success = (messageText) => {
    message.success(messageText);
  };

  const error = (messageText) => {
    message.error(messageText);
  };

  const onMapLoad = map => {
    setMap(map);
  };

  const onAutocompleteLoad = autocomplete => {
    setAutocomplete(autocomplete);
  };

  const onAutocompletePlaceChanged = () => {
    let place = autocomplete?.getPlace();
    if (place?.formatted_address || place?.geometry) {
      let geolocation = {
        location: place.formatted_address || null,
        lat: place.geometry?.location?.lat() || null,
        lng: place.geometry?.location?.lng() || null
      };
      if (Array.isArray(place.address_components)) {
        place.address_components.forEach(component => {
          if (-1 !== component.types.indexOf('street_number')) {
            geolocation.streetNumber = component.long_name;
          } else if (-1 !== component.types.indexOf('route')) {
            geolocation.route = component.long_name;
          } else if (-1 !== component.types.indexOf('postal_code')) {
            geolocation.postalCode = component.long_name;
          } else if (-1 !== component.types.indexOf('locality')) {
            geolocation.locality = component.long_name;
          } else if (-1 !== component.types.indexOf('administrative_area_level_2')) {
            geolocation.administrativeLevel2 = component.long_name;
          } else if (-1 !== component.types.indexOf('administrative_area_level_1')) {
            geolocation.administrativeLevel1 = component.long_name;
          } else if (-1 !== component.types.indexOf('country')) {
            geolocation.country = component.long_name;
            geolocation.countryCode = component.short_name;
          }
        });
      }
      setGeolocation(geolocation);
    }
  };

  const onStepChange = current => {
    setStep(current);
  };

  const onSortEnd = async ({collection, oldIndex, newIndex }) => {
    if (operatorSearch?.status?.code !== operatorSearchStatusCode.SELECTION_PROCESSING) {
      error(`La sélection ne peut-être modifiée car elle est à l'étape ${operatorSearch.status.name}`);
      return false;
    }
    const [stage, skill] = collection.split('-');
    let stageMatches = matches.get(stage);
    if (Array.isArray(stageMatches)) {
      let skillMatches = stageMatches.filter(match => (match.skill.id === skill) && (showUnselectedMatches || match.selected));
      if (Array.isArray(skillMatches) && skillMatches.length > Math.max(oldIndex, newIndex)) {
        skillMatches.sort((m1, m2) => (m1.ranking || 0) - (m2.ranking || 0));
        for(let i = 0; i < newIndex; i++) {
          skillMatches[i].ranking = i + 1;
        }
        for(let i = newIndex; i < skillMatches.length; i++) {
          skillMatches[i].ranking = i + 2;
        }
        skillMatches[oldIndex].ranking = newIndex + 1;
        skillMatches.sort((m1, m2) => m1.ranking - m2.ranking);
        await updateOperatorSearchMatches(skillMatches);
      }
    }
    setMatches(new Map(matches));
    return true;
  }

  const startSelection = async () => {
    if (operatorSearch.status && operatorSearch.status.code !== operatorSearchStatusCode.SUCCEEDED) {
      error(`La sélection est à l'étape ${operatorSearch.status.name}`);
    } else {
      let status = await getOperatorSearchStatus(operatorSearchStatusCode.SELECTION_PROCESSING);
      if (status && status.id) {
        setLoading(true);
        let search = await updateOperatorSearchStatus(operatorSearch, status);
        setLoading(false);
        if (false !== search) {
          setOperatorSearch(search);
          return true;
        }
      }
    }
    return false;
  };

  const validateSelection = async () => {
    if (operatorSearch.status && operatorSearch.status.code !== operatorSearchStatusCode.SELECTION_PROCESSING) {
      error(`La recherche ne peut-être validée car elle est à l'étape ${operatorSearch.status.name}`);
    } else {
      let status = await getOperatorSearchStatus(operatorSearchStatusCode.SELECTION_DONE);
      if (status && status.id) {
        setLoading(true);
        let search = await updateOperatorSearchStatus(operatorSearch, status);
        setLoading(false);
        if (false !== search) {
          setOperatorSearch(search);
          return true;
        }
      }
    }
    return false;
  };

  const validateConfirmation = async () => {
    if (operatorSearch.status && operatorSearch.status.code !== operatorSearchStatusCode.CONFIRMATION_PROCESSING) {
      error(`La recherche ne peut-être close car elle est à l'étape ${operatorSearch.status.name}`);
    } else {
      let status = await getOperatorSearchStatus(operatorSearchStatusCode.CONFIRMATION_DONE);
      if (status && status.id) {
        setLoading(true);
        let search = await updateOperatorSearchStatus(operatorSearch, status);
        setLoading(false);
        if (false !== search) {
          setOperatorSearch(search);
          return true;
        }
      }
    }
    return false;
  };

  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const getListStyle = isDraggingOver => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    overflow: 'auto',
    background: isDraggingOver ? 'lightgrey' : 'none',
  });

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieOperatorSearching
  };

  // search steps & labels
  let stepDescriptions = ['', '', ''];
  let stepStatus = ['wait', 'wait', 'wait'];
  switch (operatorSearch?.status?.code) {
    case operatorSearchStatusCode.PENDING:
      stepDescriptions[0] = 'En attente';
      break;
    case operatorSearchStatusCode.PROCESSING:
      stepDescriptions[0] = 'En cours';
      stepStatus[0] = 'process';
      break;
    case operatorSearchStatusCode.SUCCEEDED:
      stepDescriptions[0] = 'Terminée';
      stepStatus[0] = 'finish';
      break;
    case operatorSearchStatusCode.FAILED:
      stepDescriptions[0] = 'Échouée';
      stepStatus[0] = 'error';
      break;
    case operatorSearchStatusCode.SELECTION_PROCESSING:
      stepDescriptions[0] = 'Terminée';
      stepDescriptions[1] = 'En cours';
      stepStatus[0] = 'finish';
      stepStatus[1] = 'process';
      break;
    case operatorSearchStatusCode.SELECTION_DONE:
      stepDescriptions[0] = 'Terminée';
      stepDescriptions[1] = 'Validée';
      stepStatus[0] = 'finish';
      stepStatus[1] = 'finish';
      break;
    case operatorSearchStatusCode.CONFIRMATION_PROCESSING:
      stepDescriptions[0] = 'Terminée';
      stepDescriptions[1] = 'Validée';
      stepDescriptions[2] = 'En cours';
      stepStatus[0] = 'finish';
      stepStatus[1] = 'finish';
      stepStatus[2] = 'process';
      break;
    case operatorSearchStatusCode.CONFIRMATION_DONE:
      stepDescriptions[0] = 'Terminée';
      stepDescriptions[1] = 'Validée';
      stepDescriptions[2] = 'Recherche terminée 🎉';
      stepStatus[0] = 'finish';
      stepStatus[1] = 'finish';
      stepStatus[2] = 'finish';
      break;
    default:
      break
  }

  if (!loading && !loadingSkills && !loadingStages) {
    return (
      <>
        <Space size="middle" direction="vertical" style={{ width: "100%" }}>
          { (operatorSearch?.production?.id || productionId) &&
            <Row gutter={64} justify="space-between" align="middle">
                <Col span={18}>
                    <BackButton text={ 'Retour à la production ' + (operatorSearch?.production?.name ? operatorSearch.production.name : productionName) }/>
                </Col>
                <Col>
                  <StyledTag className={operatorSearch?.status?.code?.toLowerCase()}>{operatorSearch?.status?.name?.toUpperCase()}</StyledTag>
                </Col>
            </Row> }
          <Card>
            {edit && <Form
              form={form}
              layout="vertical"
              name="search_operators"
              onFinish={onFinish}
            >
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              libraries={["places, drawing"]}
            >
            <Row gutter={[16, 16]} justify="center" align="stretch">
              <Col span={12}>
                <Space size="middle" direction="vertical" style={{ width: "100%" }}>
                  <Form.Item
                    label="Poste"
                    name="skills"
                    style={{ width: "100%" }}
                  >
                  <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Sélectionnez un poste"
                  >
                    {skills.map((item) => (
                      <Option key={item.id} value={item.name}>{item.name}</Option>
                    ))}
                  </Select>
                  </Form.Item>

                  <Form.Item
                    label="Période"
                    name="period"
                    style={{ width: "100%" }}
                  >
                    <RangePicker style={{ width: "100%" }} format="DD/MM/YY" />
                  </Form.Item>

                  <Form.Item
                    label="Notes / Complément d'informations "
                    name="notes"
                    style={{ width: "100%" }}
                  >
                    <TextArea style={{ width: "100%" }} maxLength={255} autoSize={{ minRows: 8, maxRows: 8 }} />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space size="middle" direction="vertical" style={{ width: "100%" }}>
                  <Form.Item
                    label="Localisation"
                    name="location"
                    style={{ marginBottom: '0' }}
                  >
                    <Autocomplete
                      onLoad={onAutocompleteLoad}
                      onPlaceChanged={onAutocompletePlaceChanged}
                      restrictions={{ country: "fr" }}
                      fields={['geometry.location', 'address_components', 'formatted_address', 'name']}
                    >
                      <input
                        id="autocomplete-input"
                        type="text"
                        placeholder="Enter your address"
                        className="ant-input"
                        defaultValue={geolocation?.location || ''} />
                    </Autocomplete>
                  </Form.Item>
                  <GoogleMap
                    center={{ lat: geolocation?.lat || 46.232193, lng:  geolocation?.lng || 2.209667 }}
                    clickableIcons={false}
                    extraMapTypes={[]}
                    zoom={5}
                    id="map"
                    mapContainerStyle={{ minWidth:"30vw", minHeight:"45vh" }}
                    onLoad={onMapLoad}
                  >
                    <Marker position={{ lat: geolocation?.lat || 43.300000, lng: geolocation?.lng || 5.400000}} />
                  </GoogleMap>
                </Space>
              </Col>
            </Row>
            </LoadScript>
            <Row justify="center">
              <Space size="middle" style={{ justifyContent: 'flex-end' }}>
                {operatorSearch?.id && <Form.Item style={{marginBottom: "0"}}>
                  <Button htmlType="button" onClick={() => setEdit(false)}>Annuler</Button>
                </Form.Item>}
                <Form.Item style={{marginBottom: "0"}}>
                  <Button type="primary" htmlType="submit" style={{minWidth: "10vw"}}>Rechercher</Button>
                </Form.Item>
              </Space>
            </Row>
            </Form>}
            {!edit && <Row>
              <Col span={16}>
                Recherche {productionName ? " pour la production " + productionName : ""}
                <ul>
                  <li>{operatorSearch?.skills.map(skill => skill.name).join(', ')}</li>
                  {operatorSearch?.startDate && operatorSearch?.endDate &&
                  <li>du {moment(operatorSearch.startDate).format('LL')} au {moment(operatorSearch.endDate).format('LL')}</li>}
                  <li>autour de {operatorSearch.locality} </li>
                </ul>
              </Col>
              <Col span={8} align="right">
                <Space size="small" direction="vertical" style={{ width: "100%" }}>
                  <Button type="primary" htmlType="button" onClick={() => setEdit(true)}><EditOutlined/>Modifier</Button>
                  {operatorSearch?.status?.code === operatorSearchStatusCode.CONFIRMATION_PROCESSING &&
                    <Button type="primary" htmlType="button" onClick={validateConfirmation}><CheckOutlined/>Clore la recherche</Button>}
                </Space>
              </Col>
            </Row>
            }
          </Card>
          <div ref={searchStepsRef} style={{paddingTop: '2.5rem'}}>
            <SearchSteps current={step} percent={60} onChange={onStepChange}>
              <Steps.Step title="Recherche d'opérateurs" description={stepDescriptions[0]} status={stepStatus[0]} disabled={true}/>
              <Steps.Step title="Sélection" description={stepDescriptions[1]} status={stepStatus[1]} disabled={stepDescriptions[1]===''}/>
              <Steps.Step
                  title={operatorSearch?.status?.code === operatorSearchStatusCode.CONFIRMATION_DONE ? "Équipe confirmée" : "Confirmation des opérateurs"}
                  description={stepDescriptions[2]} status={stepStatus[2]} disabled={stepDescriptions[2]===''}/>
            </SearchSteps>
          </div>
          <Tabs activeKey={step.toString()} tabBarStyle={{display: 'none'}}>
            <Tabs.TabPane tab="Step 0" key="10">
              <Space direction="horizontal" align="center" size="large" style={{marginBottom: '2.5rem'}}>
                <Lottie
                    options={lottieOptions}
                    height={300}
                    width={400}
                />
                <div>Votre sélection d'opérateurs est en cours de traitement...</div>
              </Space>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Step 1" key="1">
              <Space direction="vertical" style={{ width: '100%' }} className={2 !== step ? 'hidden' : ''}>
                <Title level={4} className="mt-2">Votre sélection d'opérateurs est prête !</Title>
                <p>Vous pouvez consulter et modifier la sélection d'opérateurs avant de valider.</p>

                {operatorSearch?.status?.code !== operatorSearchStatusCode.SUCCEEDED &&
                <Card bodyStyle={{display: 'flex'}}>
                  <span>
                    Afficher les opérateurs non sélectionnés &nbsp;<Switch checked={showUnselectedMatches} onChange={(checked) => setShowUnselectedMatches(checked)} />
                  </span>
                  {operatorSearch?.status?.code === operatorSearchStatusCode.SELECTION_PROCESSING &&
                    <Button type="primary" htmlType="button" style={{width: "15vw", marginLeft: 'auto'}} onClick={validateSelection}>Valider la sélection</Button>
                  }
                </Card>}

                <MatchesStagesContainer>
                  <MatchesStagesTabs type="card">
                    { stages.sort((s1, s2) => s1.step - s2.step).map(stage => (
                        matches.has(stage.id) &&
                      <Tabs.TabPane
                          tab={
                            <Title level={4}>{stage.name}&nbsp;
                              <Badge count={matches.get(stage.id).filter(match => (
                                  (showUnselectedMatches || match.selected) &&
                                  (!Array.isArray(showMatchesStatus) || 0 === showMatchesStatus.length || -1 !== showMatchesStatus.indexOf(match.status?.code))
                              )).length} /></Title>}
                          key={stage.id}>
                        <MatchesCollapseContainer
                            stage={stage}
                            skills={operatorSearch?.skills.filter(skill => Array.isArray(skill.stages) ? skill.stages.some(s => s.id === stage.id) : 'undefined' === stage.id)}
                            matches={matches.get(stage.id)}
                            showUnselectedMatches={showUnselectedMatches}
                            showMatchesStatus={showMatchesStatus}
                            onSortEnd={onSortEnd}
                        />
                      </Tabs.TabPane>
                    )) }
                  </MatchesStagesTabs>
                  {operatorSearch?.status?.code === operatorSearchStatusCode.SUCCEEDED &&
                  <MatchesStagesOverlay>
                    <Button type="primary" htmlType="button" size="large" onClick={startSelection}>Démarrer la sélection</Button>
                  </MatchesStagesOverlay>
                  }
                </MatchesStagesContainer>
              </Space>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Step 2" key="2">
              <Space direction="vertical" style={{ width: '100%' }}>
                {operatorSearch?.status?.code === operatorSearchStatusCode.CONFIRMATION_PROCESSING &&
                  <>
                    <Title level={4} className="mt-2">Votre sélection d'opérateurs est en cours de confirmation ...</Title>
                  </>}
                {operatorSearch?.status?.code === operatorSearchStatusCode.CONFIRMATION_DONE &&
                  <>
                    <Title level={4} className="mt-2">Votre équipe d'opérateurs est confirmée !</Title>
                  </>}
                <MatchesStagesContainer>
                  <MatchesStagesTabs type="card">
                    { stages.sort((s1, s2) => s1.step - s2.step).map(stage => (
                        matches.has(stage.id) &&
                        <Tabs.TabPane
                            tab={
                              <Title level={4}>{stage.name}&nbsp;
                                <Badge count={matches.get(stage.id).filter(match => (
                                    (showUnselectedMatches || match.selected) &&
                                    (!Array.isArray(showMatchesStatus) || 0 === showMatchesStatus.length || -1 !== showMatchesStatus.indexOf(match.status?.code))
                                )).length} /></Title>}
                            key={stage.id}>
                          <MatchesCollapseContainer
                              stage={stage}
                              skills={operatorSearch?.skills.filter(skill => Array.isArray(skill.stages) ? skill.stages.some(s => s.id === stage.id) : 'undefined' === stage.id)}
                              matches={matches.get(stage.id)}
                              showUnselectedMatches={false}
                              showMatchesStatus={showMatchesStatus}
                              disabled={true}
                          />
                        </Tabs.TabPane>
                    )) }
                  </MatchesStagesTabs>
                </MatchesStagesContainer>
              </Space>
            </Tabs.TabPane>
          </Tabs>
          { (operatorSearch?.production?.id || productionId) &&
            <Row>
              <Col span={24}>
                <BackButton text={ 'Retour à la production ' + (operatorSearch?.production?.name ? operatorSearch.production.name : productionName) }/>
              </Col>
            </Row> }
        </Space>
      </>
    )
  }
  return <Loader/>
}

export default SearchOperators;
