import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Empty, Form, DatePicker, Space, Button, Row, Col, Input, message } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api';
import { UserContext } from "../../context/Context";
import { useSession } from '../../services/SessionService';
import Loader from '../../components/Loader';
import SessionSkills from "./SessionSkills";

const { RangePicker } = DatePicker;
const { TextArea } = Input;


const Session = ({ instance, production, ...props }) => {

  const { user } = useContext(UserContext);

  const [form] = Form.useForm();

  // States
  const [autocomplete, setAutocomplete] = useState([]);
  const [geolocation, setGeolocation] = useState([]);
  const [session, setSession] = useState({
    id: null,
    production: null,
    name: null,
    startDate: null,
    endDate: null,
    sessionsSkills: [],
    location: null,
    lat: null,
    lng: null,
    streetNumber: null,
    route: null,
    postalCode: null,
    locality: null,
    administrativeLevel2: null,
    administrativeLevel1: null,
    country: null,
    countryCode: null,
    notes: null,
    user: [user.id]
  });
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(instance?.id == null);
  const [addSessionSkills, setAddSessionSkills] = useState(false);

  const { getSession, saveSession } = useSession();

  useEffect(() => {
    if (instance) {
      if (production && production.id && production.id !== instance?.production?.id) {
        message.error("Invalid production given");
      } else {
        setSession(instance);
        setGeolocation({
          location: instance?.location,
          lat: instance?.lat,
          lng: instance?.lng,
          streetNumber: instance?.streetNumber,
          route: instance?.route,
          postalCode: instance?.postalCode,
          locality: instance?.locality,
          administrativeLevel2: instance?.administrativeLevel2,
          administrativeLevel1: instance?.administrativeLevel1,
          country: instance?.country,
          countryCode: instance?.countryCode
        });
      }
    }
    setLoading(false);
  }, [form]);

  const onFinish = async (values) => {
    let { period, notes } = values;
    let startDate = period && period.length > 1 ? period[0].toISOString() : null;
    let endDate = period && period.length > 1 ? period[1].toISOString() : null;
    let tmpSession = {...session};
    if (startDate) {
      tmpSession.startDate = startDate;
    }
    if (endDate) {
      tmpSession.endDate = endDate;
    }
    tmpSession.notes = notes;
    Object.assign(tmpSession, geolocation);
    if (production?.id) {
      tmpSession.production = production;
    }
    // validation
    if (!tmpSession.startDate || !tmpSession.endDate) {
      message.error('Merci de renseigner les dates (prévisionnelles ou confirmées)');
    }
    if (!tmpSession.user) {
      tmpSession.user = [user.id];
    }
    let savedSession = await saveSession(tmpSession);
    if (savedSession?.id) {
      setSession(savedSession);
      message.success('La session a bien été enregistrée');
    } else {
      message.error('Une erreur s\'est produite lors de l\'enregistrement');
    }
    if (props.onSave && typeof(props.onSave) == "function") {
      props.onSave(savedSession);
    }
    setEdit(false);
  };

  const onCancel = () => {
    setEdit(false);
    if (props.onCancel && typeof(props.onCancel) == "function") {
      props.onCancel();
    }
  };

  const onSessionSkillsCancel = async () => {
    setAddSessionSkills(false);
  };

  const onSessionSkillsSave = async (sessionSkills) => {
    setAddSessionSkills(false);
    /*
    if (sessionSkills?.session) {
      setSession(null);
      setSession(sessionSkills.session);
    }
    */
  };

  const onSessionSkillsDelete = async (sessionSkills) => {
    if (sessionSkills?.session) {
      setLoading(true);
      setSession(null);
      setSession(sessionSkills?.session);
      setLoading(false);
    }
  };

  const success = (operators) => {
    message.success(operators.length + ' opérateurs trouvés !');
  };

  const onAutocompleteLoad = autocomplete => {
    setAutocomplete(autocomplete);
  };

  const onAutocompletePlaceChanged = () => {
    let place = autocomplete?.getPlace();
    if (place?.formatted_address || place?.geometry) {
      let geolocation = {
        location: place.formatted_address || null,
        lat: place.geometry?.location?.lat() || null,
        lng: place.geometry?.location?.lng() || null
      };
      if (Array.isArray(place.address_components)) {
        place.address_components.forEach(component => {
          if (-1 !== component.types.indexOf('street_number')) {
            geolocation.streetNumber = component.long_name;
          } else if (-1 !== component.types.indexOf('route')) {
            geolocation.route = component.long_name;
          } else if (-1 !== component.types.indexOf('postal_code')) {
            geolocation.postalCode = component.long_name;
          } else if (-1 !== component.types.indexOf('locality')) {
            geolocation.locality = component.long_name;
          } else if (-1 !== component.types.indexOf('administrative_area_level_2')) {
            geolocation.administrativeLevel2 = component.long_name;
          } else if (-1 !== component.types.indexOf('administrative_area_level_1')) {
            geolocation.administrativeLevel1 = component.long_name;
          } else if (-1 !== component.types.indexOf('country')) {
            geolocation.country = component.long_name;
            geolocation.countryCode = component.short_name;
          }
        });
      }
      setGeolocation(geolocation);
    }
  };

  if (!loading) {
    /*
    if (form && session?.id) {
      form.setFieldsValue({
        period: [session?.startDate ? moment(session.startDate) : null, session?.endDate ? moment(session.endDate) : null],
        notes: session?.notes
      });
    }
    */

    if (!session?.id && !edit) {
      return <></>;
    }

    return (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Card>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {edit && <Form
              form={form}
              initialValues={{
                period: [session?.startDate ? moment(session.startDate) : null, session?.endDate ? moment(session.endDate) : null],
                notes: session?.notes }}
              layout="vertical"
              name="session"
              requiredMark={true}
              onFinish={onFinish}
            >
            <LoadScript
              id={"google-loadscript-session"+session?.id}
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              libraries={["places, drawing"]}
            >
            <Row gutter={[16, 16]} justify="center" align="stretch">
              <Col span={12}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Form.Item
                    label="Période"
                    name="period"
                    rules={[
                      {
                        required: true,
                        message: 'Merci de renseigner les dates (prévisionnelles ou confirmées)',
                      }
                    ]}
                    style={{ width: "100%", marginBottom: "12px" }}
                  >
                    <RangePicker style={{ width: "100%" }} format="DD/MM/YY" />
                  </Form.Item>
                  <Form.Item
                    label="Notes / Complément d'informations"
                    name="notes"
                    style={{ width: "100%" }}
                  >
                    <TextArea style={{ width: "100%" }} maxLength={255} autoSize={{ minRows: 7, maxRows: 7 }} />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Localisation"
                  name="location"
                  style={{ marginBottom: '0' }}
                >
                  <Autocomplete
                    onLoad={onAutocompleteLoad}
                    onPlaceChanged={onAutocompletePlaceChanged}
                    restrictions={{ country: "fr" }}
                    fields={['geometry.location', 'address_components', 'formatted_address', 'name']}
                  >
                    <input
                      id="autocomplete-input"
                      type="text"
                      placeholder="Enter your address"
                      className="ant-input"
                      defaultValue={geolocation?.location || ''} />
                  </Autocomplete>
                </Form.Item>
                <GoogleMap
                  center={{ lat: geolocation?.lat || 46.232193, lng:  geolocation?.lng || 2.209667 }}
                  clickableIcons={false}
                  extraMapTypes={[]}
                  zoom={5}
                  id="map"
                  mapContainerStyle={{ width:"100%", minHeight:"30vh" }}
                >
                  <Marker position={{ lat: geolocation?.lat || 43.300000, lng: geolocation?.lng || 5.400000}} />
                </GoogleMap>
              </Col>
            </Row>
            </LoadScript>
            <Row justify="end">
              <Space size="middle" style={{ justifyContent: 'flex-end' }}>
                <Form.Item style={{marginBottom: "0"}}>
                  <Button htmlType="button" onClick={() => onCancel()}>Annuler</Button>
                </Form.Item>
                <Form.Item style={{marginBottom: "0"}}>
                  <Button type="primary" htmlType="submit" style={{minWidth: "10vw"}}>Enregistrer</Button>
                </Form.Item>
              </Space>
            </Row>
            </Form>}
            {!edit && <Row>
              <Col span={16}>
                {session?.name || 'Session'}
                {session?.startDate && session?.endDate &&
                " du " + moment(session.startDate).format('LL') + " au " +  moment(session.endDate).format('LL')}
                {session?.locality?.trim() ? " à " + session.locality : ""}
              </Col>
              <Col span={8} align="right">
                <Button type="primary" htmlType="button" onClick={() => setEdit(true)}><EditOutlined/>Modifier</Button>
              </Col>
            </Row>
            }
            {!edit && session?.id && <Row gutter={[16, 16]} justify="center" align="stretch">
              <Col span={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {session?.sessionsSkills?.map(sessionSkills =>
                      <SessionSkills key={"sessions-skills-"+sessionSkills?.id}
                                     instance={sessionSkills}
                                     onCancel={onSessionSkillsCancel}
                                     onSave={onSessionSkillsSave}
                                     onDelete={onSessionSkillsDelete} />
                    )}
                  {!addSessionSkills && !session?.sessionsSkills?.length &&
                  <Empty description="Aucun profil renseigné pour cette session" style={{ margin: 'auto' }}>
                    <Button disabled={session?.id == null} type="primary" htmlType="submit" onClick={() => setAddSessionSkills(true)}>
                      Ajouter un profil
                    </Button>
                  </Empty>}
                  {session?.sessionsSkills?.length > 0 &&
                  <Button disabled={session?.id == null} type="primary" htmlType="submit"
                          onClick={() => setAddSessionSkills(true)}>
                    Ajouter un profil
                  </Button>}
                  {addSessionSkills &&
                  <SessionSkills session={session} onCancel={onSessionSkillsCancel} onSave={onSessionSkillsSave} />}
                </Space>
              </Col>
            </Row>}
            </Space>
          </Card>
        </Space>
      </>
    )
  }
  return <Loader/>
}

export default Session;
