import React from 'react';
import {Badge, Card, Col, Divider, Layout, Row, Space, Tabs, Tag, Typography} from "antd";
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLink, faLocation} from "@fortawesome/pro-regular-svg-icons";
import {faMobileAndroidAlt} from "@fortawesome/pro-solid-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {EnvironmentOutlined, LinkedinOutlined, LinkOutlined} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import AvaibilityTag from "../Tag/AvaibilityTag";
import displayAvatar from "../../helpers/displayAvatar";

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const Container = styled(Layout)`
  padding: 0;
  .ant-ribbon-wrapper {
    display: flex;
    flex: 1;
    .ant-card {
      flex: 1;
    }
  }
`;

const Resume = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShortDivider = styled(Divider)`
  width: 30%;
  min-width: 30%;
  margin: 20px 0;
`;

const ResumeInfosSection = styled.div`
  margin: .5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  &.horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const ResumeInfosHorizontal = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-around;
`;

const ResumeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const ResumeLink = styled.a`
  transition: .3s opacity;
  color: #343e46;
  span {
    font-size: .75rem;
    background-color: #ffefe9;
    padding: .3rem .7rem .3rem .6rem;
    text-transform: uppercase;
    margin-right: .3rem;
    border-radius: 3px;
    svg {
      width: .6rem;
      margin-right: .3rem;
    }
  }
  &:hover {
    opacity: .8;
  }
  &.icon-only {
    svg {
      width: 1.4rem;
    }
  }
`;

const Description = styled.div`
  padding: 0;
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  > * {
    margin: 0.2rem;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  width:100%;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const OperatorProfile = ({operator, size = 'full', style = {}, searchMatch = null, ...props}) => {
  //const { skills, getSkills, error:errorSkills, loading:loadingSkills } = useSkill();

  let avaibility = true === operator.available;
  let fromDate = operator.availableFromDate ? new Date(operator.availableFromDate) : null;
  let level = "Expert";
  let distance = null;

  if (searchMatch) {
    if (searchMatch.distance) {
      if (searchMatch.distance < 50) {
        distance = "(moins de 50km)"
      } else if (searchMatch.distance < 100) {
        distance = "(moins de 100km)"
      } else if (searchMatch.distance < 200) {
        distance = "(moins de 200km)"
      } else if (searchMatch.distance < 400) {
        distance = "(moins de 400km)"
      }
    }
    if (Array.isArray(operator.skills)) {
      operator.skills.forEach(operatorSkill => {
        if (searchMatch.skill?.id === operatorSkill.skill?.id) {
          level = operatorSkill.level.name;
        }
      });
    }
  }

  if (operator.jobs) {
    /*
    let operatorJobsList = operator.jobs.map((skillId) => {
      const operatorJobs = skills.filter((skill) => skill.id === skillId);
      const { startDate, endDate } = operatorJobs[0] || {};
      let slot = { startDate: startDate, endDate: endDate };
      return slot;
    });
    if (true === avaibility) {
      avaibility = checkAvaibility(operatorJobsList);
    }
     */
  }

  const getWebsiteLink = (url: String, options = {}) => {
    let domain = (new URL(url)).hostname.match(/(\w+\.?){2}$/g)[0];
    let icon = null;
    let text = null;
    options = Object.assign({ resume: true, icon: true }, options);
    switch (domain) {
      case 'linkedin.com':
        icon = faLinkedin;
        text = 'Linkedin';
        break;
      default:
        icon = faLink;
        text = 'Site web';
        break;
    }
    let content = <span>{options?.icon && <FontAwesomeIcon icon={icon} size="1x"/>} {text}</span>;
    if (true === options?.resume) {
      return <ResumeLink target="_blank" rel="nofollow noreferrer noopener" href={url}>{ content }</ResumeLink>;
    }
    return <a target="_blank" rel="nofollow noreferrer noopener" href={url}>{ content }</a>;
  };

  return (<Container style={style}>
    <Badge.Ribbon text={level} placement="start" width={style?.width || 'auto'}>
      <Card bordered={false}>
        {'small' === size &&
        <>
        <Card.Meta
          avatar={displayAvatar(100, operator.image, operator.firstName, operator.lastName)}
          title={operator.fullName}
          description={
            <>
              <p>{operator.jobTitle}</p>
              <ul style={{ listStyleType:'none', padding: 0 }}>
                { operator.website &&
                <li><LinkOutlined />
                  &nbsp;{ getWebsiteLink(operator.website, {icon: false, resume: false}) }
                </li>}
                { operator.socialWebsite &&
                <li><LinkedinOutlined />
                  &nbsp;{ getWebsiteLink(operator.socialWebsite, {icon: false, resume: false}) }
                </li>}
              </ul>
            </>
          }
        />
        <Space direction="vertical">
          <p>
            <EnvironmentOutlined /> &nbsp;{operator.postalCode} {operator.locality}&nbsp;&nbsp;
            <small>{distance}</small>
          </p>
          <SkillsContainer>
            {operator.skills && operator.skills.slice(0,4).map((operatorSkill, index) =>
              <Tag key={index}>{operatorSkill?.skill?.name?.toUpperCase()}</Tag>)}
          </SkillsContainer>
        </Space>
        </>}
        {'small' !== size &&
        <Row gutter={32}>
          <Col xs={24} lg={'full' === size ? 8 : 24}>
            <Resume>
              { displayAvatar('full' === size ? 150 : 100, operator.image, operator.firstName, operator.lastName) }
              <ShortDivider/>
              <Title level={4} className="mb-0">{operator.fullName}</Title>
              <Title level={4} className="mt-0 h5-like" type="secondary">{operator.jobTitle}</Title>
              <AvaibilityTag available={avaibility} className="my-1"/>
              <span>
            { false === avaibility && fromDate ? 'Disponible à partir du ' + fromDate.toLocaleDateString() : '' }
            </span>
              <ShortDivider/>
              <ResumeInfosHorizontal>
                <ResumeInfo>
                  <Text className="mt-0" >Localisation</Text>
                  <Text className="mt-0" ><span className="text-strong">{operator.postalCode} {operator.locality}</span>{ operator.radius > 0 &&
                  <><FontAwesomeIcon icon={faLocation} size="1x"/> {operator.radius} kms</>
                  }</Text>
                </ResumeInfo>
              </ResumeInfosHorizontal>
              <ShortDivider/>
              <ResumeInfosHorizontal>
                <ResumeInfo className="horizontal">
                  {
                    operator.phone &&
                    <ResumeLink href={`tel:${operator.phone}`} target="_blank" rel="nofollow noreferrer noopener" className="mx-1 icon-only"><FontAwesomeIcon icon={faMobileAndroidAlt} style={{fontSize: "1.3rem"}}/></ResumeLink>
                  }
                  {
                    operator.email &&
                    <ResumeLink href={`mailto:${operator.email}`} className="mx-1 icon-only"><FontAwesomeIcon icon={faEnvelope} size="2x"/></ResumeLink>
                  }
                </ResumeInfo>
              </ResumeInfosHorizontal>
              <ShortDivider/>
              <ResumeInfosSection className="horizontal">
                {
                  operator.website && getWebsiteLink(operator.website)
                }
                {
                  operator.socialWebsite && getWebsiteLink(operator.socialWebsite)
                }
              </ResumeInfosSection>
            </Resume>
          </Col>
          { 'full' === size &&
          <Col xs={24} lg={16}>
            <Description>
              <Title level={3}>Présentation</Title>
              <Paragraph className="mb-2">{ operator.blurb }</Paragraph>
              <Tabs defaultActiveKey="1" size="large">
                <TabPane tab="Expériences" key="1">
                  <ReactMarkdown source={ operator.resume }
                                 renderers={{link: props => <a href={props.href} target="_blank" rel="nofollow noreferrer noopener">{props.children}</a>}}/>
                  <Divider/>
                  <Space>
                    {operator.skills && operator.skills.map((operatorSkill, index) =>
                      <Tag key={index}>{operatorSkill?.skill?.name?.toUpperCase()}</Tag>)}
                  </Space>
                </TabPane>
                { operator.showreel &&
                <TabPane tab="Showreel" key="2">
                  <PlayerWrapper>
                    <ReactPlayer url={operator.showreel} className='react-player' controls={true}  width='100%' height='100%' />
                  </PlayerWrapper>
                </TabPane>
                }
              </Tabs>
            </Description>
          </Col> }
        </Row>}
      </Card>
    </Badge.Ribbon>
    </Container>);
}

export default OperatorProfile;
