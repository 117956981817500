import React, { useEffect, useState } from 'react';
import { Badge, Card, Checkbox, Form, Space, Button, Row, Col, Input, InputNumber, message, Popconfirm, Select } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined}  from "@ant-design/icons";
import Loader from '../../components/Loader';
import {useSessionSkills} from "../../services/SessionSkillsService";
import {useSkill} from "../../services/SkillService";

const { Option } = Select;
const { TextArea } = Input;


const SessionSkills = ({ instance, session, ...props }) => {

  const [form] = Form.useForm();

  // States
  const [sessionSkills, setSessionSkills] = useState({
    id: null,
    session: [],
    skills: [],
    count: 0,
    notes: null,
    search: false,
    operatorSearch: null
  });
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(!instance?.id);
  const {saveSessionSkills, deleteSessionSkills} = useSessionSkills();
  const { skills, getSkills, loading:loadingSkills } = useSkill();

  useEffect(() => {
    getSkills(null);
    if (instance) {
      if (session && session.id && session.id !== instance?.session?.id) {
        message.error("Invalid session given");
      } else {
        setSessionSkills(instance);
        setEdit(false);
      }
    }
    setLoading(false);
  }, [form]);

  const onFinish = async (values) => {
    let { skills, count, notes, search } = values;
    let tmpSessionSkills = {...sessionSkills};
    tmpSessionSkills.count = count;
    tmpSessionSkills.notes = notes;
    tmpSessionSkills.search = search;
    if (Array.isArray(skills) && skills.length) {
      tmpSessionSkills.skills = skills;
    }
    if (session?.id) {
      tmpSessionSkills.session = session;
    }
    if (!tmpSessionSkills.user && tmpSessionSkills.session?.user) {
      tmpSessionSkills.user = tmpSessionSkills.session.user;
    }
    // validation
    if (!Array.isArray(tmpSessionSkills.skills) || 0 === tmpSessionSkills.skills.length) {
      message.error('Merci de renseigner un poste');
    }
    if (!tmpSessionSkills.count) {
      message.error('Merci de renseigner le nombre d\'opérateur par profil.');
    }
    let savedSessionSkills = await saveSessionSkills(tmpSessionSkills);
    if (savedSessionSkills?.id) {
      setSessionSkills(savedSessionSkills);
      setEdit(false);
      message.success('La session a bien été enregistrée');
    } else {
      message.error('Une erreur s\'est produite lors de l\'enregistrement');
    }
    if (props.onSave && typeof(props.onSave) == "function") {
      props.onSave(savedSessionSkills);
    }
  };

  const onCancel = () => {
    setEdit(false);
    if (props.onCancel && typeof(props.onCancel) == "function") {
      props.onCancel();
    }
  };

  const onDelete = async () => {
    let deletedSessionSkills = await deleteSessionSkills(sessionSkills);
    if (true === deletedSessionSkills) {
      setEdit(false);
      if (props.onDelete && typeof(props.onDelete) == "function") {
        props.onDelete(sessionSkills);
      }
    }
  };

  if (!loading && !loadingSkills && skills.length > 0) {
    /*
    if (form && sessionSkills) {
      form.setFieldsValue({
        skills: Array.isArray(sessionSkills.skills) ? sessionSkills.skills.map(skill => skill.name) : [],
        count: sessionSkills.count,
        notes: sessionSkills.notes,
        search: !!sessionSkills.search,
      });
    }
    */

    if (!sessionSkills?.id && !edit) {
      return <></>;
    }

    return (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Card>
            {edit && <Form
              form={form}
              initialValues={{
                skills: Array.isArray(sessionSkills.skills) ? sessionSkills.skills.map(skill => skill.name) : [],
                count: sessionSkills.count,
                notes: sessionSkills.notes,
                search: !!sessionSkills.search }}
              layout="vertical"
              name="session"
              requiredMark={true}
              onFinish={onFinish}
            >
              <Row justify="center" align="stretch">
                <Col span={24}>
                  <Form.Item
                    label="Poste"
                    name="skills"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Merci de renseigner un poste',
                      }
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Sélectionnez un poste"
                    >
                      {skills.map((item) => (
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Nombre (pour chaque profil)"
                    name="count"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Merci de renseigner le nombre de personnes',
                      }
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="search"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={sessionSkills.search}>
                      Rechercher des opérateurs
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Notes / Complément d'informations"
                    name="notes"
                    style={{ width: "100%" }}
                  >
                    <TextArea style={{ width: "100%" }} maxLength={255} autoSize={{ minRows: 8, maxRows: 8 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Space size="middle" style={{ justifyContent: 'flex-end' }}>
                  <Form.Item style={{marginBottom: "0"}}>
                    <Button htmlType="button" onClick={() => onCancel()}>Annuler</Button>
                  </Form.Item>
                  {sessionSkills.id &&
                  <Form.Item style={{marginBottom: "0"}}>
                    <Popconfirm
                      title="Etes-vous sûr(e) ?"
                      onConfirm={onDelete}
                      okText="Oui"
                      cancelText="Non"
                      placement="bottom"
                    >
                      <Button htmlType="button" danger>
                        <Space>
                          <DeleteOutlined />
                          Supprimer
                        </Space>
                      </Button>
                    </Popconfirm>
                  </Form.Item>}
                  <Form.Item style={{marginBottom: "0"}}>
                    <Button type="primary" htmlType="submit" style={{minWidth: "10vw"}}>
                      <Space>
                        <SaveOutlined />
                        Enregistrer
                      </Space>
                    </Button>
                  </Form.Item>
                </Space>
              </Row>
            </Form>}
            {!edit && <Row>
              <Col span={16}>
                {sessionSkills?.skills?.map(skill => skill.name).join(', ')}&nbsp;
                <Badge count={sessionSkills?.count}/>
              </Col>
              <Col span={8} align="right">
                <Button type="link" htmlType="button" onClick={() => setEdit(true)}><EditOutlined/></Button>
              </Col>
            </Row>
            }
          </Card>
        </Space>
      </>
    )
  }
  return <Loader/>
}

export default SessionSkills;
