const config = {
  apiURL: process.env.REACT_APP_API_URL,
  apiToken: process.env.REACT_APP_API_TOKEN,
  appName: process.env.REACT_APP_APP_NAME,
  entities: {
    job: {
      path: "jobs"
    },
    notification: {
      path: "notifications"
    },
    operator: {
      path: "operators"
    },
    operatorSearch: {
      path: "operatorSearch"
    },
    operatorSearchMatch: {
      path: "operatorSearchMatch"
    },
    operatorSearchMatchStatus: {
      path: "operatorSearchMatchStatus"
    },
    operatorSearchStatus: {
      path: "operatorSearchStatus"
    },
    operatorSkill: {
      path: "operatorSkills"
    },
    production: {
      path: "productions"
    },
    productionCompany: {
      path: "productionCompanies"
    },
    productionStage: {
      path: "productionStages"
    },
    productionStatus: {
      path: "productionStatus"
    },
    productionType: {
      path: "productionTypes"
    },
    session: {
      path: "sessions"
    },
    sessionSkills: {
      path: "sessionSkills"
    },
    skill: {
      path: "skills"
    },
    skillLevel: {
      path: "skillLevels"
    },
    user: {
      path: "users"
    },
    userRequest: {
      path: "userRequests"
    }
  },
  view: "Base",
  maxRecords: "1000",
  pageSize: "100",
};

export default config;