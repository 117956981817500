import React from 'react';
import { useHistory } from 'react-router-dom';
import Error403 from "../../routes/Errors/Error403";
import Error404 from "../../routes/Errors/Error404";
import ErrorDefault from "../../routes/Errors/ErrorDefault";

// A context to allow components lower down the tree to trigger the display of an error page
const ErrorStatusContext = React.createContext();

// The top level component that will wrap our app's core features
const ErrorHandler = ({ children }) => {
    const history = useHistory();
    const [errorStatusCode, setErrorStatusCode ] = React.useState(null);
    const [errorMessage, setErrorMessage ] = React.useState(null);

    // Reset/clear status code whenever the user navigates to a new URL.
    // If we didn't do that, then the user would be "trapped" into error pages forever
    React.useEffect(() => {
        // Listen for changes to the current location.
        const unlisten = history.listen(() => { setErrorStatusCode(null); setErrorMessage(null) });
        // cleanup the listener on unmount
        return unlisten;
    }, [])

    // If errorStatusCode is set, render an error page, render the children as normal otherwise
    const renderContent = () => {
        if (errorStatusCode === 403) {
            return <Error403 />
        }
        if (errorStatusCode === 404) {
            return <Error404 />
        }
        if (errorStatusCode) {
            return <ErrorDefault message={errorMessage} />
        }
        return children;
    }

    // We wrap it in a useMemo for performance reasons. More here:
    // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
    const contextPayload = React.useMemo(
    () => ({ setErrorStatusCode, setErrorMessage }),
    [setErrorStatusCode, setErrorMessage]
    );

    // We expose the context's value down to our components, while
    // also making sure to render the proper content to the screen
    return (
      <ErrorStatusContext.Provider value={contextPayload}>
        {renderContent()}
      </ErrorStatusContext.Provider>
    )
}

// A custom hook to quickly read the context's value. It's only here to allow quick imports
export const useErrorStatus = () => React.useContext(ErrorStatusContext);

export default ErrorHandler;