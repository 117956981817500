import React, { useReducer, createContext } from 'react';
import useLocalStorage, { clearStorageValue, getStorageValue } from "../hooks/useLocalStorage";
import { authenticateUserToken, authenticateUserPassword, requestUserMagicLink } from "../services/UserService";
import { getCompany } from "../services/CompanyService";

// Global App Context
export const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'vibrant'
});


// Auth|User Context, Provider and Reducer
const authLocalStorageKey = 'auth';
const companyLocalStorageKey = 'company';
const userLocalStorageKey = 'user';
const recaptchaLocalStorageKey = 'grecaptcha_token';

export const getUser = () => {
  return getStorageValue(userLocalStorageKey);
};

export const getRecaptchaToken = () => {
  return getStorageValue(recaptchaLocalStorageKey);
};
export const clearRecaptchaToken = () => {
  clearStorageValue(recaptchaLocalStorageKey);
};

export const AuthReducer = (initialState, action) => {
  let state = null;

  switch (action.type) {
    case "loginRequest":
      state = {...initialState, loading: true, errorMessage: null};
      break;
    case "loginSuccess":
      state = {
        ...initialState,
        auth: {...initialState.auth || {}, valid:true, token: action.auth_token},
        company: action.company,
        user: action.user,
        loading: false
      };
      break;
    case "loginError":
      state = {...initialState, loading: false, errorMessage: action.error};
      break;
    case "logout":
      state = {...initialState, auth: {...initialState.auth || {}, valid:false, token: ''}};
      break;
    case "magicLinkRequest":
      state = {...initialState, loading: true, errorMessage: null};
      break;
    case "magicLinkSuccess":
      state = {...initialState, loading: false, auth: {...initialState.auth || {}, code: action.code}};
      break;
    case "magicLinkError":
      state = {...initialState, loading: false, errorMessage: action.error};
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }

  // save into local storage
  if (state instanceof Object) {
    if (state.auth) {
      localStorage.setItem(authLocalStorageKey, JSON.stringify(state.auth));
    }
    if (state.user) {
      localStorage.setItem(userLocalStorageKey, JSON.stringify(state.user));
    }
    if (state.company) {
      localStorage.setItem(companyLocalStorageKey, JSON.stringify(state.company));
    }
  }

  return state || initialState;
};

export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  let lsAuth = null;
  let lsCompany = null;
  let lsUser = null;
  try {
    lsAuth = JSON.parse(localStorage.getItem(authLocalStorageKey) ?? '{}');
    lsCompany = JSON.parse(localStorage.getItem(companyLocalStorageKey) ?? 'null');
    lsUser = JSON.parse(localStorage.getItem(userLocalStorageKey) ?? 'null');
  } catch (e) {
    // default empty values;
    lsAuth = {};
    lsCompany = null;
    lsUser = null;
    // clear local storage
    localStorage.removeItem(authLocalStorageKey);
    localStorage.removeItem(companyLocalStorageKey);
    localStorage.removeItem(userLocalStorageKey);
  }

  const [state, dispatch] = useReducer(AuthReducer, Object.freeze({
    auth: Object.assign({
      code: null,
      token: null,
      valid: false
    }, lsAuth),
    company: lsCompany,
    user: lsUser,
    loading: false,
    errorMessage: null
  }));
  const {auth, company, user, loading, errorMessage} = state;
  const [recaptchaToken, setRecaptchaToken] = useLocalStorage(recaptchaLocalStorageKey, null);

  const login = async (email, password) => {
    try {
      dispatch({ type: 'loginRequest' });
      let user = await authenticateUserPassword(email, password);
      if ((user instanceof Object) && user.id && user.fullName) {
        let companyId = Array.isArray(user?.company) && user.company.length > 0
            ? user.company[0]
            : null;
        let company = companyId ? await getCompany(companyId) : null;
        dispatch({ type: 'loginSuccess', user: user, company: company, token: null });
        return true;
      }
      dispatch({ type: 'loginError', error: 'Authentication failed !' });
    } catch (error) {
      dispatch({ type: 'loginError', error: error });
    }
    return false;
  };

  const logout = async () => {
    dispatch({ type: 'logout' });
    return true;
  };

  const requestMagicLink = async (email) => {
    try {
      dispatch({ type: 'magicLinkRequest' });
      const code = [...crypto.getRandomValues(new Uint8Array(32))].map(m => m.toString(16)).join('');
      let state = await requestUserMagicLink(email, code);
      if (state) {
        dispatch({ type: 'magicLinkSuccess', code: code });
        return true;
      }
    } catch (error) {
      dispatch({ type: 'magicLinkError', error: error });
      return false;
    }
    // @todo : restore error message in english & add translation service
    dispatch({
      type: 'magicLinkError',
      //error: 'Unable to send magic link. Please check the given address is a valid user account or contact the support.'
      error: 'Nous n\'avons pas trouvé de compte. Veuillez vérifier l\'adresse email et contacter le support le cas échéant.'
    });
    return false;
  };

  const verifyToken = async (token) => {
    try {
      let user = await authenticateUserToken(token, auth?.email, auth?.code);
      if ((user instanceof Object) && user.id && user.fullName) {
        let companyId = Array.isArray(user?.company) && user.company.length > 0
            ? user.company[0]
            : null;
        let company = companyId ? await getCompany(companyId) : null;
        dispatch({ type: 'loginSuccess', user: user, company: company, token: null });
        return true;
      }
      dispatch({ type: 'loginError', error: 'Authentication failed !' });
    } catch (error) {
      dispatch({ type: 'loginError', error: error });
    }
    return false;
  };

  return (
    <UserContext.Provider value={{ auth, company, user, loading, errorMessage, recaptchaToken,
        login, logout, requestMagicLink, verifyToken, setRecaptchaToken }}>
      {children}
    </UserContext.Provider>
  );
}

export default AppContext;
