import React, { useContext } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { routes, routeComponents } from '../routes';
import { Layout, Space } from 'antd';
import styled from 'styled-components';
import LoginForm from "../components/LoginForm";
import Logo from '../components/Logo';
import { UserContext } from "../context/Context";
import { getRoute } from "../helpers/getPageName";

const { Content } = Layout;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AuthLayout = () => {
  const { auth } = useContext(UserContext);
  const location = useLocation();
  const route = getRoute(location);

  return (
    <>
      <Layout>
        <StyledContent>
          <Space direction="vertical" size="large" align="center">
            <Logo/>
            { false !== route?.authenticated && false === auth?.valid && <LoginForm/> }
            { false === route?.authenticated &&
              <Switch>
                { routeComponents }
              </Switch> }
          </Space>
        </StyledContent>
      </Layout>
    </>
  );
};

export default AuthLayout;
