import React from 'react'

const MediaImg = ({src, url, alt, className}) => {
    return (
        <div className={className}>
            {src && url ? (
                <a href={url}>
                    <img src={src} alt={alt}/>
                </a>
            ) : (
                <img src={src} alt={alt}/>
            )}
        </div>
    )
}

export default MediaImg
