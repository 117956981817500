import { useState, useEffect } from "react";
import { getProduction } from './ProductionService';
import { getSessionsSkills } from "./SessionSkillsService";
import { getRepository } from "./RepositoryService";

const repository = getRepository('session');

async function loadSessions(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for session!');
	}
	let sessions = null === ids ? await repository.getAll(true) : await repository.get(ids);
	if (false === lazy) {
		sessions = await Promise.all(sessions.map(async (record) => {
			// production
			if (Array.isArray(record.production) && record.production.length > 0 && (typeof record.production[0] === 'string')) {
				//@todo check to fix infinite loop / circular reference when loading lazy=false
				record.production = await getProduction(record.production[0], true);
			}
			// sessionsSkills
			if (record?.sessionsSkills?.length > 0 && (typeof record?.sessionsSkills[0] === 'string')) {
				//record.sessionsSkills = record.sessionsSkills.filter(sessionSkills => sessionSkills?.id)
				//	.concat(await getSessionsSkills(record.sessionsSkills.filter(sessionSkills => !sessionSkills?.id), false));
				record.sessionsSkills = await getSessionsSkills(record.sessionsSkills, false);
			}
			return record;
		}));
	}
	return sessions;
}

export const getSessions = async (ids: Array, lazy: boolean = true) => {
	return await loadSessions(ids ?? null, lazy);
};

export const getSession = async function (id, lazy: boolean = true) {
	await getSessions([id], lazy);
	return repository.get(id);
};


const listeners = new Set();

export const useSession = () => {
	const [sessions, setSessions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getSessions = async function (ids: Array, lazy: boolean = true) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let sessions = await loadSessions(ids ?? null, lazy);
		setLoading(false);
		listeners.forEach(listener => listener());
		return sessions;
	};

	const getSession = async function (id, lazy: boolean = true) {
		await getSessions([id], lazy);
		return repository.get(id);
	};

	const saveSession = async (data) => {
		let {id, ...fields} = data;
		let ignoreFields = ['fullName', 'sessionsSkills', 'jobs'];
		ignoreFields.forEach(field => {
			if (field in fields) {
				delete fields[field];
			}
		});
		// cast production
		if (fields?.production?.id) {
			fields.production = fields.production.id;
		} else if (typeof fields?.production !== 'string') {
			delete fields['production'];
		}
		let session = null;
		if (id) {
			let resp = await repository.patch(id, fields);
			if (resp.status !== 200) {
				throw new Error("Got an error while updating Session");
			}
			// remove instance from cache
			if (repository.has(id)) {
				repository.remove(id);
			}
			// reload instance
			session = await getSession(id, false);
		} else {
			// validate creation
			if (!fields.production) {
				throw new Error("Production must be defined !");
			}
			let resp = await repository.post(fields);
			if (resp.status !== 200 || !resp.data.id) {
				throw new Error("Got an error while creating new Session");
			}
			if(resp.data.id) {
				// load instance
				session = await getSession(resp.data.id, false);
				// add new instance to production sessions
				if (data.production) {
					if (!('sessions' in data.production) || null === data.production.sessions) {
						data.production.sessions = [];
					}
					if (Array.isArray(data.production.sessions)) {
						data.production.sessions.push(session);
					}
				}
			}
		}
		return session;
	};

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(sessions => {
				setSessions(sessions);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, sessions, getSessions, getSession, saveSession };
};

export default useSession;