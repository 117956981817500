import React from 'react';
import Avatar from '../components/Avatar';

const displayAvatar = (size, avatar, firstName, lastName, style) => {
  let initials = "";
  if (firstName && lastName) { initials = `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}
  if (Array.isArray(avatar)) {
    avatar = avatar.length > 0 && avatar[0].url ? avatar[0].url : '';
  } else if (typeof avatar != "string") {
    avatar = null;
  }
  if (avatar) {
    return <Avatar src={avatar} size={size} style={style}/>
  }
  return <Avatar size={size} initials={initials} style={style}/>
};

export default displayAvatar;