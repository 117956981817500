import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import { Row } from 'antd';
import BackButton from '../../components/BackButton';
import Loader from '../../components/Loader';
import OperatorProfile from "../../components/Operator/Profile";
import { getOperator } from '../../services/OperatorService';
import { useSkill } from '../../services/SkillService';

const ViewOperator = ({ match }) => {

  const [operator, setOperator] = useState(null);
  const [redirect, setRedirect] = useState(false);

  // Onload
  useEffect(() => {
    // load operators
    if (match.params.id) {
      getOperator(match.params.id, false).then(operator => {
        if (operator) {
          setOperator(operator)
        } else {
          setRedirect(true);
        }
      });
    } else {
      setRedirect(true);
    }
  }, []);

  // Queries
  const { skills } = useSkill();

  if (redirect) {
    return (
      <Redirect to='/operators'/>
    )
  }

  if (operator && skills) {
    return (
      <>
        <OperatorProfile operator={operator} skills={skills} />
        <Row className="mt-2">
          <BackButton text="Retour"/>
        </Row>
      </>
    )
  }

  return <Loader/>
};

export default ViewOperator;