import React, { useEffect, useState } from 'react';
import { getNotifications, saveNotification } from '../services/NotificationService';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import Loader from '../components/Loader';
import moment from 'moment';
import { List, Typography, Card, Row, Col, Button, message } from 'antd';

const { Text, Title } = Typography;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 1rem;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 1rem;
    .ant-card-body {
      padding: .5rem;
    }
  }
`;

const NotificationsList = styled(List)`
  .ant-list-item-meta-title {
    font-weight: bold
  }
  .status-icon {
      max-width: .5rem;
      margin: 0 1.5rem 0 0;
    &.readed {
      color: #e9e9e9;
    }
    &.unread {
      color: ${props => props.theme.brandColor};
    }
  }

  .action-icon {
    max-width: .5rem;
    margin-right: .5rem;
  }

  .ant-list-item {
    padding: 1rem;
    .ant-list-item-action {
        opacity: 0;
        visibility: hidden;
        svg {
          font-size: 1.4rem;
        }
      }
    &:hover {
      background-color: #fdfbfa;
      cursor: pointer;
      .ant-list-item-action {
        opacity: .5;
        visibility: visible;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .ant-list-item-action {
      display: none!important;
    }
  }

`;

const Notifications = () => {

    const [notifications, setNotifications] = useState([]);

    // Onload
    useEffect(() => {
      // load notifications
      getNotifications().then(notifications => setNotifications(notifications));
    }, []);

    if (notifications) {

      const unreadList = notifications.filter(notification => !notification.read);

      const markAllAsRead = () => {
        let success = 0;
        unreadList.map( async (notification, index) => {
          index++;
          notification.read = true;
          try {
            let resp = await saveNotification(notification);
            if (resp.status === 200) success++
          } catch (error) {
            message.error("Une erreur est survenue, impossible d'effectuer l'action demandée");
          }
          if (index === unreadList.length ) {
            if (success === unreadList.length) {
              message.success('Tout est lu, vous êtes à jour !');
            }
          }
        });
        setNotifications([...notifications]);
      };

      const toggleRead = async (item) => {
        item.read = item.read !== true;
        try {
          await saveNotification(item);
          setNotifications([...notifications]);
        } catch (error) {
          message.error("Une erreur est survenue, impossible d'effectuer l'action demandée");
        }
      };

      const count = notifications.length;

      return (
        <>
        <Row justify="space-between">
          <Col>
          {
            unreadList.length > 0 && 
            <Title level={3}>{ unreadList.length } notification{unreadList.length > 1 ? 's' : ''} non-lue{unreadList.length > 1 ? 's' : ''}</Title>
          }
          {
            unreadList.length === 0 &&
            <Title level={3}>{ count } notification{count > 1 ? 's' : ''} lue{count > 1 ? 's' : ''}</Title>
          }
          </Col>
          {
            unreadList.length > 0 &&
            <Col>
              <Button 
                type="primary" 
                onClick={() => markAllAsRead()}
              > Marquer tout lu
              </Button>
            </Col>
          }
        </Row>
        <StyledCard bordered={false}>
          <NotificationsList
            itemLayout="horizontal"
            dataSource={notifications}
            pagination={count > 8 ? {position: "bottom", pageSize: 8, size:"small"} : false}
            renderItem={item => (

              <List.Item
                onClick={() => toggleRead(item)}
                actions={ 
                  !item.read ? [
                    <Button title="Marquer comme lu" type="link"><FontAwesomeIcon icon={faCheckCircle}/></Button>
                  ] : [
                    <Button title="Marquer comme non lu" type="link"><FontAwesomeIcon icon={ faPlusCircle }/></Button>
                  ]
                  }>
                
                <>
                { !item.read ? <FontAwesomeIcon icon={ faCircle } className="status-icon unread"/> : <FontAwesomeIcon icon={ faCircle } className="status-icon readed"/>}
                </>

                <List.Item.Meta
                  title={<Text ellipsis={true}>{moment(item.createdAt).fromNow()}</Text>}
                  description={item.message}
                />

              </List.Item>

            )}
          />
        </StyledCard>
        </>
      )
    }

    return <Loader/>
}

export default Notifications;