import { useState, useEffect } from "react";
import { getStages } from "./ProductionStageService";
import { getRepository } from "./RepositoryService";

export const repository = getRepository('skill');

async function loadSkills(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for skill!');
	}
	let skills = null === ids ? await repository.getAll(true) : await repository.get(ids);
	if (false === lazy) {
		skills = await Promise.all(skills.map(async (record) => {
			// stages
			if (record?.stages?.length > 0 && (typeof record?.stages[0] === 'string')) {
				record.stages = await getStages(record.stages);
			}
			return record;
		}));
	}
	return skills;
}

export const getSkills = async (ids: Array, lazy: boolean = true) => {
	return await loadSkills(ids ?? null, lazy);
};

export const getSkill = async function (id, lazy: boolean = true) {
	await getSkills([id], lazy);
	return repository.get(id);
};


const listeners = new Set();

export const useSkill = () => {
	const [skills, setSkills] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getSkills = async function (ids: Array, lazy: boolean = true) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let skills = await loadSkills(ids ?? null, lazy);
		setLoading(false);
		listeners.forEach(listener => listener());
		return skills;
	};

	const getSkill = async function (id, lazy: boolean = true) {
		await getSkills([id], lazy);
		return repository.get(id);
	};

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(skills => {
				setSkills(skills);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, skills, getSkills, getSkill };
};

export default useSkill;