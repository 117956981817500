import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
    padding: .3rem .8rem;
    font-size: .9rem;
    font-weight: bold;
    border-width: 2px;
    margin: .8rem 0;
    &.green {
      color: #fff;
      border-color: ${props => props.theme.operatorAvaibility.available};
      background-color: ${props => props.theme.operatorAvaibility.available};
    }
    &.grey {
      color: #fff;
      border-color: ${props => props.theme.operatorAvaibility.unavailable};
      background-color: ${props => props.theme.operatorAvaibility.unavailable};
    }
`;

const AvaibilityTag = ({available}) => {
  let color, text = "";
  color = available === true ? "green" : "grey";
  text = available === true ? "Disponible" : "Indisponible";
  return (
    <>
      <StyledTag className={color}>{text}</StyledTag>
    </>
  )
}

export default AvaibilityTag
