import {useEffect, useState} from "react";
import { getRepository } from "./RepositoryService";
import { getOperator } from "./OperatorService";
import { getSkill } from "./SkillService";
import { getSkillLevel } from "./SkillLevelService";

const repository = getRepository('operatorSkill');

async function loadOperatorSkills(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for operatorSkill!');
	}
	let operatorSkills = null === ids ? await repository.getAll(true) : await repository.get(ids);
	if (false === lazy) {
		operatorSkills = await Promise.all(operatorSkills.map(async (record) => {
			// operator
			if (record?.operator?.length > 0 && (typeof record?.operator[0] === 'string')) {
				// @todo load operator using repository method to avoid infinite loop/loading
				/*
				let operator = await getOperator(record.operator[0]);
				if (operator && operator.id) {
					record.operator = operator;
				}
				*/
			}
			// skill
			if (record?.skill?.length > 0 && (typeof record?.skill[0] === 'string')) {
				let skill = await getSkill(record.skill[0]);
				if (skill && skill.id) {
					record.skill = skill;
				}
			}
			// level
			if (record?.level?.length > 0 && (typeof record?.level[0] === 'string')) {
				let level = await getSkillLevel(record.level[0]);
				if (level && level.id) {
					record.level = level;
				}
			}
			return record;
		}));
	}
	return operatorSkills;
}

export const getOperatorSkills = async (ids: Array, lazy: boolean = true) => {
	return await loadOperatorSkills(ids ?? null, lazy);
};

export const getOperatorSkill = async function (id, lazy: boolean = true) {
	await getOperatorSkills([id], lazy);
	return repository.get(id);
};


const listeners = new Set();

export const useOperatorSkill = () => {
	const [operatorSkills, setOperatorSkills] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getOperatorSkills = async function (ids: Array, lazy: boolean = true) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let operatorSkills = await loadOperatorSkills(ids ?? null, lazy);
		setLoading(false);
		listeners.forEach(listener => listener());
		return operatorSkills;
	};

	const getOperatorSkill = async function (id, lazy: boolean = true) {
		await getOperatorSkills([id], lazy);
		return repository.get(id);
	};

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(operatorSkills => {
				setOperatorSkills(operatorSkills);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, operatorSkills, getOperatorSkills, getOperatorSkill };
};

export default useOperatorSkill;