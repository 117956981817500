import { useState, useEffect } from "react";
import { getRepository } from "./RepositoryService";

const repository = getRepository('operatorSearchStatus');

export const statusCode = {
	PENDING: 'PENDING',
	PROCESSING: 'PROCESSING',
	SUCCEEDED: 'SUCCEEDED',
	FAILED: 'FAILED',
	SELECTION_PROCESSING: 'SELECTION-PROCESSING',
	SELECTION_DONE: 'SELECTION-DONE',
	CONFIRMATION_PROCESSING: 'CONFIRMATION-PROCESSING',
	CONFIRMATION_DONE: 'CONFIRMATION-DONE'
}

async function loadOperatorSearchStatuses(ids: Array = null) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for operatorSearchStatus!');
	}
	return null === ids ? await repository.getAll(true) : await repository.get(ids);
}

export const getOperatorSearchStatuses = async (ids: Array) => {
	return await loadOperatorSearchStatuses(ids ?? null);
};

export const getOperatorSearchStatus = async function (id) {
	switch (id) {
		case statusCode.PENDING:
		case statusCode.PROCESSING:
		case statusCode.SUCCEEDED:
		case statusCode.FAILED:
		case statusCode.SELECTION_PROCESSING:
		case statusCode.SELECTION_DONE:
		case statusCode.CONFIRMATION_PROCESSING:
		case statusCode.CONFIRMATION_DONE:
			return (await getOperatorSearchStatuses(null)).find(status => status.code === id) ?? null;
		default:
			await getOperatorSearchStatuses([id]);
			return repository.get(id);
	}
	return null;
};


const listeners = new Set();

export const useOperatorSearchStatus = () => {
	const [operatorSearchStatuses, setOperatorSearchStatuses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getOperatorSearchStatuses = async function (ids: Array) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let statuses = await loadOperatorSearchStatuses(ids ?? null);
		setLoading(false);
		listeners.forEach(listener => listener());
		return statuses;
	}

	const getOperatorSearchStatus = async function (id) {
		switch (id) {
			case statusCode.PENDING:
			case statusCode.PROCESSING:
			case statusCode.SUCCEEDED:
			case statusCode.FAILED:
			case statusCode.SELECTION_PROCESSING:
			case statusCode.SELECTION_DONE:
			case statusCode.CONFIRMATION_PROCESSING:
			case statusCode.CONFIRMATION_DONE:
				if (!repository?.isFullyLoaded()) {
					await getOperatorSearchStatuses(null);
				}
				return operatorSearchStatuses.find(status => status.code === id) ?? null;
			default:
				await getOperatorSearchStatuses([id]);
				return repository.get(id);
		}
		return null;
	}

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(statuses => {
				setOperatorSearchStatuses(statuses);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, operatorSearchStatuses, getOperatorSearchStatuses, getOperatorSearchStatus };
}

export default useOperatorSearchStatus;