import { getProduction } from '../services/ProductionService';
import { getCompany } from '../services/CompanyService';
import { getUser } from '../services/UserService';
import { getOperatorSearchMatchStatus, getOperatorSearchMatchStatuses } from "./OperatorSearchMatchStatus";
import { getOperatorSearchStatus } from "./OperatorSearchStatus";
import { getSkills, getSkill } from "./SkillService";
import { getSessionSkills } from "./SessionSkillsService";
import { getOperatorSkills } from "./OperatorSkillService";
import { getRepository } from "./RepositoryService";

const operatorRepository = getRepository('operator');
const operatorSearchRepository = getRepository('operatorSearch');
const operatorSearchMatchRepository = getRepository('operatorSearchMatch');

async function loadOperators(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!operatorRepository) {
		throw new Error('No repository defined for operator!');
	}
	let operators = null === ids ? await operatorRepository.getAll(true) : await operatorRepository.get(ids);
	if (Array.isArray(operators) && operators.length > 0 && false === lazy) {
		// preload all operators skills in only one api call to avoid too many requests
		let operatorsSkills = [];
		operators.forEach(operator => {
			if (operator?.skills?.length > 0 && (typeof operator?.skills[0] === 'string')) {
				operatorsSkills = [...operatorsSkills, operator?.skills];
			}
		});
		await getOperatorSkills(operatorsSkills, false);
		// load skills for each operator
		operators = await Promise.all(operators.map(async (record) => {
			// skills
			if (record?.skills?.length > 0 && (typeof record?.skills[0] === 'string')) {
				record.skills = await getOperatorSkills(record.skills, false);
			}
			return record;
		}));
	}
	return operators;
}

export const getOperators = async (ids: Array, lazy: boolean = true) => {
	return await loadOperators(ids ?? null, lazy);
};

export const getOperator = async (id, lazy: boolean = true) => {
	if (id) {
		await getOperators([id], lazy);
		return operatorRepository.get(id);
	}
	return null;
};

export const searchOperators = async (data) => {
	let {id, ...fields} = data;
	let ignoreFields = ['name', 'matches'];
	ignoreFields.forEach(field => {
		if (field in fields) {
			delete fields[field];
		}
	});
	// cast production
	if (fields?.production?.id) {
		fields.production = fields.production.id;
	} else if (typeof fields?.production !== 'string') {
		delete fields['production'];
	}
	// cast sessionSkills
	if (fields?.sessionSkills?.id) {
		fields.sessionSkills = fields.sessionSkills.id;
	} else if (typeof fields?.sessionSkills !== 'string') {
		delete fields['sessionSkills'];
	}
	// cast status
	if (fields?.status?.id) {
		fields.status = fields.status.id;
	} else if (typeof fields?.status !== 'string') {
		delete fields['status'];
	}
	// cast user
	if (fields?.user?.id) {
		fields.user = fields.user.id;
	} else if (typeof fields?.user !== 'string') {
		delete fields['user'];
	}
	let resp = null;
	if (id) {
		// update search
		resp = await operatorSearchRepository.patch(id, fields);
	} else {
		// validate creation
		if (!fields.production) {
			throw new Error("Production must be defined !");
		}
		// create search
		resp = await operatorSearchRepository.post(fields);
	}
	if (resp?.status === 200 && resp?.data?.id) {
		return await getOperatorSearch(resp.data.id, false);
	}
	return null;
};

export const getOperatorSearch = async (id: String, lazy: boolean = true) => {
	let operatorSearch = await operatorSearchRepository.get(id);
	if (operatorSearch) {
		await loadOperatorSearch(operatorSearch, lazy);
		return operatorSearch;
	}
	return null;
};

const loadOperatorSearch = async (search: Object, lazy: boolean = true) => {
	if (false === lazy) {
		// skills
		if (Array.isArray(search.skills) && search.skills.length > 0 && (typeof search.skills[0] === 'string')) {
			search.skills = await getSkills(search.skills);
		}
		// status
		if (Array.isArray(search.status) && search.status.length > 0) {
			let status = await getOperatorSearchStatus(search.status[0]);
			if (status && status.id) {
				search.status = status;
			}
		}
		// production
		if (Array.isArray(search.production) && search.production.length > 0 && (typeof search.production[0] === 'string')) {
			//@todo check to fix infinite loop / circular reference when loading lazy=false
			search.production = await getProduction(search.production[0], true);
			if (search.production?.company?.length > 0 && (typeof search.production?.company[0] === 'string')) {
				search.production.company = await getCompany(search.production.company[0]);
			}
		}
		// sessionSkills
		if (Array.isArray(search.sessionSkills) && search.sessionSkills?.length > 0 && (typeof search.sessionSkills[0] === 'string')) {
			search.sessionSkills = await getSessionSkills(search.sessionSkills[0], false);
		}
		// matches
		if (Array.isArray(search.matches) && search.matches.length > 0 && (typeof search.matches[0] === 'string')) {
			search.matches = await getOperatorSearchMatches(search, false);
		}
	}
	if (search?.user?.length > 0) {
		let user = await getUser(search.user[0]);
		search.user = user;
	}
};

export const getOperatorSearches = async (ids: Array, lazy: boolean = true) => {
	let searches = await operatorSearchRepository.get(ids);
	if (false === lazy) {
		searches = await Promise.all(searches.map(async (search) => {
			await loadOperatorSearch(search, lazy);
			return search;
		}));
	}
	return searches;
};

export const updateOperatorSearchStatus = async (search: Object, status) => {
	if (search?.id && status && status.id) {
		let resp = await operatorSearchRepository.patch(search.id, {'status': [status.id]});
		if (resp?.status === 200) {
			search.status = status;
			return search;
		}
	}
	return false;
};

export const getOperatorSearchMatches = async (search: Object, lazy: boolean = true) => {
	if (Array.isArray(search?.matches) && search.matches.length > 0 && (typeof search.matches[0] === 'string')) {
		let matches = await operatorSearchMatchRepository.get(search.matches);
		if (Array.isArray(matches)) {
			let operators = [];
			let statuses = [];
			let skills = [];
			matches = matches.map(match => {
				match.operatorSearch = search;
				if (match.operator?.length > 0) {
					operators.push(match.operator[0]);
				}
				if (match.status?.length > 0) {
					statuses.push(match.status[0]);
				}
				if (match.skill?.length > 0) {
					skills.push(match.skill[0]);
				}
				return match;
			});
			if (false === lazy) {
				operators = await getOperators(operators, false);
				skills = await getSkills(skills, false);
				statuses = await getOperatorSearchMatchStatuses(statuses);
				for (let i = 0; i < matches.length; i++) {
					if (matches[i].operator) {
						matches[i].operator = await operatorRepository.get(matches[i].operator[0]);
					}
					if (matches[i].skill) {
						matches[i].skill = await getSkill(matches[i].skill[0]);
					}
					if (matches[i].status) {
						matches[i].status = await getOperatorSearchMatchStatus(matches[i].status[0]);
					}
				}
			}
			return matches;
		}
	}
	return [];
};

export const getOperatorSearchMatch = async (id: String, lazy: boolean = true) => {
	let match = await operatorSearchMatchRepository.get(id);
	if (match && false === lazy) {
		// operator
		let operators = await getOperators([match.operator[0]], false);
		match.operator = await operatorRepository.get(match.operator[0]);
		// search
		if (Array.isArray(match.operatorSearch) && match.operatorSearch.length > 0 && (typeof match.operatorSearch[0] === 'string')) {
			let search = await getOperatorSearch(match.operatorSearch[0], lazy);
			if (search && search.id) {
				match.operatorSearch = search;
			}
		}
		// status
		if (Array.isArray(match.status) && match.status.length > 0 && (typeof match.status[0] === 'string')) {
			let status = await getOperatorSearchMatchStatus(match.status[0]);
			if (status && status.id) {
				match.status = status;
			}
		}
		// skill
		if (Array.isArray(match.skill) && match.skill.length > 0 && (typeof match.skill[0] === 'string')) {
			let skill = await getSkill(match.skill[0]);
			if (skill && skill.id) {
				match.skill = skill;
			}
		}
	}
	return match;
};

export const updateOperatorSearchMatch = async (data) => {
	let {id, ...fields} = data;
	let ignoreFields = ['identifier', 'operator', 'operatorSearch', 'skill'];
	ignoreFields.forEach(field => {
		if (field in fields) {
			delete fields[field];
		}
	});
	if (fields?.status?.id) {
		fields.status = fields.status.id;
	} else if (typeof fields?.status !== 'string') {
		delete fields['status'];
	}
	if (id) {
		return await operatorSearchMatchRepository.patch(id, fields);
	}
	return false;
};

export const updateOperatorSearchMatches = async (matches: Array) => {
	let records = matches.map(match => {
		let {id, ...fields} = match;
		let ignoreFields = ['identifier', 'operator', 'operatorSearch', 'skill'];
		ignoreFields.forEach(field => {
			if (field in fields) {
				delete fields[field];
			}
		});
		return {id, fields};
	});
	if (Array.isArray(records) && records.length > 0) {
		return await operatorSearchMatchRepository.patchArray(records);
	}
	return false;
};
