import { useState, useEffect } from "react";
import { getRepository } from "./RepositoryService";

const repository = getRepository('operatorSearchMatchStatus');

export const statusCode = {
	PENDING: 'PENDING',
	PROCESSING: 'PROCESSING',
	SENT: 'SENT',
	ACCEPTED: 'ACCEPTED',
	REJECTED: 'REJECTED',
	CANCELED: 'CANCELED'
};

async function loadOperatorSearchMatchStatuses(ids: Array = null) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for operatorSearchMatchStatus!');
	}
	return null === ids ? await repository.getAll(true) : await repository.get(ids);
}

export const getOperatorSearchMatchStatuses = async (ids: Array) => {
	return await loadOperatorSearchMatchStatuses(ids ?? null);
};

export const getOperatorSearchMatchStatus = async function (id) {
	switch (id) {
		case statusCode.PENDING:
		case statusCode.PROCESSING:
		case statusCode.SENT:
		case statusCode.ACCEPTED:
		case statusCode.REJECTED:
		case statusCode.CANCELED:
			return (await getOperatorSearchMatchStatuses(null)).find(status => status.code === id) ?? null;
		default:
			await getOperatorSearchMatchStatuses([id]);
			return repository.get(id);
	}
	return null;
};


const listeners = new Set();

export const useOperatorSearchMatchStatus = () => {
	const [operatorSearchMatchStatuses, setOperatorSearchMatchStatuses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getOperatorSearchMatchStatuses = async function (ids: Array) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let statuses = await loadOperatorSearchMatchStatuses(ids ?? null);
		setLoading(false);
		listeners.forEach(listener => listener());
		return statuses;
	}

	const getOperatorSearchMatchStatus = async function (id) {
		switch (id) {
			case statusCode.PENDING:
			case statusCode.PROCESSING:
			case statusCode.SENT:
			case statusCode.ACCEPTED:
			case statusCode.REJECTED:
			case statusCode.CANCELED:
				if (!repository?.isFullyLoaded()) {
					await getOperatorSearchMatchStatuses(null);
				}
				return operatorSearchMatchStatuses.find(status => status.code === id) ?? null;
			default:
				await getOperatorSearchMatchStatuses([id]);
				return repository.get(id);
		}
		return null;
	}

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(statuses => {
				setOperatorSearchMatchStatuses(statuses);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, operatorSearchMatchStatuses, getOperatorSearchMatchStatuses, getOperatorSearchMatchStatus };
}

export default useOperatorSearchMatchStatus;