import React, { useContext, useEffect } from 'react';
import Loader from "../../components/Loader";
import { UserContext } from "../../context/Context";

const MagicLink = ({ match }) => {
  const { verifyToken } = useContext(UserContext);

  useEffect(() => {
    const token = match?.params?.token;
    if (token) {
      verifyToken(token).then((valid) => {
        window.location.replace('/');
      });
    }
  }, []);

  return <Loader/>
}

export default MagicLink;