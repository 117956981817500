import React from 'react';
import { Modal, Typography, Upload, message, Space } from "antd";
import styled from "styled-components";
import {InboxOutlined, InfoCircleOutlined, MailOutlined} from "@ant-design/icons";

const StyledModal = styled(Modal)`
  min-width: 40vw;
  .ant-btn-primary[disabled] {
    cursor: default;
    color: #fff;
    background-color: ${props => props.theme.brandColor};
    border-color: ${props => props.theme.brandColor};
    opacity: .7;
  }
  .ant-upload-drag {
    max-height: 25vh;
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        })
        // You can also log the error to an error reporting service
        //this.logError('componentDidCatch', error, errorInfo);
    }

    // fake logging
    //logError = console.log

    render() {
        let details = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
        if (true === this.state.hasError) {
            return (
              <>
                <StyledModal
                 visible={true}
                 title='Oooops, something went wrong'
                >
                <div>
                  <h2>Houston, we've got a problem!</h2>
                  { details &&
                  <details style={{ whiteSpace: 'pre-wrap' }}>
                    <p className="mt-1">
                        {this.state.error ? this.state.error.toString() : 'An error occured. That\'s all we now right now.' }
                    </p>
                    <pre>{this.state.errorInfo ? this.state.errorInfo.componentStack : 'No more details.'}</pre>
                  </details>
                  }
                </div>
                </StyledModal>
              </>
            );
        }

        return this.props.children;
    }
}