import { getRepository } from "./RepositoryService";

const repository = getRepository('productionCompany');

export const getCompany = async (companyId) => {
  if (companyId) {
    return repository.get(companyId);
  }
  return null;
};

export const getLogo = (company, version?: string) =>{
  let logo = null;
  if (company && company.id) {
    if (version) {
      if (version.search('dark') >= 0) {
        logo = company.logoDarkBackground;
      } else if (version.search('light') >= 0) {
        logo = company.logoLightBackground;
      }
    }
    if (!logo) {
      logo = company.logo;
    }
    if (Array.isArray(logo) && logo.length > 0) {
      return logo[0].url;
    }
  }
  return null;
};