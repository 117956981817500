import axios from "axios";
import config from './api-config';
import { clearRecaptchaToken, getRecaptchaToken, getUser } from '../context/Context';

function handleResponse(res) {
  if (res) {
    // if recaptcha token sent, clear recaptcha token to prevent being sent again in next api calls
    // see : Google Recaptcha DUPE error
    if(res.config?.headers?.Recaptcha) {
      clearRecaptchaToken();
    }
    // success response status
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    // error response status
    if (res.status >= 300) {
      let message = null;
      if (res.data && 'string' === typeof res.data && res.data.length > 0) {
        message = res.data;
      } else if (res.data?.message && 'string' === typeof res.data.message && res.data.message.length > 0) {
        message = res.data.message;
      } else if (res.statusText && res.statusText.length > 0) {
        message = res.statusText;
      }
      if (message) {
        throw new Error(message);
      }
    }
  }
  return false;
}

export const api = axios.create({
  baseURL: config.apiURL,
  headers: {
    Authorization: `Basic ${config.apiToken}`,
    "Content-type": "application/json",
    App: `${config.appName}`
  },
  validateStatus: status => (status >= 200 && status < 300) || 418 === status
});

// Intercept API request
// inject headers (auth, lang, recaptcha, user, ...)
api.interceptors.request.use(request => {
  // language
  const lang = navigator.language || navigator.languages.shift();
  if (lang) {
    request.headers.common.lang = `${lang.split(/-|_/).shift()}`;
  }
  const recaptchaToken = getRecaptchaToken();
  if (recaptchaToken) {
    request.headers.common.Recaptcha = `${recaptchaToken}`;
  }
  const user = getUser();
  // User token
  if (user && user.id) {
    // @todo give a look at JWT
    //request.headers.common.Authorization = `Bearer ${user.token}`;
    request.headers.common.User = `${user.id}`;
  }

  return request;
});

// Intercept API response
// first function if the request succeeds
// second function if the request fails
api.interceptors.response.use(
  res => {
    return handleResponse(res) || Promise.reject(res);
  },
  err => {
    // If err.response, the request was made and the server responded with a status code out of the validateStatus range
    handleResponse(err.response);
    if (err.message) {
      // Something happened in setting up the request that triggered an Error
      throw new Error(err.message);
    } else if (err.request) {
      // The request was made but no response was received
      // instance of XMLHttpRequest in the browser an instance of http.ClientRequest in node.js
      // @todo if no response maybe throw error regarding request context|data
    } else if (err.config) {
      // @todo if no response maybe throw error regarding config context|data
    }
    throw new Error('Error');
  }
);

export default api;