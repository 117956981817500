import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import moment from 'moment';
import styled from "styled-components";
import { Button, Card, Col, Input, Modal, Radio, Row, Space, Switch, Tabs, Typography } from 'antd';
import { CalendarOutlined, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, EnvironmentOutlined, InfoOutlined, LoadingOutlined, MailOutlined, MessageOutlined, PhoneOutlined, QuestionOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Loader from '../../components/Loader';
import Logo from "../../components/Logo";
import displayAvatar from '../../helpers/displayAvatar';
import { getLogo } from "../../services/CompanyService";
import { getOperatorSearchMatch, updateOperatorSearchMatch } from "../../services/OperatorService";
import { statusCode as operatorSearchStatusCode, getOperatorSearchMatchStatus } from "../../services/OperatorSearchMatchStatus";

const { Title } = Typography;
const { TabPane } = Tabs;

const HeroHeader = styled.div`
    position: relative;
    width: 100%;
    height: 120px;
    min-height: 120px;
    overflow: hidden;
    background: #46163B;
`;

const HeroHeaderOverlay = styled.div`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*background: rgba(52, 61, 70, 0.7);*/
    z-index: 1;
    transform: none;
`;

const ProductionCompanyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    height: auto;
    
    @media (max-width: 767px) {
        & {
            display: flex;
	          flex-direction: column;
        }
    }
`;

const ProductionCompanyPresentation = styled.div`
    width: 40% !important;
    height: auto;
    background: #fff;
    border-radius: 30px;
    padding: 2rem calc(2rem + 30px) 2rem 2rem;
    overflow: hidden;
    transform: translateX(30px);
    z-index: 0;
    text-align: center;

    > button {
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 15px;
        color: #fff;
    }
    
    @media (max-width: 767px) {
        & {
            width: 94% !important;
            transform: translateX(0);
        }
    }
`;

const ProductionCompanyVideo = styled.video`
    width: 60% !important;
    height: auto;
    border-radius: 30px;
    z-index: 1;
    
    @media (max-width: 767px) {
        & {
            margin-top: 1rem;
            width: 94% !important;
        }
    }
`;

const HeroHeaderContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /*
    > img {
        position: absolute;
        top: 2rem;
        left: 2rem;
        max-height: 60px;
    }
    */
`;

const Content = styled(Space)`
    flex-flow : no-wrap;
    align-items: center;
    width: 100%;
    padding: 2rem;
    background: #f4f4f4;

    .ant-tabs {
        margin-top: 1rem;
    }

    .ant-tabs-tabpane > .ant-space {
        display: flex;
	      justify-content: center;
	      margin: auto;
    }

    .ant-tabs-tabpane > .ant-space > .ant-space-item {
        display: flex;
	      flex-direction: column;
	      flex: 1 1 500px;
    }

    .ant-card {
        display: flex;
        flex-direction: column;
        min-width: 500px;
        margin: 1rem;
        border-radius: 30px;
        overflow: hidden;
        height: 100%;
        > .ant-card-head {
            display: flex;
            min-height: 100px;
            > .ant-card-head-wrapper, .ant-card-head-title, .ant-space {
                width: 100%;
            }
            .ant-space-horizontal >.ant-space-item:last-child {
                margin-left: auto;
            }
        }
        > .ant-card-actions {
            margin-top: auto;
            background: #46163b;
            li {
                color: #fff;
            }
        }
    }

    h1, h2, h3, h4 {
        color: #46163b;
    }
    h2 {
        font-weight: 200;
    }
    > .ant-space-item {
        h1, h2, h3, h4 {
            margin-bottom: 0;
        }
    }

    em {
        color: @primary-color;
    }

    @media (max-width: 767px) {
        padding: 1rem;

        .ant-tabs-tabpane {
             > .ant-space {
                display: flex;
                flex-direction: column;

                > .ant-space-item {
                    margin: 0 !important;
                }
            }
        }

        .ant-card {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            margin: 1rem 0 !important;

            > .ant-card-head {
                .ant-space-horizontal {
                    flex-direction: column;

                    > .ant-space-item {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
`;

const PlayerWrapper = styled.div`
      position: relative;
      width:100%;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
`;

const ModalFooter = styled.div`
    .ant-switch {
        min-width: 66px;
        height: 28px;
        line-height: 28px;
    }
    .ant-switch-handle {
        top: 3px;
        left: 3px;
        width: 22px;
        height: 22px;
    }
    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 22px - 3px);
    }
    .ant-switch-handle::before {
        border-radius: 12px;
    }
    .ant-switch-inner {
        font-size: 16px;
    }
`;

const StatusIconContainer = styled.div`
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      border: 1px solid #ff4000;
      padding: 4px;
`;

const OperatorReply = ({match, location}) => {
    const operatorSearchMatchId = match?.params?.id;
    const token = match?.params?.token;

    const [loading, setLoading] = useState(true);
    const [operatorSearchMatch, setOperatorSearchMatch] = useState({
        id: null,
        operatorSearch: null,
        skill: null,
        operator: null,
        status: null,
        score: null,
        rating: null,
        selected: null,
        ranking: null,
        message: null,
        proposalToken: null,
        proposalSendDate: null,
        proposalReadDate: null,
        replyDate: null,
        replyMessage: null
    });
    const [replyStatus, setReplyStatus] = useState(null);
    const [replyMessage, setReplyMessage] = useState(null);
    const [scheduleCall, setScheduleCall] = useState(false);
    const [scheduleCallChanged, setScheduleCallChanged] = useState(false);
    const [schedulingLink, setSchedulingLink] = useState(null);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [typing, setTyping] = useState(false);
    const [sending, setSending] = useState(false);

    async function loadOperatorSearchMatch(id) {
        let match = await getOperatorSearchMatch(id, false);
        if (match && match.proposalToken === token) {
            if (!('proposalReadDate' in match)) {
                match.proposalReadDate = (new Date()).toISOString();
                updateOperatorSearchMatch(match);
            }
            // retrieve user scheduling link
            setOperatorSearchMatch(match);
            if (match.status?.code === operatorSearchStatusCode.CANCELED) {
                setReplyMessage(match.cancellationReplyMessage);
            } else {
                setReplyMessage(match.replyMessage);
            }
        }
        setLoading(false);
    }

    async function reply() {
        // reply if cancelled
        if (operatorSearchMatch.status?.code === operatorSearchStatusCode.CANCELED) {
            operatorSearchMatch.cancellationReplyMessage = replyMessage;
            updateOperatorSearchMatch(operatorSearchMatch);
            setOperatorSearchMatch(operatorSearchMatch);
            setShowReplyModal(false);
            return;
        }

        // reply if not cancelled
        let status = null;
        operatorSearchMatch.replyStatus = replyStatus;
        if (-1 === operatorSearchMatch.replyStatus) {
            status = await getOperatorSearchMatchStatus(operatorSearchStatusCode.REJECTED);
        } else if (1 === operatorSearchMatch.replyStatus) {
            status = await getOperatorSearchMatchStatus(operatorSearchStatusCode.ACCEPTED);
        }
        switch (operatorSearchMatch?.status?.code) {
            case operatorSearchStatusCode.CANCELED:
                break;
            default:
                if (status) {
                    operatorSearchMatch.status = status;
                }
                break;
        }
        operatorSearchMatch.replyDate = (new Date()).toISOString();
        operatorSearchMatch.replyMessage = replyMessage;
        updateOperatorSearchMatch(operatorSearchMatch);
        setOperatorSearchMatch(operatorSearchMatch);
        setShowReplyModal(false);
        if (schedulingLink && true === scheduleCall) {
            await setSchedulingLink(getSchedulingLink(operatorSearchMatch?.operatorSearch?.user));
            window.open(schedulingLink, '_blank');
        }
    }

    function getSchedulingLink(user) {
        if (user.schedulingLink) {
            let title = operatorSearchMatch?.operatorSearch?.production?.title
                + ', ' + operatorSearchMatch?.operator?.fullName
                + ' (' + operatorSearchMatch?.skill?.name + ')';
            let params = new URLSearchParams();
            switch (true) {
                case /vyte.in/.test(user.schedulingLink) :
                    params.set('cn', operatorSearchMatch.operator.fullName);
                    params.set('cm', operatorSearchMatch.operator.email);
                    params.set('cp', operatorSearchMatch.operator.phone);
                    params.set('et', title);
                    params.set('em', replyMessage ?? '');
                    return user.schedulingLink + '?' + params.toString();
                case /calendly.com/.test(user.schedulingLink) :
                    params.set('name', operatorSearchMatch.operator.fullName);
                    params.set('email', operatorSearchMatch.operator.email);
                    //params.set('cp', operatorSearchMatch.operator.phone);
                    //params.set('et', title);
                    //params.set('em', replyMessage ?? '');
                    return user.schedulingLink + '?' + params.toString();
                default :
                    break;
            }

        }
        return null;
    }

    function resetScheduleCallSwitch() {
        // schedule call switched by user
        if (true === scheduleCallChanged) {
            return;
        }
        // operator search match cancelled
        if (operatorSearchMatch.status?.code === operatorSearchStatusCode.CANCELED) {
            return;
        }
        switch (replyStatus) {
            case -1:
                setScheduleCall(false);
                break;
            default:
                setScheduleCall(null != replyMessage);
                break;
        }
    }

    useEffect(() => {
        if (operatorSearchMatchId && !operatorSearchMatch?.id) {
            loadOperatorSearchMatch(operatorSearchMatchId);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setTyping(false);
        if (operatorSearchMatch?.operatorSearch?.user) {
            setSchedulingLink(getSchedulingLink(operatorSearchMatch?.operatorSearch?.user));
        }
        resetScheduleCallSwitch();
    }, [operatorSearchMatch, replyMessage]);

    useEffect(() => {
        if (true === sending) {
            reply();
            setSending(false);
        }
    }, [schedulingLink]);

    useEffect(() => {
        resetScheduleCallSwitch();
    }, [replyStatus]);

    if (loading) {
        return <Loader/>;
    }

    if (operatorSearchMatch && operatorSearchMatch.proposalToken === token) {
        let startDate = operatorSearchMatch?.operatorSearch?.startDate;
        if (startDate) {
            startDate = moment(startDate).format('LL');
        }
        let endDate = operatorSearchMatch?.operatorSearch?.endDate;
        if (endDate) {
            endDate = moment(endDate).format('LL');
        }
        let replied = operatorSearchMatch?.status?.code === operatorSearchStatusCode.ACCEPTED
                    || operatorSearchMatch?.status?.code === operatorSearchStatusCode.REJECTED;
        let canceled = operatorSearchMatch?.status?.code === operatorSearchStatusCode.CANCELED;

        return (
            <>
                <HeroHeader>
                    <HeroHeaderContent>
                        { operatorSearchMatch?.operatorSearch?.production?.company?.logo?.length > 0 &&
                        <Logo src={getLogo(operatorSearchMatch.operatorSearch.production.company, 'dark')} alt={operatorSearchMatch.operatorSearch.production.company.name} />
                        }
                    </HeroHeaderContent>
                </HeroHeader>
                <Content direction="vertical">
                    <Title level={2}>Mission { canceled ? 'annulée' : 'proposée' } :</Title>
                    <Title level={1}><strong>{ operatorSearchMatch.skill?.name }</strong> pour { operatorSearchMatch?.operatorSearch?.production?.title }</Title>
                    <Tabs defaultActiveKey="mission" centered>
                        <TabPane tab="Mission" key="mission">
                            <Space direction="horizontal" size="large" align="stretch">
                                <Card
                                    bordered={false}
                                    title={ operatorSearchMatch?.operatorSearch?.user?.fullName ?
                                        <>
                                            <Space direction="horizontal" size="large">
                                                { displayAvatar(60,
                                                    operatorSearchMatch.operatorSearch.user.image,
                                                    operatorSearchMatch.operatorSearch.user.firstName,
                                                    operatorSearchMatch.operatorSearch.user.lastName,
                                                    { color: '#fff', backgroundColor: '#eC4e15' }) }
                                                <Space direction="vertical">
                                                    <Title level={4}>{ operatorSearchMatch.operatorSearch.user.fullName }</Title>
                                                    <div>
                                                        { schedulingLink &&
                                                        <a target="_blank" href={schedulingLink}><PhoneOutlined key="schedule"/> Planifier un appel</a> }
                                                    </div>
                                                </Space>
                                                <span></span>
                                            </Space>
                                        </> :
                                        "Message"
                                    }
                                >
                                    <ReactMarkdown source={ operatorSearchMatch.message } renderers={{}}/>
                                </Card>
                                <Card
                                    bordered={false}
                                    title={
                                        <Space direction="horizontal" align="center">
                                            <Title level={4}>Détails de la mission</Title>
                                            <div class="primary" style={{ display: 'flex' }}>
                                                <span style={{ marginRight: '0.5rem' }}>{ operatorSearchMatch?.status?.name }</span>
                                                <StatusIconContainer>
                                                    { operatorSearchStatusCode.ACCEPTED === operatorSearchMatch?.status?.code &&
                                                        <CheckOutlined/>
                                                    }
                                                    { operatorSearchStatusCode.REJECTED === operatorSearchMatch?.status?.code &&
                                                        <CloseOutlined/>
                                                    }
                                                    { operatorSearchStatusCode.CANCELED === operatorSearchMatch?.status?.code &&
                                                        <CloseOutlined/>
                                                    }
                                                    { operatorSearchStatusCode.SENT === operatorSearchMatch?.status?.code &&
                                                        <LoadingOutlined spin />
                                                    }
                                                </StatusIconContainer>
                                            </div>
                                        </Space>
                                    }
                                    actions={!replied ? [
                                        !replied && !canceled ? <div onClick={() => {setReplyStatus(-1); setShowReplyModal(true);}}><span><CloseOutlined key="reject" /></span> <span>Refuser</span></div> : null,
                                        !replied && !canceled ? <div onClick={() => {setReplyStatus(1); setShowReplyModal(true)}}><span><CheckOutlined key="accept" /></span> <span>Accepter</span></div> : null,
                                        (!replied || canceled) ? <div onClick={() => {if (!operatorSearchMatch.replyStatus) { setReplyStatus(0); } setShowReplyModal(true);}}><span><QuestionCircleOutlined key="question" /></span> <span>Contacter</span></div> : null,
                                    ] : null}
                                >
                                    <Space direction="vertical" size="small">
                                        <span>Société de production :&nbsp;
                                            <span className="primary">{ operatorSearchMatch?.operatorSearch?.production?.company?.name }</span>
                                        </span>
                                        <span>Production : <span className="primary">{ operatorSearchMatch?.operatorSearch?.production?.title }</span></span>
                                        <span>Note : { operatorSearchMatch?.operatorSearch?.notes }</span>
                                        <span>&nbsp;</span>
                                        <span><strong>{ operatorSearchMatch.skill?.name }</strong></span>
                                        <span><CalendarOutlined className="primary"/> <span class="primary">{ startDate }</span> au <span class="primary">{ endDate }</span> (dates à confirmer)</span>
                                        <span><EnvironmentOutlined className="primary"/> <span class="primary">{ operatorSearchMatch.operatorSearch?.location }</span></span>
                                    </Space>
                                </Card>
                            </Space>
                        </TabPane>
                        <TabPane tab={operatorSearchMatch?.operatorSearch?.production?.company?.name} key="company">
                            <ProductionCompanyContainer>
                                { operatorSearchMatch?.operatorSearch?.production?.company?.showreel &&
                                    <>
                                        <ProductionCompanyPresentation>
                                            <Title level={3}>Qui est Providz ?</Title>
                                            <p>{operatorSearchMatch.operatorSearch.production.company.description}</p>
                                            <button type="primary" style={{ backgroundColor: operatorSearchMatch?.operatorSearch?.production?.company?.colorPrimary || '#303131' }}>Découvrir {operatorSearchMatch.operatorSearch.production.company.name}</button>
                                        </ProductionCompanyPresentation>
                                        <ProductionCompanyVideo autoPlay={true} loop={true} muted={true} playsInline={true} preload="auto" src={operatorSearchMatch.operatorSearch.production.company.showreel} />
                                    </>
                                }
                            </ProductionCompanyContainer>
                        </TabPane>
                    </Tabs>
                </Content>
                {showReplyModal && <Modal
                    title={<Title level={4}>Réponse à l'offre de mission</Title>}
                    visible={showReplyModal}
                    okText="Envoyer"
                    width={640}
                    onOk={() => { if (typing) { setSending(true); } else { reply(); } }}
                    onCancel={() => setShowReplyModal(false)}
                    footer={<ModalFooter>
                                <Row>
                                    <Col span={24} align="end">
                                        <Space direction="vertical" align="end">
                                            {!canceled &&
                                            <Space direction="horizontal">
                                                <Switch key="schedule"
                                                        checkedChildren={<PhoneOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        checked={scheduleCall}
                                                        onChange={(checked) => { setScheduleCall(checked); setScheduleCallChanged(true); }} />
                                            </Space>}
                                            <Space direction="horizontal">
                                                <Button key="cancel" onClick={() => setShowReplyModal(false)}>
                                                    Annuler
                                                </Button>
                                                <Button key="reply" disabled={typing}  loading={sending}  onClick={() => { if (typing) { setSending(true); } else { reply(); } }} type="primary">
                                                    { replyStatus === -1 ? 'Refuser' : (scheduleCall ? 'Planifier un appel' : (replyStatus === 1 ? 'Accepter' : 'Envoyer' )) }
                                                </Button>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                            </ModalFooter>}
                    >
                    {!canceled &&
                    <>
                        <p>Êtes-vous intéressé·e et disponible autour de ces dates (à confirmer) ?</p>
                        <Radio.Group value={replyStatus} onChange={(e) => setReplyStatus(e.target.value)} buttonStyle="solid">
                            <Radio.Button value={1}><CheckOutlined/>Oui</Radio.Button>
                            <Radio.Button value={-1}><CloseOutlined/>Non</Radio.Button>
                            <Radio.Button value={0}><QuestionOutlined/>Peut-être</Radio.Button>
                        </Radio.Group>
                        <p>&nbsp;</p>
                        { (-1 === replyStatus) &&
                            <p>Indiquez-nous les raisons pour lesquelles vous refusez la mission.<br />
                            Cela nous permet d'améliorer la pertinence des propositions qui vous sont envoyées.</p>}
                        { (-1 !== replyStatus) && <p>Besoin de plus de précisions ?<br/>
                            Indiquez-nous vos questions. Vous serez redirigé·e sur une page pour planifier un échange téléphonique.</p>}
                    </>}
                    {canceled &&
                        <p>La mission a été annulée.<br />Merci de bien vouloir nous confirmer réception en répondant ci-dessous.</p>
                    }
                    <Input.TextArea
                        defaultValue={replyMessage}
                        rows={10}
                        onChange={(e) => { setTyping(true); setReplyMessage(e.target.value.length > 0 ? e.target.value : null );} }
                    />
                </Modal>}
            </>
        );
    }

    return (
        <>
            <h1>❗Aucune proposition en cours !</h1>
        </>
    );
};

export default OperatorReply;
