import React, { useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { routes, routeComponents } from '../routes';
import { Layout } from 'antd';
import Topbar from '../components/Topbar';
import NavbarVertical from '../components/Navbar';
import styled from 'styled-components';
import Loader from '../components/Loader';
import { repository as skillRepository } from '../services/SkillService';
import { repository as skillLevelRepository } from '../services/SkillLevelService';

const { Header, Sider, Content } = Layout;

const StyledHeader = styled(Header)`
  padding: 0 2rem;
  @media (max-width: 1200px) {
    padding: 0 1.5rem;
  }
`;

const StyledSider = styled(Sider)`
  @media (max-width: 1200px) {
    position: fixed;
    bottom: 0;
    max-height: 62px;
    z-index: 11;
    height: 62px;
    width: 100%!important;
    max-width: 100%!important;
  };
`;

const StyledContent = styled(Content)`
  position: relative;
  padding: 2rem;
  overflow-y: auto;
  @media (max-width: 1200px) {
    padding: 1.5rem;
    padding-bottom: 82px;
  }
`;

const AppLayout = () => {
  const [loaded, setLoaded] = useState(false);

  async function loadGlobalAppData() {
    await skillRepository.getAll(true);
    await skillLevelRepository.getAll(true);
    setLoaded(true);
  }

  // Effects
  useEffect(() => {
    loadGlobalAppData();
  }, []);

  if (true === loaded) {
    return (
      <>
        <Layout hasSider={true}>
          <StyledSider width={150}>
            <NavbarVertical/>
          </StyledSider>
          <Layout style={{ overflow: "hidden" }}>
            <StyledHeader>
              <Topbar/>
            </StyledHeader>
            <StyledContent>
              <Switch>
                { routeComponents }
                <Redirect from="/" exact to="/dashboard"/>
              </Switch>
            </StyledContent>
          </Layout>
        </Layout>
      </>
    );
  }

  return <Loader/>;
};

export default AppLayout;
