import React from 'react';
import Styled from 'styled-components';

const RoundedBadge = Styled.div`
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 1.4rem;
    font-weight: bold;
    &.badge-large {
        width: 2.5rem;
        height: 2.5rem;
    }
    &.team-selection {
        background-color: ${props => props.theme.productionStatus.inSelection};
    }
    &.team-complete {
        background-color: ${props => props.theme.productionStatus.selectionComplete};
    }
    &.making {
        background-color: ${props => props.theme.productionStatus.productionStarted};
    }
    &.complete {
        background-color: ${props => props.theme.productionStatus.productionDone};
    }
    &.archive {
      background-color: ${props => props.theme.productionStatus.productionArchived};
    }
`;

const Badge = ({size, background, children}) => {
    const classNames = [`badge-${size}`, background].join(' ');
    return (
        <RoundedBadge className={classNames}>
            {children}
        </RoundedBadge>
    )
}

export default Badge
