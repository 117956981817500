import { api } from '../utils/api';
import config from '../utils/api-config';

const repositories = new Map();

class Repository {
	#entityName: string;
	#loading: boolean;
	#fullLoading: boolean;
	#fullyLoaded: boolean;
	#promises: Map;
	#storage: Map;
	#url: string;
	#fullLoadingAllowed: string;

	constructor(entityName: string) {
		this.#entityName = entityName.trim();
		this.#loading = false;
		this.#fullLoading = false;
		this.#fullyLoaded = false;
		this.#promises = new Map();
		this.#storage = new Map();
		this.#url = null;
		this.#fullLoadingAllowed = null;

		if ('' !== this.entityName && config?.entities && entityName in config.entities) {
			this.#url = `/${config.entities[entityName]?.path}`;
			if (typeof config.entities[entityName]?.fullLoadingAllowed == 'boolean') {
				this.#fullLoadingAllowed = config.entities[entityName]?.fullLoadingAllowed;
			}
		} else {
			throw new Error(`${entityName} is not managed by repository`);
		}
	}

	//@todo set private method (#load or private)
	async load(ids: Array = null) {
		let fullLoading = (null === ids);
		// assert url is set
		if ('' === this.#url) {
			throw new Error(`Api endpoint not found for ${this.#entityName}`);
		}
		// assert not already loading full entities
		if (true !== this.#fullyLoaded && true !== this.#fullLoading) {
			// assert ids array not empty
			if (true === fullLoading && false === this.#fullLoadingAllowed) {
				throw new Error(`Loading all ${this.#entityName} is forbidden. Please provide a list of IDs.`);
			}
			let loadIds = fullLoading ? [] : ids.filter(id => !this.#storage.has(id) && !this.#promises.has(id));
			// load entities not already loaded
			if (fullLoading || loadIds.length > 0) {
				this.#loading = true;
				if (true === fullLoading) {
					this.#fullLoading = true;
				}
				let params = new URLSearchParams();
				params.set('maxRecords', config.maxRecords);
				params.set('pageSize', config.pageSize);
				//params.set('view', config.view);
				if (loadIds.length > 0) {
					params.set('filterByFormula', (`SEARCH(RECORD_ID(), '${loadIds.join(',')}') != ''`));
				}
				let offset = null;
				do {
					if (offset) {
						params.set('offset', offset);
					}
					let query = api.get(`${this.#url}?${params.toString()}`);
					//.then(response => (this.info = response));
					if (fullLoading) {
						this.#promises.set('full', query);
					} else {
						loadIds.forEach(id => this.#promises.set(id, query));
					}
					let resp = await query;
					resp.data.records.forEach(({id, fields}) => {
						this.#storage.set(id, {id, ...fields});
						if (this.#promises.has(id)) {
							this.#promises.delete(id);
						}
					});
					offset = resp?.data?.offset;
				} while (offset);
				if (fullLoading && this.#promises.has('full')) {
					this.#promises.delete('full');
				}
			}
		}
		// wait for all pending promises to end
		if (this.#promises.has('full')) {
			await this.#promises.get('full');
		}
		if (ids && ids.length > 0) {
			await Promise.all(ids.map(id => this.#promises.get(id)));
		}
		this.#loading = false;
		this.#fullLoading = false;
	}

	async get(id: string|Array, autoload: boolean = true) {
		let getOne = false;
		let ids = [];

		if (typeof id === 'string') {
			getOne = true;
			ids = [id];
		} else if (Array.isArray(id)) {
			ids = id;
		}

		// load entities not already loaded
		let loadIds = ids.filter(id => !this.#storage.has(id));
		if (loadIds.length && true === autoload) {
			await this.load(loadIds);
		}

		let entities = ids.map(id => this.#storage.get(id)).filter(entity => entity);
		// return entity
		if (true === getOne) {
			return entities.length > 0 ? entities[0] : null;
		}
		// return entities
		return entities;
	};

	async getByFilters(filters: Object, autoload: boolean = true) {
		// @todo check filters is valid (not empty object)
		let filtersArray = [];
		Object.keys(filters).forEach(field => {
			filtersArray.push(`{${field}} = '${filters[field]}'`);
		});
		let formula = filtersArray.join(', ');
		if (filtersArray.length > 1) {
			formula = `AND(${formula})`;
		}
		let resp = await api.get(`${this.#url}?filterByFormula=${encodeURIComponent(formula)}`);
		if (resp?.data?.records?.length > 0) {
			return resp.data.records.map(({id, fields}) => {
				return {id, ...fields};
			});
		}
		return [];
	};

	async getAll(autoload: boolean = false) {
		if (true === autoload) {
			await this.load();
		}
		return Array.from(this.#storage.values());
	};

	has(id: string) {
		return this.#storage.has(id);
	}

	remove(id: string) {
		this.#promises.delete(id);
		return this.#storage.delete(id);
	}

	isLoading(): boolean {
		return true === this.#loading;
	}

	isFullyLoaded(): boolean {
		return true === this.#fullyLoaded;
	}

	async patch(id: string, fields: array, typecast: boolean = true) {
		return await api.patch(`${this.#url}/${id}`, { fields: fields, typecast: true === typecast });
	}

	async patchArray(records: array, typecast: boolean = true) {
		return await api.patch(`${this.#url}`, { records: records, typecast: true === typecast });
	}

	async post(fields: array, typecast: boolean = true) {
		return await api.post(`${this.#url}`, { fields: fields, typecast: true === typecast });
	}

	async delete(id: string) {
		return await api.delete(`${this.#url}/${id}`);
	}
}

export const getRepository = function (entityName: string) {
	if (!repositories.has(entityName)) {
		repositories.set(entityName, new Repository(entityName));
	}
	return repositories.get(entityName);
};