import React from 'react';
import styled from 'styled-components';
import OverviewListItem from '../List/OverviewListItem';
import { Divider } from 'antd';

const StyledOverviewList = styled.div`
    display: flex;
    flex-direction: column;
`;


const OverviewList = ({items, itemsType}) => {

  return (
    <>
      {
        itemsType === "productions" &&
        <StyledOverviewList>
          {
            items.map((item, key) => {
              const { id, code, name, productions } = item;
              if (productions !== undefined) {
                return <OverviewListItem type={itemsType} id={id} name={name} count={productions.length} badge={code} key={key}/>
              } return false
            })
          }
        </StyledOverviewList>
      }
      {
        itemsType === "operators" && 
        <StyledOverviewList>
          {
            <OverviewListItem type={itemsType} id="incomplete" name="Profils incomplets" count={items.length}/>
          }
        </StyledOverviewList>
      }
    </>
  )
}

export default OverviewList
