import { useState, useEffect } from "react";
import { getRepository } from "./RepositoryService";

export const repository = getRepository('skillLevel');

async function loadSkillLevels(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for skillLevel!');
	}
	let skillLevels = null === ids ? await repository.getAll(true) : await repository.get(ids);
	return skillLevels;
}

export const getSkillLevels = async (ids: Array, lazy: boolean = true) => {
	return await loadSkillLevels(ids ?? null, lazy);
};

export const getSkillLevel = async function (id, lazy: boolean = true) {
	await getSkillLevels([id], lazy);
	return repository.get(id);
};


const listeners = new Set();

export const useSkillLevel = () => {
	const [skillLevels, setSkillLevels] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getSkillLevels = async function (ids: Array, lazy: boolean = true) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let skillLevels = await loadSkillLevels(ids ?? null, lazy);
		setLoading(false);
		listeners.forEach(listener => listener());
		return skillLevels;
	};

	const getSkillLevel = async function (id, lazy: boolean = true) {
		await getSkillLevels([id], lazy);
		return repository.get(id);
	};

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(skillLevels => {
				setSkillLevels(skillLevels);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, skillLevels, getSkillLevels, getSkillLevel };
};

export default useSkillLevel;