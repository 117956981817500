import React, { useEffect, useState } from 'react';
import Logo from '../Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCameraMovie, faPollPeople, faTachometerFast } from '@fortawesome/pro-light-svg-icons';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getNotifications } from '../../services/NotificationService';

const StyledSidebar = styled.nav`
    position: fixed!important;
    background-color: #fbfbfb;
    border-top: 1px solid #ececec;
    width: 100%;
    bottom: 0;
    z-index: 11;
    left: 0;
    @media (min-width: 1200px) {
        background-color: #fff;
        justify-content: center!important;
        align-items: flex-start!important;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: 150px;
    }
    @media (max-width: 1200px) {
        svg {
            font-size: 1.6rem;
        }
    }
`;

const SidebarContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
`;

const Nav = styled.div`
@media (min-width: 1200px) {
    margin-top: 2rem;
    margin-bottom: 8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    flex-direction: column;
    flex: 1 1 auto;
}
display: flex;
width: 100%;
justify-content: space-around;
flex-direction: row;
@media (max-width: 1200px) {
  padding: .5rem;
}
`;

const NavItem = styled.div`
    display: flex;
    justify-content: center; 
    a {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
`;

const NavBarLink = styled(NavLink)`
    position: relative;
    color: ${props => props.theme.textColor};
    &:hover {
        text-decoration: none;
        color: ${props => props.theme.textColor};
    }
    &.active {
        color: ${props => props.theme.brandColor};
    }
`;

const NavBarLinkLabel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1200px) {
        font-size: .8rem;
    }
`;

const Brand = styled.div`
    margin: 2rem 0;
    text-align: center;
    @media (max-width: 1200px) {
        display: none;
    }
`;

const CountBadge = styled.div`
  width: 26px;
  height: 26px;
  font-size: .9rem;
  position: absolute;
  right: 14px;
  top: 4px;
  border-radius: 100px;
  background-color: ${props => props.theme.brandColor};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    width: 22px;
    height: 22px;
    font-size: .75rem;
    top: 2px;
    right: 12px;
  }
`;

const NavbarVertical = () => {
    const [notifications, setNotifications] = useState([]);

    // Onload
    useEffect(() => {
        getNotifications().then(notifications => setNotifications(notifications.filter(notification => !notification.read)));
    }, []);

    return (
      <StyledSidebar>
          <SidebarContent>
              <Brand>
                  <Logo type="small"/>
              </Brand>

              <Nav>
                  <NavItem>
                      <NavBarLink to="/dashboard">
                          <FontAwesomeIcon icon={faTachometerFast} size="3x"/>
                          <NavBarLinkLabel className="py-xl-2">Dashboard</NavBarLinkLabel>
                      </NavBarLink>
                  </NavItem>
                  <div className="navbar-vertical-divider xs-hide">
                      <hr className="navbar-vertical-hr"/>
                  </div>
                  <NavItem>
                      <NavBarLink to="/productions">
                          <FontAwesomeIcon icon={faCameraMovie} size="3x"/>
                          <NavBarLinkLabel className="py-xl-2">Productions</NavBarLinkLabel>
                      </NavBarLink>
                  </NavItem>
                  <div className="navbar-vertical-divider xs-hide">
                      <hr className="navbar-vertical-hr"/>
                  </div>
                  <NavItem>
                      <NavBarLink to="/operators">
                          <FontAwesomeIcon icon={faPollPeople} size="3x"/>
                          <NavBarLinkLabel className="py-xl-2">Opérateurs</NavBarLinkLabel>
                      </NavBarLink>
                  </NavItem>
                  <div className="navbar-vertical-divider xs-hide">
                      <hr className="navbar-vertical-hr"/>
                  </div>
                  <NavItem>
                      <NavBarLink to="/notifications">
                          <FontAwesomeIcon icon={faBell} size="3x"/>
                          <NavBarLinkLabel className="py-xl-2">Notifications</NavBarLinkLabel>
                          {
                              notifications.length > 0 &&
                              <CountBadge>{notifications.length}</CountBadge>
                          }
                      </NavBarLink>
                  </NavItem>
              </Nav>

          </SidebarContent>
      </StyledSidebar>
    );
};

export default NavbarVertical;
