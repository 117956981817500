import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button, Typography, Space } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import Loader from '../../components/Loader';
import displayAvatar from '../../helpers/displayAvatar';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { getOperators } from "../../services/OperatorService";
import { getJobs } from "../../services/JobService";

 const { Text } = Typography;

const StyledActionsButtons = styled.div`
  padding: 0 0 1.5rem 0;
`;

const TableCounter = styled.div`
  display: inline-block;
  margin: 1rem 0 1.5rem 0;
  font-size: .9rem;
  span {
    font-weight: bold;
  }
`;

const StyledLink = styled(Link)`
  color: #343D46;
  &:hover {
    opacity: .8;
    color: #343D46;
  }
`;

const ActionIcons = styled.span`
  a {
    color: #343D46;
    padding: 5px 20px;
    display: flex;
    &:hover {
      opacity: .8;
      color: #343D46;
    }
  }
`;

const WarningTag = styled.span`
    font-size: 14px;
    padding: 7px 12px;
    color: #343e45;
    margin-right: 0;
    margin-left: 1rem;
    border-radius: 2px;
    border: 1px solid #ececec;
    background: #ececec;
`;

const Operators = ({ location }) => {

  const { queryFilterId } = location;

  const [operators, setOperators] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [displayResetFilter, setdisplayResetFilter] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0);

  // Onload
  useEffect(() => {
    // load operators
    getJobs().then(jobs => setJobs(jobs));
    // load operators
    getOperators().then(operators => setOperators(operators));
    if (queryFilterId === "incomplete") {
      const incompletes = operators.filter((operator) => !operator.profileComplete);
      setFilteredCount(incompletes.length);
      setdisplayResetFilter(true);
      setFilteredItems(() => incompletes);
      setFiltered(true);
    }
  }, []);

  if (operators && jobs) {

    const labelFormatter = (labelId) => {
      if (labelId) {
        const matchingLabelId = jobs.filter((job) => job.id === labelId[0]);
        const { name } = matchingLabelId[0];
        return name;
      }
      return <Text type="secondary">Aucune mission récente</Text>
    };

    const nameFormatter = (record) => {
      const { id, image, imageURL, firstName, lastName, fullName } = record;
      return (
        <StyledLink to={`/operators/${id}`}>
          <Space size="middle">
            {displayAvatar(40, image || imageURL, firstName, lastName)}
            <Text strong>{ fullName }</Text>
          </Space>
        </StyledLink>
      )
    };

    const actionsViewFormatter = (record) => {
      return (
        <ActionIcons>
          <Space>
          <Link to={`/operators/${record.id}`}>
              <FontAwesomeIcon icon={faEllipsisV} size="2x"/>
          </Link>
          </Space>
        </ActionIcons>
      )
    };
  
    const columns = [
      {
        dataIndex: "fullName",
        key: "fullName",
        title: "Nom",
        width: "280px",
        render: (text, record) => (
          nameFormatter(record)
        ),
      },
      {
        dataIndex: "jobTitle",
        key: "jobTitle", 
        title: "Métier",
        width: "280px",
        sorter: (a, b) => (a.jobTitle && b.jobTitle) ? a.jobTitle[0].localeCompare(b.jobTitle[0]) : 0,
        sortDirections: ['ascend', 'descend'],
      },
      {
        dataIndex: "location",
        key: "location", 
        title: "Localisation",
        responsive: ['md'],
        width: "250px",
      },
      {
        dataIndex: "jobs",
        key: "jobs", 
        title: "Mission récente",
        ellipsis: true,
        responsive: ['md'],
        render: labelFormatter
      },
      {
        title: "",
        dataIndex: "",
        width: "70px",
        align: "center",
        responsive: ['md'],
        render: (text, record) => (
          actionsViewFormatter(record)
        )
      }
    ];

    const operatorsCount = operators ? operators.length : 0;

    const clearFilters = () => {
      setdisplayResetFilter(false);
      setFiltered(false);
      setFilteredItems([]);
    };

    const displayCount = () => {
      if (displayResetFilter || filteredItems.length > 0) {
        return (
        <><span>{filteredCount}</span> élément{ filteredCount > 1 ? 's' : '' }</>
        )
      } return <><span>{ `${operatorsCount}` }</span> élément{ operatorsCount > 1 ? 's' : '' }</>
    };

    return (

      <>
      <StyledActionsButtons>
      <Row justify="space-between">
        <Col>
          <Button type="primary" className="text-uppercase mb-1">
            Créer une équipe
          </Button>
        </Col>
        <Col>
          <Button className="text-uppercase" ghost type="primary">
            Importer dans la base
          </Button>
        </Col>
      </Row>
      </StyledActionsButtons>
      {
        operators && 
        <>
        <Row justify="space-between" align="middle">
          <Col>
            <TableCounter>
              { displayCount() }
            </TableCounter>
            {
              displayResetFilter &&
                <Space>
                  <WarningTag>
                    Liste filtrée
                  </WarningTag>
                  <Button style={{fontSize:"14px"}} onClick={() => { clearFilters() }}>
                    Réinitialiser
                  </Button>
                </Space>
            }
          </Col>
        </Row>
        </>
      }

      <Table
        columns={columns}
        rowKey="id"
        size="small"
        dataSource={filtered === true ? filteredItems : operators}
        pagination={operatorsCount > 8 ? {position: ['', 'bottomCenter'], pageSize: 8} : false}
      />

      </>

    );

  }

  return <Loader/>

};

export default Operators;
