import React, { useContext } from 'react';
import PageHeader from '../PageHeader';
import { Menu, Dropdown, Avatar, Space, Typography } from 'antd';
import { LogoutOutlined, UserOutlined, BellOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context/Context';
import displayAvatar from '../../helpers/displayAvatar';
import getPageName from '../../helpers/getPageName';
import styled from 'styled-components';

const { Text } = Typography;

const StyledTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserBloc = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1rem;
`;

const UserText = styled(Text)`
  color: #fff;
`;

const TopBar = ({  }) => {
  const location = useLocation();
  let pageTitle = getPageName(location);
  let { user, company, logout } = useContext(UserContext);

  const menu = (
    <Menu>
      <Menu.Item key="0" icon={<UserOutlined style={{fontSize: '14px'}}/>}>
        <a href="#">Profil</a>
      </Menu.Item>
      <Menu.Item key="1" icon={<BellOutlined style={{fontSize: '14px'}}/>}>
        <a href="notifications">Notifications</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" icon={<LogoutOutlined style={{fontSize: '14px'}}/>} onClick={logout}>Déconnexion</Menu.Item>
    </Menu>
  );

  return (
    <StyledTopBar>
        <PageHeader title={pageTitle} titleTag="h2" color="white"/>
        { user &&
        <Dropdown overlay={menu}>
          <div>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <Space>
                {displayAvatar(40, user.image, user.firstName, user.lastName, { color: '#fff', backgroundColor: '#EC4E15' })}
                <UserBloc className="xs-hide">
                  <UserText strong>{user.fullName}</UserText>
                  { company && <UserText>{company.name}</UserText> }
                </UserBloc>
              </Space>
            </a>
          </div>
        </Dropdown>
        }
    </StyledTopBar>
  );
};

export default TopBar;
