import React, { Component } from "react";
import { Badge, Modal, Typography, Upload, message, Space } from "antd";
import { ExperimentTwoTone, InfoCircleOutlined, InboxOutlined, MailOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';

const { Text, Paragraph } = Typography;
const { Dragger } = Upload;

const StyledModal = styled(Modal)`
  min-width: 40vw;
  .ant-btn-primary[disabled] {
    cursor: default;
    color: #fff;
    background-color: ${props => props.theme.brandColor};
    border-color: ${props => props.theme.brandColor};
    opacity: .7;
  }
  .ant-upload-drag {
    max-height: 25vh;
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-ribbon-wrapper {
    position: absolute;
    right: 0;
    top: -20px;
  }
`;

const ExperimentalOverlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  color: #ff4000;
  font-size: 16px;
`;

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      experimental: false
    };
  }

  handleUpload = async () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file);
    });

    this.setState({
      uploading: true,
      experimental: true
    });

    let resp = await axios.get('https://run.mocky.io/v3/d7cce111-1f26-4142-9f33-723019541688');
    if (resp.status === 200) {
      setTimeout(() => {
        this.setState({
          fileList: [],
          uploading: false,
        });
        message.success('La liste a bien été importé');
      }, 2000);
      setTimeout(() => {
        this.setState({
          fileList: [],
          uploading: false,
          experimental: false,
        });
        this.props.onCancel();
      }, 3000);
    } else {
      this.setState({
        uploading: false,
        experimental: false
      });
      message.error('Une erreur est survenue pendant l\'import');
    };


  };

  render() {
    const { uploading, fileList, experimental } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const {
      visible, 
      onCancel
    } = this.props

    return (
      <StyledModal
        visible={visible}
        title='Importer des opérateurs'
        onCancel={onCancel}
        onOk={this.handleUpload}
        okText={uploading ? 'Envoi en cours' : 'Importer'}
        okButtonProps={{disabled: fileList.length === 0 ? true : false }}
      >
        <Badge.Ribbon text={<><ExperimentTwoTone twoToneColor="#ffffff" style={{ fontSize:'16px' }} /> Expérimental</>} color="#ff4000" />
        <Paragraph align="center" className="mb-2">
          <Space align="start" size="small">
            <MailOutlined style={{ fontSize: '24px'  }} />
            Une invitation leur sera automatiquement envoyée par e-mail
          </Space>
        </Paragraph>
        <Dragger accept=".csv" {...props}>
          {experimental &&
            <ExperimentalOverlay>
              <Space>
                <InfoCircleOutlined style={{ fontSize: '20px'  }} />
                Cette fonctionnalité n'est pas encore disponible.
              </Space>
            </ExperimentalOverlay>
          }
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Cliquer ou déposer un fichier dans cette zone</p>
          <p className="ant-upload-hint">
            <InfoCircleOutlined style={{ color:"#565656",verticalAlign:"text-bottom",fontSize:"1rem",marginRight:".3rem" }}/>
            Format de fichier accepté : CSV (max 2Mo)
          </p>
        </Dragger>
      </StyledModal>
    );
  }
}

export default ImportModal;
