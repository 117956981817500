import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { UserContext } from "../../context/Context";
import {
  getProductionTypes,
  createProduction,
  updateProduction,
  deleteProduction
} from '../../services/ProductionService';
import {
  statusCode as productionStatusCode,
  useProductionStatus
} from '../../services/ProductionStatusService';
import {
  useProductionStage
} from '../../services/ProductionStageService';
import { statusCode } from "../../services/OperatorSearchMatchStatus";
import { useProduction } from "../../services/ProductionService";
import Loader from '../../components/Loader';
import Session from '../../components/Production/Session';
import displayAvatar from '../../helpers/displayAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';

import {
  DeleteOutlined,
  MessageOutlined,
  SearchOutlined,
  SendOutlined,
  StopOutlined
} from '@ant-design/icons';
import { Redirect } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import BackButton from '../../components/BackButton';

const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Menu;
const { TabPane } = Tabs;

let isLoadingCrew;
let displayLoader;
let crewMembers = [];

const StyledLink = styled(Link)`
  color: #343D46;
  display: block;
  &:hover {
    opacity: .7rem;
    color: #343D46;
  }
  @media (max-width: 550px) {
    font-size: .9rem;
    .ant-space {
      .ant-space-item:first-child {
        display: none;
      }
    }
    .ant-avatar {
      display: none;
    }
  }
`;

const StyledTag = styled(Tag)`
  border: none;
  padding: 4px 15px;
  font-size: .9rem;
  font-weight: bold;
  background-color: #FFFFFF;

  &.team-selection {
    background-color: ${props => props.theme.productionStatus.inSelection};
  }
  &.team-complete {
    background-color: ${props => props.theme.productionStatus.selectionComplete};
  }
  &.making {
    background-color: ${props => props.theme.productionStatus.productionStarted};
  }
  &.complete {
    background-color: ${props => props.theme.productionStatus.productionDone};
  }
  &.archive {
    background-color: ${props => props.theme.productionStatus.productionArchived};
  }
`;

const StyledCount = styled(Text)`
  font-size: 1.6rem;
  margin-right: 1rem;
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
`;

const ActionIcons = styled.span`
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 20px;
    margin-left: auto;
  a {
    color: #343D46;
    padding: 5px 20px;
    display: flex;
  }
  &:hover {
      opacity: .8;
      color: ${props => props.theme.textColor};;
      cursor: pointer;
    }
`;

const StatusContent = styled.div`
  color: #343e46;
  display: inline-block;
  padding: .2rem .5rem;
  border-radius: 3px;
  &.pending {
    background-color: ${props => props.theme.matchStatus.pending};
  }
  &.processing {
    background-color: ${props => props.theme.matchStatus.processing};
  }
  &.sent {
    background-color: ${props => props.theme.matchStatus.sent};
  }
  &.accepted {
    background-color: ${props => props.theme.matchStatus.accepted};
  }
  &.rejected {
    background-color: ${props => props.theme.matchStatus.rejected};
  }
  &.canceled {
    background-color: ${props => props.theme.matchStatus.canceled};
  }
  @media (max-width: 900px) {
    font-size: .9rem;
  }
`;

const EditProduction = ({ match }) => {

  const { user } = useContext(UserContext);
  const history = useHistory();

  // Initials values
  const prodId = match.params?.id;
  const [form] = Form.useForm();

  const success = () => {
    message.success('Production enregistrée !');
  };
  
  const error = (messageText) => {
    message.error(messageText);
  };

  const onFinish = async (values) => {
    let { title, description, type, estimatedStartDate, estimatedEndDate, releaseDate } = values;

    let tmpProduction = {...production};
    if (title) {
      tmpProduction.title = title;
    }
    tmpProduction.description = description;
    if ((Array.isArray(type) && type.length) || typeof type === 'string') {
      tmpProduction.type = type;
    }
    if (estimatedStartDate) {
      tmpProduction.estimatedStartDate = estimatedStartDate.toISOString();
    }
    if (estimatedEndDate) {
      tmpProduction.estimatedEndDate = estimatedEndDate.toISOString();
    }
    if (releaseDate) {
      tmpProduction.releaseDate = releaseDate.toISOString();
    }
    if (!tmpProduction.user) {
      tmpProduction.user = user;
    }

    let resp = null;
    if (tmpProduction.id) {
      resp = await updateProduction(tmpProduction);
      loadProduction(tmpProduction.id);
    } else {
      resp = await createProduction(tmpProduction);
      if (resp.data?.id) {
        loadProduction(resp.data.id);
        history.push(`/productions/${resp.data.id}`);
      }
    }
    resp.status === 200 ? success() : error('Impossible d\'enregistrer les modifications')
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const deleteProd = async () => {
    displayLoader = true;
    const resp = await deleteProduction(prodId);
    if (resp.status === 200) {
      message.success('La production a bien été suprimée');
      setTimeout(() => {
        displayLoader = false;
        setRedirect(true);
      }, 800);
    } else {
      message.error('Impossible de supprimer la production !');
      displayLoader = false;
    }
  }

  // States && Queries
  const [loading, setLoading] = useState(false);
  const [production, setProduction] = useState(null);
  const [productionTypes, setProductionTypes] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [addSession, setAddSession] = useState(false);
  const [showAllMatches, setShowAllMatches] = useState(false);
  const [operatorSearchMatch, setOperatorSearchMatch] = useState(null);
  const { getProduction, error:errorProduction, loading:loadingProduction } = useProduction();
  const { getStatus: getProductionStatus, error:errorProductionStatus, loading:loadingProductionStatus } = useProductionStatus();
  const { getStages: getProductionStages, error:errorProductionStage, loading:loadingProductionStage } = useProductionStage();

  async function loadProduction(id) {
    await getProductionStages();
    let production = await getProduction(id, false);
    if (production) {
      setProduction(production);
      if (production.id !== prodId) {
        history.push(`/productions/${production.id}`);
      }
    } else {
      setRedirect(true);
    }
  }

  async function createNewProduction() {
    let status = await getProductionStatus(productionStatusCode.CREATION);
    setProduction({
      id: null,
      title: null,
      description: null,
      type: [],
      company: user?.company ?? null,
      status: status,
      estimatedStartDate: null,
      estimatedEndDate: null,
      estimatedBudget: null,
      releaseDate: null,
      sessions: [],
      operatorSearches: [],
      jobs: [],
      user: user
    });
  }

  const onSessionCancel = async () => {
    setAddSession(false);
  };

  const onSessionSave = async (session) => {
    setAddSession(false);
    /*
    if (session?.production) {
      setProduction(null);
      setProduction(session.production);
    }
    */
  };

  const onSessionDelete = async (session) => {
    if (session?.production) {
      setLoading(true);
      setProduction(null);
      setProduction(session.production);
      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    getProductionTypes().then(types => setProductionTypes(types));
    if (prodId) {
      if ('create' === prodId) {
        createNewProduction();
      } else {
        loadProduction(prodId);
      }
    } else {
      setRedirect(true);
    }
    crewMembers = [];
  }, [prodId]);

  if (redirect) {
    return (
      <Redirect to='/productions'/>
    )
  }

  if (!loading && null !== production && productionTypes) {

      let {
        id,
        title,
        estimatedStartDate,
        estimatedEndDate,
        estimatedBudget,
        releaseDate,
        type,
        status,
        description
      } = production;

      if (estimatedStartDate) {
        estimatedStartDate = moment(estimatedStartDate);
      }

      if (estimatedEndDate) {
        estimatedEndDate = moment(estimatedEndDate);
      }

      if (releaseDate) {
        releaseDate = moment(releaseDate);
      }

      const createCrew = () => {
        crewMembers = [];
        if (production?.operatorSearches?.length > 0) {
          production.operatorSearches.forEach(search => {
            if (search?.matches?.length > 0) {
              search.matches.forEach(match => {
                if (match.id && match.operator) {
                  if (statusCode.ACCEPTED === match.status?.code) {
                    crewMembers.push({operator: match.operator, status: match.status, match: match, key: match.id});
                  } else if (showAllMatches && statusCode.REJECTED === match.status?.code) {
                    crewMembers.push({operator: match.operator, status: match.status, match: match, key: match.id});
                  }
                }
              });
            }
          });
          crewMembers.sort((a, b) => {
            if (statusCode.REJECTED === a.status?.code && statusCode.ACCEPTED === b.status?.code) {
              return 1;
            }
            if (statusCode.ACCEPTED === a.status?.code && statusCode.REJECTED === b.status?.code) {
              return -1;
            }
            return a.operator?.fullName < b.operator?.fullName;
          });
        }
      };

      const nameFormatter = operator => {
        if (operator) {
          const { id, image, firstName, lastName, fullName, jobTitle } = operator;
          return (
              <StyledLink to={`/operators/${id}`}>
                <Space size="middle">
                  {displayAvatar(40, image, firstName, lastName)}
                  <Text>
                    <div className="text-strong">{ fullName }</div>
                    <div>{ jobTitle }</div>
                  </Text>
                </Space>
              </StyledLink>
          );
        }
        return (<></>);
      }

      const statusNameFormatter = status => {
        return (
          <StatusContent className={status.code.toLowerCase()}>
            { status.name }
          </StatusContent>
        )
      }

      const menu = record => {
        let actions = [
          <Item key="1" icon={<StopOutlined />}><a>Annuler la demande</a></Item>
        ];
        if (record.status.code === statusCode.SENT) {
          actions.push(
            <Item key={actions.length + 1} icon={<SendOutlined />}><a>Renvoyer la demande</a></Item>
          );
        }
        if (record.match.replyMessage && record.match.replyMessage !== '') {
          actions.push(
            <Item key={actions.length + 1} icon={<MessageOutlined />} onClick={() => setOperatorSearchMatch(record.match)}><a>Voir la réponse</a></Item>
          );
        }
        return (
          <Menu>
            { actions }
          </Menu>
        )
      }

      const actions = record => {
        return (
          <Dropdown overlay={menu(record)} trigger={['click']}>
            <ActionIcons>
              <FontAwesomeIcon icon={faEllipsisV} size="2x"/>
            </ActionIcons>
          </Dropdown>
        )
      }

      const searchActionsMenu = record => {
        return (
            <Menu>
              <Item key="1" icon={<SearchOutlined />}><a>Voir la recherche</a></Item>
            </Menu>
        )
      }

      const searchActions = record => {
        return (
          <Dropdown overlay={searchActionsMenu(record)} trigger={['click']}>
            <ActionIcons>
              <FontAwesomeIcon icon={faEllipsisV} size="2x"/>
            </ActionIcons>
          </Dropdown>
        )
      }
  
      const crewColumns = [
        {
          dataIndex: "operator",
          key: "matchOperator",
          title: "",
          width: "63%",
          render: (text, record) => nameFormatter(record.operator)
        },
        {
          dataIndex: "status",
          key: "matchStatus",
          title: "",
          width: "31%",
          align: "right",
          render: (text, record) => statusNameFormatter(record.status)
        },
        {
          title: "",
          dataIndex: "",
          align: "right",
          render: (text, record) => actions(record)
        }
      ];
  
      createCrew();

      return (
      <>
        <Row gutter={64} justify="space-between" align="middle">
          <Col><Title level={3}>{ title ?? 'Nouvelle production' }</Title></Col>
          <Col>
            <StyledTag className={production?.status?.code?.toLowerCase()}>{production?.status?.name?.toUpperCase()}</StyledTag>
            {production.id &&
            <Popconfirm
              title="Etes-vous sûr(e) ?"
              onConfirm={deleteProd}
              okText="Oui"
              cancelText="Non"
              placement="bottom"
            >
              <Button type="text" size="small" style={{color:"#a9a9a9"}}>
                <Space>
                  <DeleteOutlined />
                  Supprimer
                </Space>
              </Button>
            </Popconfirm>}
          </Col>
        </Row>
        <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
        <Row gutter={64}>
          <Col xs={24} lg={14}>
            <Tabs defaultActiveKey="1" size="large">
              <TabPane tab="General" key="1">
                <Form
                  form={form}
                  name="vertical_edit"
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    title,
                    estimatedStartDate,
                    estimatedEndDate,
                    releaseDate,
                    type,
                    status,
                    description
                  }}
                >
                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="Titre"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: 'Merci de renseigner le titre',
                          },
                        ]}
                      >
                        <Input placeholder="Titre" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="Type"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: 'Merci de renseigner le type',
                          },
                        ]}
                      >
                        <Select>
                          { productionTypes.map((typeId, key) =>
                            <Option key={key} value={typeId.id}>{typeId.name}</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="Début de production"
                        name="estimatedStartDate"
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          locale="fr"
                          format="LL"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="Fin de production"
                        name="estimatedEndDate"
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          locale="fr"
                          format="LL"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="Date de livraison/diffusion"
                        name="releaseDate"
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          locale="fr"
                          format="LL"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Form.Item name="description" label="Description">
                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 8 }} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="space-between" className="mt-2">
                    <Col>
                      <Form.Item>
                        <BackButton text="Retour"/>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          { production.id ? 'Enregistrer' : 'Créer' }
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tab="Sessions" key="2" disabled={!production.id}>
                <Space size="middle" direction="vertical" style={{ width: '100%' }}>
                  {production?.sessions?.map(session => <Session key={session.id}
                                                                 instance={session}
                                                                 onCancel={onSessionCancel}
                                                                 onSave={onSessionSave}
                                                                 onDelete={onSessionDelete}/>)}
                  <Button type="primary" htmlType="submit" onClick={() => setAddSession(true)}>
                    Ajouter une session
                  </Button>
                  {addSession &&
                    <Session production={production} onCancel={onSessionCancel} onSave={onSessionSave} />}
                </Space>
              </TabPane>
              <TabPane tab="Budget" key="3" disabled={!production.id}>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                        label="Budget global"
                        name="estimatedBudget"
                    >
                      <InputNumber defaultValue={0} /> €
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
          {production.id &&
          <Col xs={24} lg={10}>
            <Card
              bordered={false}
            >
              <Row justify="space-between" align="center">
                <Col>
                  <Title level={4}>Opérateurs</Title>
                  <small>Voir tous</small>
                  &nbsp;<Switch size="small" checked={showAllMatches}
                                onChange={(checked) => setShowAllMatches(checked)}/>
                </Col>
                <Col><StyledCount strong>{crewMembers.length}</StyledCount></Col>
              </Row>
              <Divider/>
              <StyledTable
                loading={isLoadingCrew}
                dataSource={crewMembers}
                rowKey="key"
                pagination={false}
                columns={crewColumns}
                showHeader={false}
              />
              <Row justify="space-between" align="center" style={{marginTop: '1rem'}}>
                <Col><Title level={4}>Recherches</Title></Col>
                <Col><StyledCount strong>{production?.operatorSearches?.length}</StyledCount></Col>
              </Row>
              <Divider/>
              <StyledTable
                loading={false}
                dataSource={production?.operatorSearches}
                rowKey="id"
                pagination={false}
                columns={[
                  {
                    dataIndex: "name",
                    key: "searchName",
                    title: "",
                    width: "63%",
                    render: (text, record) => <StyledLink to={{
                      pathname: `/operators/search/${record.id}`,
                      productionId: production.id,
                      productionName: production.title
                    }}>{record.name}</StyledLink>
                  },
                  {
                    dataIndex: "status",
                    key: "searchStatus",
                    title: "",
                    width: "31%",
                    align: "right",
                    render: (text, record) => record?.status?.name
                  },
                  {
                    title: "",
                    dataIndex: "",
                    align: "right",
                    render: (text, record) => searchActions(record)
                  }
                ]}
                showHeader={false}
              />
              <Button type="primary" block className="my-2">
                <Link to={
                  {
                    pathname: '/operators/search',
                    productionId: id,
                    productionName: title
                  }
                }>Nouvelle recherche</Link>
              </Button>
            </Card>
          </Col>}
        </Row>
        {operatorSearchMatch &&
        <Modal title="Réponse" visible={!!operatorSearchMatch} onOk={() => setOperatorSearchMatch(null)} onCancel={() => setOperatorSearchMatch(null)}>
          <p>{operatorSearchMatch.replyMessage}</p>
        </Modal>
        }
      </>
      )

    }

    return (
      <Loader/>
    )



}

export default EditProduction;
