import { useState, useEffect } from "react";
import { getSkills } from "./SkillService";
import { getSession } from "./SessionService";
import { statusCode as operatorSearchStatusCode } from "./OperatorSearchStatus";
import { searchOperators } from "./OperatorService";
import { getRepository } from "./RepositoryService";

const repository = getRepository('sessionSkills');

async function loadSessionsSkills(ids: Array = null, lazy: boolean = true) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for session!');
	}
	let sessionsSkills = null === ids ? await repository.getAll(true) : await repository.get(ids);
	if (false === lazy) {
		sessionsSkills = await Promise.all(sessionsSkills.map(async (record) => {
			// session
			if (Array.isArray(record.session) && record?.session?.length > 0 && (typeof record.session[0] === 'string')) {
				//@todo check to fix infinite loop / circular reference when loading lazy=false
				record.session = await getSession(record.session[0], true);
			}
			// skills
			if (Array.isArray(record.skills) && record?.skills?.length > 0 && (typeof record.skills[0] === 'string')) {
				record.skills = await getSkills(record.skills);
			}
			return record;
		}));
	}
	return sessionsSkills;
}

export const getSessionsSkills = async (ids: Array, lazy: boolean = true) => {
	return await loadSessionsSkills(ids ?? null, lazy);
};

export const getSessionSkills = async function (id, lazy: boolean = true) {
	await getSessionsSkills([id], lazy);
	return repository.get(id);
};


const listeners = new Set();

export const useSessionSkills = () => {
	const [sessionSkills, setSessionSkills] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getSessionsSkills = async function (ids: Array, lazy: boolean = true) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let sessionsSkills = await loadSessionsSkills(ids ?? null, lazy);
		setLoading(false);
		listeners.forEach(listener => listener());
		return sessionsSkills;
	};

	const getSessionSkills = async function (id, lazy: boolean = true) {
		await getSessionsSkills([id], lazy);
		return repository.get(id);
	};

	const saveSessionSkills = async (data) =>{
		let {id, ...fields} = data;
		let ignoreFields = ['name'];
		ignoreFields.forEach(field => {
			if (field in fields) {
				delete fields[field];
			}
		});
		// cast session
		if (fields?.session?.id) {
			fields.session = fields.session.id;
		} else if (typeof fields?.session !== 'string') {
			delete fields['session'];
		}
		let sessionSkills = null;
		if (id) {
			let resp = await repository.patch(id, fields);
			if (resp.status !== 200) {
				throw new Error("Got an error while updating SessionSkills");
			}
			// remove instance from cache
			if (repository.has(id)) {
				repository.remove(id);
			}
			// reload instance
			sessionSkills = await getSessionSkills(id, false);
		} else {
			// validate creation
			if (!fields.session) {
				throw new Error("Session must be defined !");
			}
			let resp = await repository.post(fields);
			if (resp.status !== 200 || !resp.data.id) {
				throw new Error("Got an error while creating new SessionSkills");
			}
			if(resp.data.id) {
				// load instance
				sessionSkills = await getSessionSkills(resp.data.id, false);
				// add new instance to session skills
				if (data.session) {
					if (!('sessionsSkills' in data.session) || null === data.session.sessionsSkills) {
						data.session.sessionsSkills = [];
					}
					if (Array.isArray(data.session.sessionsSkills)) {
						data.session.sessionsSkills.push(sessionSkills);
					}
				}
			}
		}
		if (sessionSkills && sessionSkills.search && !sessionSkills.operatorSearch && sessionSkills.session) {
			let startDate = data.session.startDate;
			if (startDate instanceof Date) {
				startDate = startDate.toISOString();
			}
			let endDate = data.session.endDate;
			if (endDate instanceof Date) {
				endDate = startDate.toISOString();
			}
			let production = data.session.production;
			if (Array.isArray(production)) {
				production = production.shift();
			}
			let operatorSearch = {
				sessionSkills: sessionSkills,
				production: production,
				skills: data.skills,
				count: data.count,
				startDate: startDate,
				endDate: endDate,
				location: data.session.location,
				lat: data.session.lat,
				lng: data.session.lng,
				streetNumber: data.session.streetNumber,
				route: data.session.route,
				postalCode: data.session.postalCode,
				locality: data.session.locality,
				administrativeLevel2: data.session.administrativeLevel2,
				administrativeLevel1: data.session.administrativeLevel1,
				country: data.session.country,
				countryCode: data.session.countryCode,
				notes: data.notes,
				user: Array.isArray(data.user) && data.user.length > 0 ? data.user[0] : data.user ?? null,
				status: operatorSearchStatusCode.PENDING
			};
			const search = await searchOperators(operatorSearch);
			if(search && search.id) {
				// attach operatorSearch to sessionSkills
				sessionSkills.operatorSearch = search;
			}
		}
		return sessionSkills;
	};

	const deleteSessionSkills = async (data) => {
		if (data?.id) {
			let resp = await repository.delete(data.id);
			if (resp.status !== 200 || resp.data?.id !== data.id || !resp.data?.deleted) {
				throw new Error("Got an error while deleting SessionSkills");
			}
			// remove instance from session
			if (Array.isArray(data.session?.sessionsSkills)) {
				// get deleted sessionSkills index
				let index = data.session.sessionsSkills.indexOf(data);
				if (-1 !== index) {
					// remove deleted sessionSkills
					data.session.sessionsSkills.splice(index, 1);
				}
			}
			return true;
		}
		return false;
	}

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(sessionsSkills => {
				setSessionSkills(sessionsSkills);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, sessionSkills, getSessionsSkills, getSessionSkills, saveSessionSkills, deleteSessionSkills };
};

export default useSessionSkills;