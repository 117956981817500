import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;


const PageHeader = ({ title, titleTag: TitleTag, color }) => {
    return (
        <StyledHeader>
            <TitleTag style={{color, textTransform: 'capitalize', marginBottom: '0'}}>{title}</TitleTag>
        </StyledHeader>
    )
}

PageHeader.defaultProps = { titleTag: 'h3', color: '#000' };

export default PageHeader;
