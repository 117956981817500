import React from "react";
import { Route } from "react-router-dom";
import MagicLink from "./routes/Auth/MagicLink";
import Dashboard from './routes/Dashboard';
import Error404 from "./routes/Errors/Error404";
import Notifications from './routes/Notifications';
import Operators from './routes/Operators/Operators';
import SearchOperators from './routes/Operators/SearchOperators'
import ViewOperator from './routes/Operators/ViewOperator';
import Productions from './routes/Productions/Productions';
import EditProduction from './routes/Productions/EditProduction'
import OperatorReply from './routes/Operators/OperatorReply'

const routes = [
  {
    name: 'Authentification - MagicLink',
    path: '/auth/magiclink/:token',
    exact: false,
    component: MagicLink,
    authenticated: false
  },
  {
    name: 'Dashboard',
    path: ['/', '/dashboard'],
    exact: true,
    component: Dashboard
  },
  {
    name: 'Productions',
    path: '/productions/:id',
    exact: false,
    component: EditProduction
  },
  {
    name: 'Productions',
    path: '/productions',
    exact: true,
    component: Productions
  },
  {
    name: 'Recherche Opérateurs',
    path: ['/operators/search/:id', '/operators/search/'],
    exact: false,
    component: SearchOperators
  },
  {
    name: 'Opérateurs',
    path: '/operators/:id',
    exact: false,
    component: ViewOperator
  },
  {
    name: 'Opérateurs',
    path: '/operators',
    exact: true,
    component: Operators
  },
  {
    name: 'Notifications',
    path: '/notifications',
    exact: true,
    component: Notifications
  },
  {
    name: 'Operator proposal reply',
    path: '/reply/:id/:token',
    exact: false,
    component: OperatorReply,
    authenticated: false,
    context: 'operator'
  },
  {
    name: 'Page 404',
    component: Error404
  }
];

export const routeComponents = routes.map(({ path, component, exact }, key) => {
  return (
      <Route
          exact={exact}
          path={path}
          component={component}
          key={key}
      />
  )
});

export default routes;