import React from 'react';
import icon from '../../assets/icon_opside.png';
import logo from '../../assets/logo_opside.svg';
import styled from "styled-components";

const LogoImg = styled.img`
    height: 80px;
    max-height: 100%;
`;

const Logo = (props) => {
    return (
        <LogoImg src={props?.src ? props.src : ('small' === props?.type ? icon : logo)}
                 alt={props?.alt ? props?.alt : 'Opside'} />
    )
}

export default Logo
