import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const CenteredContent = styled.div`
  text-align: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = () => {
  return (
    <CenteredContent>
      <Spin/>
    </CenteredContent>
  )
}

export default Loader
