import routes from '../routes';

export const getRoute = (location?: Location = window.location) => {
  let route = routes.find((route) => {
    if (!route.path) {
      return false;
    }
    let paths = typeof route.path === 'string' ? [route.path] : route.path;
    return paths.find((path) => {
      path = path.split(':')[0];
      return route.exact === true
          ? path === `${location.pathname}`
          : -1 !== location.pathname.indexOf(path)
    });
  }) || null;
  return route;
};

export const getPageName = (location?: Location) => {
  let currentRoute = getRoute(location);
  if (currentRoute) {
    return currentRoute.name;
  }
  return '';
};

export default getPageName;