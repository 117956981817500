import React from 'react';
import { Row, Col, Space, Divider } from 'antd';
import RoundedBadge from '../RoundedBadge';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SmallDivider = styled(Divider)`
  margin: 18px 0;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  color: #343D46;
`;

const OverviewListItem = ({ id, name, count, type, badge }) => {
  return (
    <>
      <Row justify="space-between" align="middle">
          <Col flex={4}>
              <Row align="middle">
                  <Space>
                      <RoundedBadge size="large" background={badge}>{count}</RoundedBadge>
                      <h3 className="text-uppercase mb-0">
                        <StyledLink to={{
                          pathname: type,
                          queryFilterId: id,
                          queryFilterCount: count
                        }}>
                          { name }
                        </StyledLink>
                      </h3>
                  </Space>
              </Row>
          </Col>
          <Col flex={1} className="xs-hide">
              <Row justify="end">
                <Link to={
                  {
                    pathname: type,
                    queryFilterId: id,
                    queryFilterCount: count
                  }
                }>Consulter / Modifier</Link>
              </Row>
          </Col>
      </Row>
      <SmallDivider/>
    </>
  )
}

export default OverviewListItem
