import { useState, useEffect } from "react";
import { getRepository } from "./RepositoryService";

const repository = getRepository('productionStage');

//const cache = new Map();

async function loadStages(ids: Array = null) {
	if (null !== ids && !Array.isArray(ids)) {
		return [];
	}
	if (!repository) {
		throw new Error('No repository defined for productionStage!');
	}
	let stages = null === ids ? await repository.getAll(true) : await repository.get(ids);
	return stages;

	/*
	// @todo Have a look here for hash/cache query results in RepositoryService
	let hash = null;
	let params = new URLSearchParams();
	params.set('maxRecords', config.maxRecords);
	params.set('pageSize', config.pageSize);
	if (Array.isArray(ids) && ids.length > 0) {
		params.set('filterByFormula', (`SEARCH(RECORD_ID(), '${ids.join(',')}') != ''`));
	}
	let offset = null;
	let records = [];
	do {
		if (offset) {
			params.set('offset', offset);
		}
		let encoder = new TextEncoder();
		hash = await crypto.subtle.digest('SHA-1', encoder.encode(params.toString()));
		if (cache.has(hash)) {
			return;
		}
		cache.set(hash, []);
		let resp = await api.get(`${url}?${params.toString()}`);
		records = records.concat(resp.data.records.map(({ id, fields }) => { return { id, ...fields }; }));
		offset = resp?.data?.offset;
	} while (offset);
	if (hash) {
		cache.set(hash, params.toString());
	}
	*/
}

export const getStages = async (ids: Array) => {
	return await loadStages(ids ?? null);
};

export const getStage = async function (id) {
	await getStages([id]);
	return repository.get(id);
};

const listeners = new Set();

export const useProductionStage = () => {
	const [stages, setStages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getStages = async function (ids: Array) {
		listeners.forEach(listener => listener());
		setError(null);
		setLoading(true);
		let stages = await loadStages(ids ?? null);
		setLoading(false);
		listeners.forEach(listener => listener());
		return stages;
	}

	const getStage = async function (id) {
		await getStages([id]);
		return repository.get(id);
	}

	useEffect(() => {
		const listener = () => {
			repository.getAll(false).then(stages => {
				setStages(stages);
			});
			setLoading(repository.isLoading());
		};
		listeners.add(listener);
		listener(); // in case it's already changed
		return () => listeners.delete(listener); // cleanup
	}, []);

	return { error, loading, stages, getStages, getStage };
};

export default useProductionStage;